import icon from "../../assets/logo/icon.png";
import React, { useEffect, useRef } from "react";
import Footer from "../../components/layout/footer";
import Banner from "../../components/layout/banner";
import Content from "../../components/layout/content";
import { useLocation, useNavigate } from "react-router-dom";
import { setProducts } from "../../features/shops/shopsSlice";
import {
  GirlSharp,
  StarSharp,
  CategorySharp,
  EmojiPeopleSharp,
  ChildFriendlySharp,
  DevicesOtherSharp,
  FamilyRestroomSharp,
} from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import { PRODUCTS_BY_SHOP } from "../../api/product/queries";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

interface Props {}

function Shop(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const shop = location.state as any;
  const inputRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   const url = location.pathname.replace(/^\/+|\/+$/g, "");
  // }, [location.pathname]);

  const products = useAppSelector((state: RootState) => state.shops.products);

  const { data } = useQuery(PRODUCTS_BY_SHOP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !location?.pathname
      .replace(/^\/+|\/+$/g, "")
      ?.split("/")
      ?.pop(),
    variables: {
      shopId: location.pathname
        .replace(/^\/+|\/+$/g, "")
        ?.split("/")
        ?.pop(),
    },
  });

  useEffect(() => {
    dispatch(setProducts(data?.productsByShop?.page?.edges));
  }, [data, dispatch]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Leckerli One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Lato";
      }
    }
  };

  return (
    <>
      <Banner />
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "2em 5em auto 5em",
        }}
      >
        {/* Banner hack */}
        <div
          className="banner"
          style={{
            gridColumn: "1 / -1",
            backgroundColor: "#a5a39f49",
          }}
        ></div>

        {/* Header */}
        <div>
          <div
            className="header px-2 justify-between items-center my-5 max-w-full"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <div className="flex">
              <img
                src={shop?.logo}
                alt=""
                className="h-10 object-scale-down rounded-md"
              />
              <div className="max-w-max justify-evenly items-end px-2 hidden md:flex">
                <StarSharp fontSize="small" className="text-yellow-500" />
                <StarSharp fontSize="small" className="text-yellow-500" />
                <StarSharp fontSize="small" className="text-yellow-500" />
                <StarSharp fontSize="small" className="text-slate-200" />
                <StarSharp fontSize="small" className="text-slate-200" />
              </div>
            </div>
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp className="hover:cursor-pointer bg-transparent border-0" />
                <div className="absolute left-1 invisible group-hover:visible rounded-md px-1 bg-white text-black z-10 border border-djeliba">
                  <ul className="flex flex-col justify-between min-w-max my-2 px-2 rounded-md ">
                    <li className="m-0.5">
                      <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                        <FamilyRestroomSharp fontSize="large" />
                        <span className="mx-1">Toutes catégories</span>
                      </div>
                    </li>
                    <li className="m-0.5 group">
                      <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                        <GirlSharp fontSize="large" />
                        <span className="mx-1">Femmes</span>
                      </div>
                    </li>
                    <li className="m-0.5">
                      <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                        <ChildFriendlySharp fontSize="large" />
                        <span className="mx-1">Enfants</span>
                      </div>
                    </li>
                    <li className="m-0.5">
                      <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                        <EmojiPeopleSharp fontSize="large" />
                        <span className="mx-1">Hommes</span>
                      </div>
                    </li>
                    <li className="m-0.5">
                      <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                        <DevicesOtherSharp fontSize="large" />
                        <span className="mx-1">Divers</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>

        {/* Content */}
        <Content products={products} />

        {/* Footer */}
        <Footer />
        <div
          onClick={() => {
            navigate("/");
          }}
          className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none"
        >
          <img src={icon} alt="" className="object-scale-down rounded-full" />
        </div>
      </div>
    </>
  );
}

export default Shop;
