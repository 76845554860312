import React from "react";
import logo from "./../../assets/logo/logo.png";
import { LinkedIn, Instagram, Facebook } from "@mui/icons-material";

interface Props {}

function Footer(props: Props) {
  const handleJumpToTop = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer">
      <div className="flex justify-around items-center p-3 bg-djeliba">
        <div onClick={(e) => handleJumpToTop(e)}>
          <img
            src={logo}
            alt=""
            className="h-16 object-scale-down cursor-pointer"
          />
        </div>
        <div className="flex justify-between items-center">
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.instagram.com/media_djeliba/`}
          >
            <span className="m-1 cursor-pointer p-0.5">
              <Instagram className="h-12" />
            </span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.linkedin.com/in/dj%C3%A9liba-group-41966b22a/`}
          >
            <span className="m-1 cursor-pointer p-0.5">
              <LinkedIn className="h-12" />
            </span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.facebook.com/Dj%C3%A9liba-109800218247485`}
          >
            <span className="m-1 cursor-pointer p-0.5">
              <Facebook className="h-12" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
