import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation createPayment(
    $phone: String!
    $amount: Float!
    $orderId: String!
    $paymentMode: String!
    $calling_code: String!
  ) {
    createPayment(
      createPaymentInput: {
        amount: $amount
        orderId: $orderId
        paymentMode: $paymentMode
        calling_code: $calling_code
        phone: $phone
      }
    ) {
      amount
      status
      token
      url
    }
  }
`;

export const PAYMENT = gql`
  mutation payment($paymentInput: PaymentInput!) {
    payment(paymentInput: $paymentInput) {
      success
      message
      url
    }
  }
`;
