let userAgent = navigator.userAgent.toLowerCase();
function checkIfUserIsOnMobileDevice($userAgent) {
  const width = window.screen.availWidth;
  const height = window.screen.availHeight;

  if (height > width && width <= 768) {
    return true;
  } else {
    return false;
  }
}

export default checkIfUserIsOnMobileDevice(userAgent);
