import React from "react";

interface Props {
  labelText?: string;
  labelName: string;
  isRequired?: boolean;
  style?:string;
}

const Label = (props: Props) => {
  return (
    <div className={props?.style}>
      <label htmlFor={props.labelName}>{props.labelText}</label>
      {props.isRequired && <span className="text-red-600"> *</span>}
    </div>
  );
};

export default Label;
