import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UtilsState {
  isSellerRequestModalOpen: boolean;
  isSignInModalOpen: boolean;
  isInstallModalOpen: boolean;
  isSignUpModalOpen: boolean;
  isGridViewActive: boolean;
  isListViewActive: boolean;
  confirmationResult: any;
  isInfoVisible: boolean;
  categories: object[];
  isWaiting: boolean;
  segments: object[];
  fcmToken: string;
  logged: boolean;
  location?: any;
  error: any;
  isResetPasswordModalOpen: boolean;
}

const initialState: UtilsState = {
  isSellerRequestModalOpen: false,
  isSignUpModalOpen: false,
  isSignInModalOpen: false,
  isInstallModalOpen: false,
  confirmationResult: null,
  isGridViewActive: false,
  isListViewActive: false,
  isInfoVisible: true,
  isWaiting: false,
  categories: [],
  fcmToken: "",
  logged: false,
  segments: [],
  error: null,
  isResetPasswordModalOpen: false,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
    setConfirmationResult: (state, action: PayloadAction<any>) => {
      state.confirmationResult = action.payload;
    },
    setLocation: (state, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
    setFCMToken: (state, action: PayloadAction<any>) => {
      state.fcmToken = action.payload;
    },
    setSegments: (state, action: PayloadAction<object[]>) => {
      state.segments = action.payload;
    },
    setCategories: (state, action: PayloadAction<object[]>) => {
      state.categories = action.payload;
    },
    setInfoVisibility: (state) => {
      state.isInfoVisible = !state.isInfoVisible;
      state.isGridViewActive = state.isInfoVisible ? false : false;
    },
    setGridTrue: (state) => {
      state.isGridViewActive = true;
      state.isInfoVisible = false;
    },
    setInfoTrue: (state) => {
      state.isGridViewActive = false;
      state.isInfoVisible = true;
    },
    setGridView: (state) => {
      state.isGridViewActive = !state.isGridViewActive;
      state.isInfoVisible = state.isGridViewActive ? false : true;
    },
    setListView: (state) => {
      state.isListViewActive = !state.isListViewActive;
      state.isGridViewActive = state.isGridViewActive ? false : true;
    },
    toggleSignInModal: (state) => {
      state.isSignUpModalOpen = false;
      state.isSignInModalOpen = !state.isSignInModalOpen;
    },
    dismissSignInModal: (state) => {
      state.isSignInModalOpen = false;
    },
    toggleSignUpModal: (state) => {
      state.isSignInModalOpen = false;
      state.isSignUpModalOpen = !state.isSignUpModalOpen;
    },
    dismissSignUpModal: (state) => {
      state.isSignUpModalOpen = false;
    },
    toggleInstallModal: (state) => {
      state.isInstallModalOpen = !state.isInstallModalOpen;
    },
    dismissInstallModal: (state) => {
      state.isInstallModalOpen = false;
    },
    toggleSellerRequestModal: (state) => {
      state.isSellerRequestModalOpen = !state.isSellerRequestModalOpen;
    },
    dismissSellerRequestModal: (state) => {
      state.isSellerRequestModalOpen = false;
    },
    login: (state) => {
      state.logged = true;
    },
    logout: (state) => {
      state.logged = false;
    },
    clearStorage() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
    toggleResetPasswordModal: (state) => {
      state.isSignInModalOpen= false;
      state.isResetPasswordModalOpen = !state.isResetPasswordModalOpen;
    },
    dismissResetPasswordModal: (state) => {
      state.isResetPasswordModalOpen = false;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  login,
  logout,
  hasError,
  setLocation,
  setGridView,
  setGridTrue,
  setListView,
  setInfoTrue,
  startWaiting,
  clearStorage,
  setSegments,
  setFCMToken,
  setCategories,
  setInfoVisibility,
  toggleSignInModal,
  toggleSignUpModal,
  toggleInstallModal,
  dismissSignInModal,
  dismissInstallModal,
  dismissSignUpModal,
  setConfirmationResult,
  toggleSellerRequestModal,
  dismissSellerRequestModal,
  toggleResetPasswordModal,
  dismissResetPasswordModal
} = utilsSlice.actions;

export default utilsSlice.reducer;
