import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflowX: "hidden",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

interface Props {
  content: any;
  className?: any;
  handleModal?: any;
  dismissModal?: any;
  modalIsOpen: boolean;
  hideDismissBtn?: boolean;
}

const ModalTSX = (props: Props) => {
  return (
    <Modal
      style={customStyles}
      closeTimeoutMS={500}
      isOpen={props.modalIsOpen}
      overlayClassName="myoverlay"
      onRequestClose={props?.handleModal}
      className={"mymodal " + props.className}
    >
      <div
        className={
          props.hideDismissBtn
            ? "rounded-md w-5 h-5 bg-red-500 absolute justify-center items-center right-0 top-0 m-1 hover:shadow-3xl cursor-pointer hidden"
            : "rounded-md w-5 h-5 bg-red-500 absolute flex justify-center items-center right-0 top-0 m-1 hover:shadow-3xl cursor-pointer"
        }
        onClick={() => {
          props.dismissModal();
        }}
      >
        <span className="text-white cursor-pointer">x</span>
      </div>
      {props.content}
    </Modal>
  );
};

export default ModalTSX;
