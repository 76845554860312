import { useMutation } from "@apollo/client";
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { RiUser3Line } from "react-icons/ri";
import { UPDATE_SHOP } from "../../api/shop/mutations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import Modal from "../../components/common/modal";
import Button from "../../components/form/button";
import InputField from "../../components/form/input";
import UpdateLogo from "../../components/ux/update-shop-logo";
import { setShop, setShopName } from "../../features/shops/shopsSlice";

interface Props {}

const Settings = (props: Props) => {
  const dispatch = useAppDispatch();
  const shop = useAppSelector((state: RootState) => state.shops.shop);
  const [state, setState] = useState({
    name: shop?.name === null ? "" : shop?.name,
    isLogoModalOpen: false,
    isWaiting: false,
  });

  let [submit] = useMutation(UPDATE_SHOP, {
    onCompleted: (data) => {
      if (data) {
        toast.success("Mise à jour réussie.");
        dispatch(setShop(data?.updateShop));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleInputChange = (e) => {
    dispatch(setShopName(e.target.value));
  };

  const handleSubmit = useCallback(() => {
    submit({
      variables: {
        id: shop?._id,
        name: shop.name.trim(),
      },
    });
  }, [submit, state, shop]);

  const toggleLogoModal = () => {
    setState((prevState) => ({
      ...prevState,
      isLogoModalOpen: !prevState.isLogoModalOpen,
    }));
  };

  const dismissLogoModal = () => {
    setState((prevState) => ({
      ...prevState,
      isLogoModalOpen: false,
    }));
  };

  return (
    <>
      <Modal
        className="w-4/5 md:w-3/12"
        modalIsOpen={state.isLogoModalOpen}
        handleModal={() => toggleLogoModal()}
        dismissModal={() => dismissLogoModal()}
        content={<UpdateLogo handleDismiss={dismissLogoModal} />}
      />
      <div className="flex flex-col w-full h-full m-auto px-7 items-start">
        <div className="flex flex-col w-full items-center justify-center">
          <div className="flex min-w-max justify-between items-center rounded-md border p-1 my-5">
            <span>Boutique :</span>
            <div className="bg-black rounded-md mx-1">
              <span className="font-extrabold m-1 text-white">{shop.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full rounded-md bg-djeliba mt-7 border">
          <div className="flex m-3">
            <span className="font-extrabold text-lg">Logo</span>
          </div>
          <div className="flex w-full">
            <div className="flex pl-5 mt-2 mb-3 px-2">
              <div className="h-24 w-24 cursor-pointer">
                {shop?.logo ? (
                  <img
                    src={shop?.logo}
                    alt={`${shop.name}'s logo`}
                    onClick={() => toggleLogoModal()}
                    className="h-15 object-scale-down rounded-md"
                  />
                ) : (
                  <RiUser3Line
                    onClick={() => toggleLogoModal()}
                    className="rounded-full h-24 w-24 shadow-2xl cursor-pointer hover:text-tolgo"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full rounded-md bg-djeliba mt-7 space-y-3">
          <div className="flex m-3">
            <span className="font-extrabold text-lg ">Nom</span>
          </div>
          <div className="w-full pl-5 pr-5">
            <InputField
              type="text"
              id="shopName"
              autoFocus={true}
              isRequired={true}
              labelName="shopName"
              value={shop.name}
              handleInputChange={handleInputChange}
              placeholder="Entrez le nom de la boutique"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
