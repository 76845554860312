import { useEffect, useState } from "react";
import {
  PersonSharp,
  GridOnSharp,
  UpdateSharp,
  GridOffSharp,
  FavoriteSharp,
  ViewListSharp,
  VisibilitySharp,
  LocalMallSharp,
  VisibilityOffSharp,
} from "@mui/icons-material";
import Install from "../ux/install";
import toast from "react-hot-toast";
import Modal from "../common/modal";
import { RootState } from "../../app/store";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as InstallMobileSharp } from "./../../assets/icon/install_mobile_black_24dp.svg";
import { ReactComponent as InstallDesktopSharp } from "./../../assets/icon/install_desktop_black_24dp.svg";
import {
  logout,
  setGridView,
  setListView,
  setLocation,
  setInfoVisibility,
  toggleInstallModal,
  toggleSignInModal,
  toggleSignUpModal,
  dismissSignInModal,
  dismissSignUpModal,
  dismissInstallModal,
  toggleSellerRequestModal,
  toggleResetPasswordModal,
  dismissSellerRequestModal,
} from "../../features/utils/utilsSlice";
import { isIOS } from "../../utils/isIOS";
import { useQuery } from "@apollo/client";
import { ME } from "../../api/users/queries";
import SignIn from "../../screens/auth/sign-in";
import SignUp from "../../screens/auth/sign-up";
import SellerRequest from "../ux/seller-request";
import { setUser } from "../../features/users/usersSlice";
import * as serviceWorkerRegistration from "./../../serviceWorkerRegistration";
import ResetPassword from "../../screens/auth/reset-password";
import getLocationAsync from "../../services/location/location";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { duration } from "@mui/material";

interface Props {
  className?: any;
}

function Banner(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /**  Redux store variables */
  const cartItemCount = useAppSelector(
    (state: RootState) => state.cart?.cartItems?.length
  );
  const user = useAppSelector((state: RootState) => state.users.user);
  const shopId = useAppSelector((state: RootState) => state.shops.shop._id);
  const notificationCount = useAppSelector(
    (state: RootState) => state.users.user.count
  );
  const wishlistItemCount = useAppSelector(
    (state: RootState) => state.wishlist?.wishlistItems?.length
  );
  const utils = useAppSelector((state: RootState) => state.utils);
  const place = useAppSelector((state: RootState) => state.utils.location);
  const token = localStorage.getItem("access_token");
  const pathname = location.pathname;
  const { client, data } = useQuery(ME, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: token === null ? true : false,
    errorPolicy: "all",
    context: {
      headers: { authorization: `Bearer ${token}` },
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setUser(data?.me));
    }
  }, [data, dispatch]);

  const handleLogout = () => {
    navigate("/");
    dispatch(logout());
    client.resetStore();
    localStorage.removeItem("access_token");
    toast.success("Vous n'êtes plus connecté.");
  };

  /**
   * Update process
   */

  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload();
  };

  useEffect(() => {
    showReload &&
      toast(
        "Une nouvelle version est disponible. Cliquez sur l'icon en haut à gauche pour mettre à jour.",
        {
          duration: 5000,
        }
      );
  }, [showReload]);

  /**
   * Install process
   */

  const [installed, setInstalled] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  useEffect(() => {
    const installationHandler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    const installedHandler = async function (e) {
      setSupportsPWA(true);
      setInstalled(true);
    };

    window.addEventListener("beforeinstallprompt", installationHandler);

    window.addEventListener("appinstalled", installedHandler);

    return () => {
      window.removeEventListener("transitionend", installationHandler);
      window.removeEventListener("transitionend", installedHandler);
    };
  }, []);

  useEffect(() => {
    if (typeof place === "undefined") {
      getLocationAsync().then((res: any) => {
        const {
          city,
          latitude,
          longitude,
          state_prov,
          calling_code,
          country_flag,
          country_name,
          country_code2,
          country_code3,
        } = res;
        dispatch(
          setLocation({
            city,
            latitude,
            longitude,
            state_prov,
            calling_code,
            country_flag,
            country_name,
            country_code2,
            country_code3,
          })
        );
      });
    }
  }, [place, dispatch]);

  const handleInstall = async (evt) => {
    evt.preventDefault();
    if (isIOS()) {
      dispatch(toggleInstallModal());
    } else {
      if (!promptInstall) {
        return;
      }

      promptInstall.prompt();
      const { outcome } = await promptInstall.userChoice;
      if (outcome === "accepted") {
        setPromptInstall(null);
        setInstalled(true);
      }
    }
  };

  return (
    <div
      className={`flex justify-between items-center pr-2 ` + props.className}
    >
      {/* SignIn Modal */}
      <Modal
        modalIsOpen={utils.isSignInModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleSignInModal())}
        dismissModal={() => dispatch(dismissSignInModal())}
        content={<SignIn />}
      />
      {/* SignUp Modal */}
      <Modal
        modalIsOpen={utils.isSignUpModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleSignUpModal())}
        dismissModal={() => dispatch(dismissSignUpModal())}
        content={<SignUp />}
      />
      {/* Installation Modal */}
      <Modal
        modalIsOpen={utils.isInstallModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleInstallModal())}
        dismissModal={() => dispatch(dismissInstallModal())}
        content={<Install />}
      />
      {/* Seller Request Modal */}
      <Modal
        modalIsOpen={utils.isSellerRequestModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleSellerRequestModal())}
        dismissModal={() => dispatch(dismissSellerRequestModal())}
        content={<SellerRequest />}
      />
      {/* Reset Password Modal */}
      <Modal
        modalIsOpen={utils.isResetPasswordModalOpen}
        handleModal={() => dispatch(toggleResetPasswordModal())}
        content={<ResetPassword />}
      />

      <div className="flex justify-between items-center">
        {/^(desktop)\/.{1,}\/(?:(products))$/.test(
          pathname.replace(/^\/+|\/+$/g, "")
        ) &&
          (utils.isGridViewActive ? (
            <ViewListSharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setListView())}
            />
          ) : (
            <GridOnSharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setGridView())}
            />
          ))}
        {(pathname.replace(/^\/+|\/+$/g, "") === "products" ||
          /(shops)\/((?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d))/gm.test(pathname)) &&
          (utils.isGridViewActive ? (
            <GridOffSharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setGridView())}
            />
          ) : (
            <GridOnSharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setGridView())}
            />
          ))}
        {(pathname.replace(/^\/+|\/+$/g, "") === "products" ||
          /(shops)\/((?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d))/gm.test(pathname)) &&
          (utils.isInfoVisible ? (
            <VisibilityOffSharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setInfoVisibility())}
            />
          ) : (
            <VisibilitySharp
              className="hover:cursor-pointer mx-2"
              onClick={() => dispatch(setInfoVisibility())}
            />
          ))}
      </div>
      <div className="flex justify-start items-center h-full w-full">
        <span
          onClick={() => reloadPage()}
          className={
            showReload
              ? "px-1 bg-black text-white flex justify-center items-center rounded-full h-7 w-7 md:min-h-max md:min-w-max md:rounded-2xl cursor-pointer mx-2"
              : "hidden"
          }
        >
          <span className="hidden md:inline-flex">Mettre à jour </span>
          <UpdateSharp className="text-green-400" />
        </span>
        <span
          className={
            supportsPWA && !installed
              ? "flex justify-center items-center"
              : "hidden"
          }
        >
          <InstallMobileSharp
            className="inline-flex lg:hidden mx-2 cursor-pointer"
            onClick={handleInstall}
          />
          <InstallDesktopSharp
            className="hidden lg:inline-flex mx-2 cursor-pointer"
            onClick={handleInstall}
          />
        </span>
      </div>
      <div className="flex justify-between items-center">
        {!(pathname === "/desktop") && (
          <>
            <div className="relative mx-3">
              <LocalMallSharp
                className={
                  pathname === "/products/cart"
                    ? "text-slate-400"
                    : "hover:cursor-pointer"
                }
                onClick={() => {
                  navigate("/products/cart");
                }}
              />
              {cartItemCount > 0 && (
                <span className="absolute top-0 -right-5 bg-white rounded-md h-5 w-5 flex justify-center items-center text-xs truncate">
                  {cartItemCount}
                </span>
              )}
            </div>

            <div className="relative mx-3">
              <FavoriteSharp
                className={
                  pathname === "/products/wishlist"
                    ? "text-slate-400"
                    : "hover:cursor-pointer"
                }
                onClick={() => {
                  navigate("/products/wishlist");
                }}
              />
              {wishlistItemCount > 0 && (
                <span className="absolute top-0 -right-5 bg-white rounded-md h-5 w-5 flex justify-center items-center text-xs truncate">
                  {wishlistItemCount}
                </span>
              )}
            </div>
          </>
        )}

        <div className="relative mx-3 transition-all ease-linear duration-300 group z-50">
          <PersonSharp className="hover:cursor-pointer" />
          {/* Do not display if there is no notification */}
          {notificationCount > 0 && (
            <span className="absolute top-0 -right-5 bg-white rounded-md h-5 w-5 flex justify-center items-center text-xs truncate">
              {notificationCount}
            </span>
          )}
          <div
            className={
              "absolute right-0 invisible group-hover:visible rounded-md px-1 bg-black"
            }
          >
            {!utils.logged && (
              <div
                className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                onClick={() => dispatch(toggleSignInModal())}
              >
                <span className="pr-2">Se connecter</span>
              </div>
            )}
            {!utils.logged && (
              <div
                className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                onClick={() => dispatch(toggleSignUpModal())}
              >
                <span className="pr-2">S' inscrire</span>
              </div>
            )}
            {utils.logged && (
              <>
                {user.isSeller && (
                  <div
                    className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                    onClick={() => navigate("/desktop/" + shopId)}
                  >
                    <span className="">Boutique</span>
                  </div>
                )}
                {/* <div className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm">
                  <span className="">Paramètres</span>
                </div> */}
                {!user.isSeller && (
                  <>
                    {user.hasRequestedToBeSeller ? (
                      <div
                        className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                        onClick={() => navigate("/shops/become-seller")}
                      >
                        <span className="">Ma demande</span>
                      </div>
                    ) : (
                      <div
                        className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                        onClick={() => dispatch(toggleSellerRequestModal())}
                      >
                        <span className="">Etre partenaire</span>
                      </div>
                    )}
                  </>
                )}
                <div
                  className="flex justify-between items-center min-w-max text-white  hover:text-black hover:cursor-pointer hover:bg-white my-2 px-2 rounded-sm"
                  onClick={() => handleLogout()}
                >
                  <span className="">Se déconnecter</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
