import { RootState } from "../../app/store";
import { classNames } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../utils/utils";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToCart, removeFromCart } from "../../features/cart/cartSlice";
import {
  Favorite,
  ShoppingCartSharp,
  DoneSharp,
  DeliveryDiningSharp,
  Edit,
  VisibilityOffSharp,
  DeleteForever,
  MoreHoriz,
  VisibilitySharp,
} from "@mui/icons-material";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../features/wishlist/wishlistSlice";
import {
  setActiveProduct,
  toggleDeleteProductModal,
  toggleEditProductModal,
  toggleHideProductModal,
} from "../../features/products/productsSlice";

interface Props {
  product: any;
  handleOnClick?: boolean;
}

const Card = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const [state, setState] = useState({
    grid: false,
    liked: false,
    added: false,
    loaded: false,
    activeLink: "/",
  });

  const cartItems = useAppSelector((state: RootState) => state.cart.cartItems);

  const wishlistItems = useAppSelector(
    (state: RootState) => state.wishlist?.wishlistItems
  );

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      activeLink: location.pathname.replace(/^\/+|\/+$/g, ""),
    }));
  }, [location, state.activeLink]);

  useEffect(() => {
    if (
      wishlistItems?.some(function (el) {
        return el?._id === props?.product?._id;
      })
    ) {
      setState((prevState) => ({
        ...prevState,
        liked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        liked: false,
      }));
    }
    if (
      cartItems?.some(function (el) {
        return el?._id === props?.product?._id;
      })
    ) {
      setState((prevState) => ({
        ...prevState,
        added: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        added: false,
      }));
    }
  }, [wishlistItems, cartItems, props?.product?._id]);

  const imageRef = useRef<HTMLImageElement>(null);

  const isInfoVisible = useAppSelector(
    (state: RootState) => state.utils.isInfoVisible
  );

  const isGridViewActive = useAppSelector(
    (state: RootState) => state.utils.isGridViewActive
  );

  const getRowSpanEstimate = (width, height) => {
    if (height === width) {
      return 4;
    }
    if (width < height) {
      if (width < 640) {
        return 6;
      }
      return 4;
    }
    if (width > height) {
      return 2;
    }
  };

  const onClickHandler = (product) => {
    if (props?.handleOnClick) {
      navigate("/shops/products/" + product?._id, {
        state: {
          ...product,
          uri: product?.images[0]?.lg,
        },
      });
    }
  };

  const getColumnSpanEstimate = (width, height) => {
    if (width === height) {
      return 4;
    }

    if (width < height) {
      if (width === 640) {
        return 4;
      }
      return 4;
    }

    if (width > height) {
      return 4;
    }
  };

  const ImageGridItem = (product) => {
    return (
      <img
        onLoad={() => {
          setState((prevState) => ({
            ...prevState,
            loaded: true,
          }));
        }}
        onClick={() => onClickHandler(product)}
        src={product?.images[0]?.lg}
        ref={imageRef}
        alt={product?.name}
        loading="lazy"
        className={state.loaded ? "card-img" : "card-img bg-gray-100"}
      />
    );
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    const { _id, shop, price, name, images, description, isDeliveryFree } = props?.product;
    const cartItem = {
      _id,
      uri: images[0]?.lg,
      name,
      shop: {
        _id: shop?._id,
        name: shop?.name,
        logo: shop?.logo,
      },
      price,
      images,
      description,
      isDeliveryFree,
      quantity: 1,
    };
    dispatch(addToCart(cartItem));
    setState((prevState) => ({
      ...prevState,
      added: !prevState.added,
    }));
  };

  const handleRemoveFromCart = (e) => {
    e.preventDefault();
    dispatch(removeFromCart(props?.product?._id));
    setState((prevState) => ({
      ...prevState,
      added: !prevState.added,
    }));
  };

  const handleToggleWish = (e) => {
    e.preventDefault();
    if (state.liked) {
      dispatch(removeFromWishlist(props?.product?._id));
    } else {
      const { _id, shop, price, name, images, description, isDeliveryFree } = props?.product;
      const wishlistItem = {
        _id,
        uri: images[0]?.lg,
        name,
        shop: {
          _id: shop?._id,
          name: shop?.name,
          logo: shop?.logo,
        },
        price,
        images,
        description,
        isDeliveryFree,
        quantity: 1,
      };
      dispatch(addToWishlist(wishlistItem));
    }
  };

  const handleSetActiveProduct = (product) => {
    dispatch(setActiveProduct(product));
  };

  const handleDeleteProduct = (e) => {
    e.preventDefault();
    handleSetActiveProduct(props?.product);
    dispatch(toggleDeleteProductModal());
  };

  const handleEditProduct = (e) => {
    e.preventDefault();
    handleSetActiveProduct(props?.product);
    dispatch(toggleEditProductModal());
  };

  const toggleProductVisibility = (e) => {
    e.preventDefault();
    handleSetActiveProduct(props?.product);
    dispatch(toggleHideProductModal());
  };

  return (
    <>
      <figure
        className={
          isGridViewActive
            ? classNames("relative flex justify-center items-center")
            : classNames("product-list-item relative")
        }
        style={
          isGridViewActive
            ? {
                gridColumn: `span ${getColumnSpanEstimate(
                  imageRef?.current?.naturalWidth,
                  imageRef?.current?.naturalHeight
                )}`,
                gridRow: `span ${getRowSpanEstimate(
                  imageRef?.current?.naturalWidth,
                  imageRef?.current?.naturalHeight
                )}`,
              }
            : {}
        }
      >
        {ImageGridItem(props.product)}
        {state.loaded && !isGridViewActive && isInfoVisible && (
          <span className="absolute text-xs px-1 bottom-0 left-0 m-2 border bg-white rounded hover:cursor-pointer font-bold">
            {`${props.product.price} FCFA`}
          </span>
        )}

        {state.loaded && !isGridViewActive && isInfoVisible && (
          <Favorite
            onClick={handleToggleWish}
            fontSize={width > 768 ? "small" : "medium"}
            className={
              state.liked
                ? classNames(
                    "absolute top-0 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-red-500"
                  )
                : classNames(
                    "absolute top-0 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer"
                  )
            }
          />
        )}

        {isInfoVisible && props.product?.isDeliveryFree && (
          <DeliveryDiningSharp
            onClick={handleAddToCart}
            fontSize={width > 768 ? "small" : "medium"}
            className="absolute text-green-500 top-0 right-14 m-1 p-0.5 border bg-white rounded"
          />
        )}
        {state.loaded &&
          !isGridViewActive &&
          isInfoVisible &&
          (!state.added ? (
            <ShoppingCartSharp
              onClick={handleAddToCart}
              fontSize={width > 768 ? "small" : "medium"}
              className="absolute top-0 right-7 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer"
            />
          ) : (
            <DoneSharp
              onClick={handleRemoveFromCart}
              fontSize={width > 768 ? "small" : "medium"}
              className="absolute top-0 right-7 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-green-400"
            />
          ))}
        {!isInfoVisible && props?.product?.quantity <= 0 && (
          <div className="absolute flex justify-center items-center bg-white p-2 border border-black rounded-md">
            <span className="font-bold text-red-500">Rupture de stock</span>
          </div>
        )}
        {/^(desktop)\/.{1,}\/(products)$/.test(state.activeLink) && (
          <div className="group absolute top-0 right-0 m-1 opacity-30 hover:opacity-100 border-black bg-white rounded hover:cursor-pointer">
            <MoreHoriz className="text-xs" />
            <div className="relative hidden group-hover:flex opacity-100">
              <Edit
                onClick={handleEditProduct}
                className={
                  "absolute top-0 md:-top-1 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-blue-500"
                }
              />
              {props.product?.status === "HIDDEN" ? (
                <VisibilitySharp
                  onClick={toggleProductVisibility}
                  className={
                    "absolute top-7 md:top-5 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-black"
                  }
                />
              ) : (
                <VisibilityOffSharp
                  onClick={toggleProductVisibility}
                  className={
                    "absolute top-7 md:top-5 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-black"
                  }
                />
              )}

              <DeleteForever
                onClick={handleDeleteProduct}
                className={
                  "absolute top-14 md:top-11 right-0 m-1 p-0.5 border border-black bg-white rounded hover:cursor-pointer text-red-500"
                }
              />
            </div>
          </div>
        )}
      </figure>
    </>
  );
};

export default Card;
