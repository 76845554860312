import React from "react";
import Card from "./card";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import { classNames, useWindowDimensions } from "../../utils/utils";

interface Props {
  products?: any[];
}

export default function CardList(props: Props) {
  const products = props.products;

  const { width } = useWindowDimensions();

  const isGridViewActive = useAppSelector(
    (state: RootState) => state.utils.isGridViewActive
  );

  return (
    <>
      <div
        className={
          isGridViewActive ? classNames("m-2") : classNames("product-list ")
        }
        style={
          isGridViewActive
            ? {
                display: "grid",
                gridGap: "0.2rem",
                gridAutoFlow: "dense",
                gridTemplateColumns:
                  width <= 768 ? "repeat(12, 1fr)" : "repeat(16, 1fr)",
              }
            : {}
        }
      >
        {products?.map((product, i) => (
          <Card key={i} product={product?.node} handleOnClick={true} />
        ))}
        {/* Do not delete the line below. It's there so that the products are displayed well*/}
        <div
          className={`product-list-item ${isGridViewActive ? "hidden" : ""}`}
        ></div>
      </div>
    </>
  );
}
