export const pricing_individual_zone_01 = {
  name: "Zone 1",
  price: 1500,
  locations: [
    "Sacré cœur 1",
    "Amitié 1",
    "Amitié 2",
    "Amitié 3",
    "Sacré cœur 2",
    "Sacré cœur 3",
    "Fann Résidence",
    "Ouagou Niayes",
    "Gueule Tapée",
    "Liberté 1",
    "Liberté 2",
    "Liberté 3",
    "Liberté 4",
    "Liberté 5",
    "Liberté 6",
    "Sicap Baobab",
    "Sicap Karak",
    "Grand Dakar",
    "Keur Gorgui",
    "Niary Tally",
    "Bourguiba",
    "Sud Foire",
    "Mamelles",
    "Jet d'eau",
    "HLM 1",
    "HLM 2",
    "HLM 3",
    "HLM 4",
    "HLM 5",
    "HLM 6",
    "Nord Foire",
    "Khar Yalla",
    "Bene Tally",
    "Colobane",
    "Hann Maristes 1",
    "Hann Maristes 2",
    "Zone de Captage",
    "HLM Grand Yoff",
    "Grand Yoff",
    "Ouest Foire",
    "Sicap Foire",
    "Fass",
    "Dieuppeul",
    "Ker Yoff",
    "Gibraltar",
    "Mermoz",
    "Ouakam",
    "Yoff",
    "Zone A",
    "Zone B",
  ],
};

export const pricing_individual_zone_02 = {
  name: "Zone 2",
  price: 2000,
  locations: [
    "Diamalaye 1",
    "Diamalaye 2",
    "Grand Médine",
    "Cambérène",
    "Cité Attaya",
    "Patte d'oie",
    "Cité Damel",
    "Almadies",
    "Parcelles",
    "Soprime",
    "Dalifort",
    "Mixta",
    "Pikine",
    "Fadia",
    "Ngor",
    "Golf",
  ],
};

export const pricing_individual_zone_03 = {
  name: "Zone 3",
  price: 3000,
  locations: [
    "Keur Mbaye Fall",
    "Tivaouane Peulh",
    "Diamagueune",
    "Guediawaye",
    "Keur Massar",
    "Grand Mbao",
    "Sicap Mbao",
    "Fass Mbao",
    "Almadies 2",
    "Pétit Mbao",
    "Yeumbeul",
    "Zac Mbao",
    "Kounoune",
    "Diacksao",
    "Thiaroye",
    "Rufisque",
    "Djaxaay",
    "Gadaye",
    "Mbatal",
    "Malika",
    "Boune",
    "Mbao",
    "APIX",
  ],
};

export const pricing_individual_region = ["Thies", "Mbour", "Saint Louis"];

export const pricing_partner_zone_01 = {
  name: "Zone 1",
  price: 1000,
  locations: [
    "Sacré cœur 1",
    "Amitié 1",
    "Amitié 2",
    "Amitié 3",
    "Sacré cœur 2",
    "Sacré cœur 3",
    "Fann Résidence",
    "Ouagou Niayes",
    "Gueule Tapée",
    "Liberté 1",
    "Liberté 2",
    "Liberté 3",
    "Sicap Baobab",
    "Sicap Karak",
    "Grand Dakar",
    "Keur Gorgui",
    "Niary Tally",
    "Jet d'eau",
    "Bene Tally",
    "Zone A",
    "Zone B",
    "Fass",
    "Dieuppeul",
  ],
};

export const pricing_partner_zone_02 = {
  name: "Zone 2",
  price: 1500,
  locations: [
    "HLM 1",
    "HLM 2",
    "HLM 3",
    "HLM 4",
    "HLM 5",
    "HLM 6",
    "Ker Yoff",
    "Gibraltar",
    "Mermoz",
    "Ouakam",
    "Yoff",
    "Hann Maristes 1",
    "Hann Maristes 2",
    "Zone de Captage",
    "HLM Grand Yoff",
    "Grand Yoff",
    "Ouest Foire",
    "Sicap Foire",
    "Liberté 4",
    "Liberté 5",
    "Liberté 6",
    "Bourguiba",
    "Sud Foire",
    "Mamelles",
    "Colobane",
    "Nord Foire",
    "Khar Yalla",
  ],
};

export const pricing_partner_zone_03 = {
  name: "Zone 3",
  price: 2000,
  locations: [
    "Diamalaye 1",
    "Diamalaye 2",
    "Grand Médine",
    "Cambérène",
    "Cité Attaya",
    "Patte d'oie",
    "Cité Damel",
    "Almadies",
    "Parcelles",
    "Soprime",
    "Dalifort",
    "Mixta",
    "Pikine",
    "Fadia",
    "Ngor",
    "Golf",
  ],
};

export const pricing_partner_zone_04 = {
  name: "Zone 4",
  price: 3000,
  locations: [
    "Keur Mbaye Fall",
    "Tivaouane Peulh",
    "Diamagueune",
    "Guediawaye",
    "Keur Massar",
    "Grand Mbao",
    "Sicap Mbao",
    "Fass Mbao",
    "Almadies 2",
    "Pétit Mbao",
    "Yeumbeul",
    "Zac Mbao",
    "Kounoune",
    "Diacksao",
    "Thiaroye",
    "Rufisque",
    "Djaxaay",
    "Gadaye",
    "Mbatal",
    "Malika",
    "Boune",
    "Mbao",
    "APIX",
  ],
};

export const pricing_partner_region = ["Thies", "Mbour", "Saint Louis"];
