import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import icon from "../../assets/logo/icon.png";
import Order from "../../components/ux/order";
import { useNavigate } from "react-router-dom";
import { LocalMallSharp } from "@mui/icons-material";
import Banner from "../../components/layout/banner";
import Header from "../../components/layout/header";
import { setOrders } from "../../features/orders/ordersSlice";
import { ORDERS_BY_PHONE } from "../../api/orders/queries";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ReactComponent as NoOrder } from "./../../assets/img/delivery-bro.svg";
import { IoMdAddCircle } from "react-icons/io";

interface Props {}

function Orders(props: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const calling_code = useAppSelector(
    (state: RootState) => state.utils.location.calling_code
  );
  const orders = useAppSelector((state: RootState) => state.orders.orders);
  const phone = useAppSelector((state: RootState) => state.users.user.phone);

  const { data } = useQuery(ORDERS_BY_PHONE, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    skip: !(phone && calling_code),
    errorPolicy: "all",
    variables: {
      phone,
      calling_code,
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setOrders(data?.ordersByPhone?.page?.edges));
    }
  }, [data, dispatch]);

  return (
    <>
      <Banner />
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "2em 5em auto",
        }}
      >
        {/* Banner hack */}
        <div
          className="banner"
          style={{
            gridColumn: "1 / -1",
            backgroundColor: "#a5a39f49",
          }}
        ></div>

        {/* Header */}
        <Header />

        {/* Content */}
        {orders.length > 0 ? (
          <div
            className=""
            style={{
              gridColumn: "1 / -1",
            }}
          >
            <div className="flex justify-center items-center px-2">
              <div className="flex justify-center items-center flex-col w-full md:mx-7">
                {orders?.map((item, i) => (
                  <Order order={item} key={i} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center md:h-1/3 flex-col">
            <NoOrder />
          </div>
        )}
        <div className="group hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none">
          <div className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border group-hover:border-0">
            <IoMdAddCircle size={35} className="group-hover:text-slate-400" />
          </div>
          <div
            className={
              "relative justify-center items-center bottom-14 hidden md:bottom-11 w-12 h-12 group-hover:flex rounded-full"
            }
          >
            <div
              onClick={() => {
                navigate("/products/cart");
              }}
              className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border"
            >
              <LocalMallSharp />
            </div>

            <div
              onClick={() => {
                navigate("/");
              }}
              className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border bottom-12"
            >
              <img
                src={icon}
                alt=""
                className="object-scale-down rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Orders;
