import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import Card from "../../components/card/card";
import { setProducts } from "../../features/shops/shopsSlice";
import { PRODUCTS_BY_SHOP } from "../../api/product/queries";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  DeleteForever,
  Edit,
  VisibilityOffSharp,
  VisibilitySharp,
} from "@mui/icons-material";
import {
  dismissDeleteProductModal,
  dismissEditProductModal,
  dismissHideProductModal,
  dismissNewProductModal,
  setActiveProduct,
  toggleDeleteProductModal,
  toggleEditProductModal,
  toggleHideProductModal,
  toggleNewProductModal,
} from "../../features/products/productsSlice";
import Modal from "../../components/common/modal";
import NewProduct from "../../components/ux/new-product";
import HideProduct from "../../components/ux/hide-product";
import DeleteProduct from "../../components/ux/delete-product";
import UpdateProduct from "../../components/ux/update-product";
import isMobile from "../../utils/isMobile";

interface Props {}

const Products = (props: Props) => {
  const [state, setState] = useState({
    actionColum: false,
  });

  const isGridViewActive = useAppSelector(
    (state: RootState) => state.utils.isGridViewActive
  );

  const dispatch = useAppDispatch();

  const product = useAppSelector((state: RootState) => state.products.product);
  const products = useAppSelector((state: RootState) => state.shops.products);
  const shopId = useAppSelector((state: RootState) => state.shops.shop._id);

  const { data } = useQuery(PRODUCTS_BY_SHOP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !shopId,
    variables: {
      shopId,
    },
  });

  useEffect(() => {
    dispatch(setProducts(data?.productsByShop?.page?.edges));
  }, [data, dispatch]);

  const handleSetActiveProduct = (product) => {
    dispatch(setActiveProduct(product));
  };

  const handleDeleteProduct = (e, product) => {
    e.preventDefault();
    handleSetActiveProduct(product);
    dispatch(toggleDeleteProductModal());
  };

  const handleEditProduct = (e, product) => {
    e.preventDefault();
    handleSetActiveProduct(product);
    dispatch(toggleEditProductModal());
  };

  const toggleProductVisibility = (e, product) => {
    e.preventDefault();
    handleSetActiveProduct(product);
    dispatch(toggleHideProductModal());
  };

  const toggleActionColumn = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      actionColum: !prevState.actionColum,
    }));
  };

  return (
    <>
      <Modal
        content={
          <UpdateProduct
            handleDismiss={() => dispatch(dismissEditProductModal())}
          />
        }
        className="w-11/12 md:w-3/5"
        modalIsOpen={product.isEditProductModalOpen}
        handleModal={() => dispatch(toggleEditProductModal())}
        dismissModal={() => dispatch(dismissEditProductModal())}
      />
      <Modal
        modalIsOpen={product?.isHideProductModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleHideProductModal())}
        dismissModal={() => dispatch(dismissHideProductModal())}
        content={
          <HideProduct
            handleDismiss={() => dispatch(dismissHideProductModal())}
          />
        }
      />
      <Modal
        modalIsOpen={product?.isDeleteProductModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleDeleteProductModal())}
        dismissModal={() => dispatch(dismissDeleteProductModal())}
        content={
          <DeleteProduct
            handleDismiss={() => dispatch(dismissDeleteProductModal())}
          />
        }
      />
      <Modal
        content={<NewProduct />}
        className="w-11/12 md:w-3/5"
        modalIsOpen={product.isNewProductModalOpen}
        handleModal={() => dispatch(toggleNewProductModal())}
        dismissModal={() => dispatch(dismissNewProductModal())}
      />
      {products?.length === 0 ? (
        <div className="flex flex-col justify-between items-center text-center w-full p-2">
          <span className="text-lg text-slate-400 mt-32 font-bold">
            Il n'y a aucun produit dans la boutique.
          </span>
        </div>
      ) : isGridViewActive ? (
        <div
          className="m-2"
          style={{
            display: "grid",
            gridGap: "0.5rem",
            gridTemplateColumns: "repeat(16, 1fr)",
            gridAutoFlow: "dense",
          }}
        >
          {products?.map((product, i) => (
            <Card key={i} product={product?.node} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-start w-full h-full p-2 pb-16">
          <table className="w-full">
            <thead className="w-full">
              <tr className="table-row text-black">
                <th className="table-cell text-center w-20 pr-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Image</span>
                  </div>
                </th>
                <th className="table-cell text-center w-20 px-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Nom</span>
                  </div>
                </th>
                <th className="table-cell text-center w-20 px-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Prix</span>
                  </div>
                </th>
                <th
                  className={
                    isMobile && state.actionColum
                      ? "hidden text-center w-20 px-1"
                      : "table-cell text-center w-20 px-1"
                  }
                >
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Reste</span>
                  </div>
                </th>
                <th className="hidden md:table-cell text-center w-20 pl-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Description</span>
                  </div>
                </th>
                <th
                  className={
                    isMobile && state.actionColum
                      ? "table-cell text-center w-20 pl-1"
                      : "hidden md:table-cell text-center w-20 pl-1"
                  }
                >
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="w-full">
              {products?.map((item, i) => (
                <tr className="table-row" key={i}>
                  <td className="text-center border-b">
                    <img
                      onClick={toggleActionColumn}
                      src={item?.node?.images[0]?.sm}
                      className="object-cover w-16 h-16 rounded-md hover:ring-4 ring-djeliba  hover:shadow-3xl transition-all ease-linear duration-300"
                      alt=""
                    />
                  </td>
                  <td className="text-center border-b">
                    <span className="">{item?.node?.name}</span>
                  </td>
                  <td className="text-center border-b">
                    <span className="">
                      {new Intl.NumberFormat("fr-FR").format(item?.node?.price)}
                    </span>
                  </td>
                  <td
                    className={
                      isMobile && state.actionColum
                        ? "hidden text-center border-b"
                        : "text-center border-b"
                    }
                  >
                    <span className="">{item?.node?.quantity}</span>
                  </td>
                  <td className="hidden md:table-cell border-b">
                    <span
                      className="border-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                      }}
                    >
                      {item?.node?.description}
                    </span>
                  </td>
                  <td
                    className={
                      isMobile && state.actionColum
                        ? "table-cell border-b"
                        : "hidden md:table-cell border-b"
                    }
                  >
                    <div className="flex justify-around items-center">
                      <Edit
                        onClick={(e) => handleEditProduct(e, item?.node)}
                        className={
                          "p-0.5 bg-white rounded hover:cursor-pointer text-blue-500"
                        }
                      />
                      {item?.node?.status === "HIDDEN" ? (
                        <VisibilitySharp
                          onClick={(e) =>
                            toggleProductVisibility(e, item?.node)
                          }
                          className={
                            "p-0.5 bg-white rounded hover:cursor-pointer text-black"
                          }
                        />
                      ) : (
                        <VisibilityOffSharp
                          onClick={(e) =>
                            toggleProductVisibility(e, item?.node)
                          }
                          className={
                            "p-0.5 bg-white rounded hover:cursor-pointer text-black"
                          }
                        />
                      )}
                      <DeleteForever
                        onClick={(e) => handleDeleteProduct(e, item?.node)}
                        className={
                          "p-0.5 bg-white rounded hover:cursor-pointer text-red-500"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Products;
