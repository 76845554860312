import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface orderState {
  orders: any[];
}

const initialState: orderState = {
  orders: [],
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<object[]>) => {
      state.orders = action.payload;
    },
    removeFromOrders: (state, action: PayloadAction<string>) => {
      state.orders = state.orders.filter(
        (item) => item?.node?._id !== action.payload
      );
    },
  },
});

export const { setOrders, removeFromOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
