import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation createOrder(
    $products: [OrderItemInput!]!
    $phone: String!
    $calling_code: String!
  ) {
    createOrder(
      createOrderInput: {
        calling_code: $calling_code
        products: $products
        phone: $phone
      }
    ) {
      _id
      products {
        product {
          _id
          name
          price
          shop {
            name
          }
          images {
            lg
          }
        }
        quantity
      }
      createdAt
      amount
      state
      status
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(id: $id) {
      _id
      amount
    }
  }
`;
