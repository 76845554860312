import { gql } from "@apollo/client";

export const CREATE_DELIVERY = gql`
  mutation createDelivery(
    $addressA: String!
    $nameA: String!
    $phoneA: String!
    $addressB: String!
    $nameB: String!
    $phoneB: String!
    $description: String!
    $deliveryAmount: Float!
  ) {
    createDelivery(
      createDeliveryInput: {
        addressA: $addressA
        nameA: $nameA
        phoneA: $phoneA
        addressB: $addressB
        nameB: $nameB
        phoneB: $phoneB
        description: $description
        deliveryAmount: $deliveryAmount
      }
    ) {
      _id
      addressA
      nameA
      phoneA
      addressB
      nameB
      phoneB
      deliveryAmount
      description
      owner {
        _id
      }
    }
  }
`;
export const EDIT_DELIVERY = gql`
  mutation editDelivery(
    $id: String!
    $addressA: String!
    $nameA: String!
    $phoneA: String!
    $addressB: String!
    $nameB: String!
    $phoneB: String!
    $description: String!
    $deliveryAmount: Float!
  ) {
    updateDelivery(
      updateDeliveryInput: {
        id: $id
        addressA: $addressA
        nameA: $nameA
        phoneA: $phoneA
        addressB: $addressB
        nameB: $nameB
        phoneB: $phoneB
        description: $description
        deliveryAmount: $deliveryAmount
      }
    ) {
      _id
      addressA
      nameA
      phoneA
      addressB
      nameB
      phoneB
      deliveryAmount
      description
      owner {
        _id
      }
    }
  }
`;

export const REMOVE_DELIVERY = gql`
  mutation removeDelivery($id: ID!) {
    removeDelivery(id: $id) {
      _id
    }
  }
`;
