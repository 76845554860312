import Button from "../form/button";
import InputField from "../form/input";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setDeliveryNameA,
  setDeliveryNameB,
  setDeliveryAddressA,
  setDeliveryAddressB,
  setDeliveryPhoneA,
  setDeliveryPhoneB,
  setDeliveryStart,
  setPhoneAVaidity,
  setPhoneBVaidity,
  setOrderAmount,
  setDeliveryDescription,
  setDeliveries,
} from "../../features/deliveries/deliveriesSlice";
import { formatPhone } from "../../utils/format";
import { phoneValidity } from "../../utils/validation";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_DELIVERY, EDIT_DELIVERY } from "../../api/deliveries/mutations";
import toast from "react-hot-toast";
import { DELIVERIES_BY_OWNER } from "../../api/deliveries/queries";

interface Props {
  isRadioHiden?: boolean;
}

interface State {
  files: string[];
  renderImages: string[];
  photoModalVisible: boolean;
  resizeModalVisible: boolean;
}

const NewDelivery = (props: Props) => {
  const [state, setState] = useState<State | any>({
    files: [],
    currentIndex: 0,
    photoModalVisible: false,
    resizeModalVisible: false,
    isPhoneValid: false,
    password: "",
    phone: "",
    isWaiting: false,
  });

  const logged = useAppSelector((state: RootState) => state.utils.logged);

  const dispatch = useAppDispatch();
  const textareRef = useRef<HTMLTextAreaElement>(null);

  const delivery = useAppSelector(
    (state: RootState) => state.deliveries.delivery
  );
  const location = useAppSelector((state: RootState) => state.utils.location);
  const user = useAppSelector((state: RootState) => state.users.user);

  const toggleLineClamp = () => {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  };

  const [getDeliveries] = useLazyQuery(DELIVERIES_BY_OWNER, {
    onCompleted: (data) => {
      if (data) {
        dispatch(setDeliveries(data?.deliveriesByOwner?.page?.edges));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [submitDelivery] = useMutation(CREATE_DELIVERY, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const [editDelivery] = useMutation(EDIT_DELIVERY, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleSubmit = (e: any) => {
    if (logged) {
      setState((prevState) => ({
        ...prevState,
        isWaiting: true,
      }));
      switch (delivery?.action) {
        case "EDIT":
          if (delivery.isDeliveryStartAtA) {
            editDelivery({
              variables: {
                id: delivery?._id,
                addressA: user?.address,
                nameA: user?.firstname,
                phoneA: user?.phone,
                addressB: delivery?.addressB,
                nameB: delivery?.nameB,
                phoneB: delivery?.phoneB,
                description: delivery?.description,
                deliveryAmount: 1500,
              },
            }).then(() => {
              toast.success("Votre modification à été prise en compte");
            });
          } else {
            editDelivery({
              variables: {
                id: delivery?._id,
                addressA: delivery?.addressA,
                nameA: delivery?.nameA,
                phoneA: delivery?.phoneA,
                addressB: delivery?.addressB,
                nameB: delivery?.nameB,
                phoneB: delivery?.phoneB,
                description: delivery?.description,
                deliveryAmount: 1500,
              },
            }).then(() => {
              toast.success("Votre modification à été prise en compte");
            });
          }
          break;

        default:
          if (delivery.isDeliveryStartAtA) {
            submitDelivery({
              variables: {
                addressA: user?.address,
                nameA: user?.firstname,
                phoneA: user?.phone,
                addressB: delivery?.addressB,
                nameB: delivery?.nameB,
                phoneB: delivery?.phoneB,
                description: delivery?.description,
                deliveryAmount: 1500,
              },
            }).then(() => {
              toast.success("Votre livraison à été prise en compte");
              toast.success("Veuillez attendre l'appel du livreur");
            });
          } else {
            submitDelivery({
              variables: {
                addressA: delivery?.addressA,
                nameA: delivery?.nameA,
                phoneA: delivery?.phoneA,
                addressB: delivery?.addressB,
                nameB: delivery?.nameB,
                phoneB: delivery?.phoneB,
                description: delivery?.description,
                deliveryAmount: 1500,
              },
            }).then(() => {
              toast.success("Votre livraison à été prise en compte");
              toast.success("Veuillez attendre l'appel du livreur");
            });
          }
          break;
      }
      setTimeout(
        () =>
          getDeliveries({
            fetchPolicy: "cache-and-network",
            nextFetchPolicy: "cache-first",
            errorPolicy: "all",
            variables: {
              first: 50,
            },
          }),
        1000
      );
    }
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "addressA":
        dispatch(setDeliveryAddressA(e.target.value));
        break;
      case "nameA":
        dispatch(setDeliveryNameA(e.target.value));
        break;
      case "phoneA":
        dispatch(
          setDeliveryPhoneA(
            formatPhone(location?.country_code2, e.target.value)
          )
        );
        dispatch(
          setPhoneAVaidity(
            phoneValidity(location?.country_code2, e.target.value)
          )
        );

        break;
      case "addressB":
        dispatch(setDeliveryAddressB(e.target.value));
        break;
      case "nameB":
        dispatch(setDeliveryNameB(e.target.value));
        break;
      case "phoneB":
        dispatch(
          setDeliveryPhoneB(
            formatPhone(location?.country_code2, e.target.value)
          )
        );
        dispatch(
          setPhoneBVaidity(
            phoneValidity(location?.country_code2, e.target.value)
          )
        );
        break;
      case "amount":
        dispatch(setOrderAmount(e.target.value));
        break;
      case "description":
        dispatch(setDeliveryDescription(e.target.value));
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col justify-center items-center p-2 overflow-y-scroll scrollbar-thin scrollbar-black-thumb">
      <div className="flex justify-center items-center">
        <img src={logo} alt="" className="h-20 object-scale-down max-w-max" />
      </div>
      <div className="flex flex-col md:flex-row justify-evenly items-center w-full h-full border">
        <div className="md:flex hidden flex-col justify-center items-center h-full w-full md:w-6/12 border-0 md:border p-2 m-1 md:mr-0 md:rounded-l-md">
          <div className="flex flex-col justify-start items-start h-80 w-full overflow-y-scroll scrollbar-thin">
            <div className="flex justify-evenly m-1 mb-5 items-stretch rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                A :
              </span>
              <span
                className="font-thin m-1 p-1"
                style={{
                  maxWidth: "90px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                }}
              >
                {delivery.isDeliveryStartAtA
                  ? "xxxxxxxx"
                  : delivery?.addressA?.length === undefined
                  ? user?.address
                  : delivery?.addressA}
              </span>
              <span className="font-thin m-1 p-1">|</span>
              <span
                className="font-thin m-1 p-1"
                style={{
                  maxWidth: "90px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                }}
              >
                {delivery?.nameA?.length === undefined ||
                delivery?.nameA?.length === 0
                  ? "xxx"
                  : delivery.isDeliveryStartAtA
                  ? user?.firstname
                  : delivery?.nameA}
              </span>
              <span className="font-thin m-1 p-1">-</span>
              <span className="font-thin m-1 p-1">
                {delivery?.phoneA?.length === undefined ||
                delivery?.phoneA?.length === 0
                  ? "xxxxxxxx"
                  : delivery.isDeliveryStartAtA
                  ? formatPhone(location?.country_code2, user?.phone)
                  : delivery?.phoneA}
              </span>
            </div>
            <div className="flex justify-evenly m-1 mb-5 items-stretch rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                B :
              </span>
              <span
                className="font-thin m-1 p-1"
                style={{
                  maxWidth: "90px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                }}
              >
                {delivery?.addressB?.length === undefined ||
                delivery?.addressB?.length === 0
                  ? "xxxxxxxx"
                  : delivery?.addressB}
              </span>
              <span className="font-thin m-1 p-1">|</span>
              <span
                className="font-thin m-1 p-1"
                style={{
                  maxWidth: "90px",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                }}
              >
                {delivery?.nameB?.length === undefined ||
                delivery?.nameB?.length === 0
                  ? "xxx"
                  : delivery?.nameB}
              </span>
              <span className="font-thin m-1 p-1">-</span>
              <span className="font-thin m-1 p-1">
                {delivery?.phoneB?.length === undefined ||
                delivery?.phoneB?.length === 0
                  ? "xxxxxxxx"
                  : delivery?.phoneB}
              </span>
            </div>
            <div className="flex justify-evenly m-1 mb-5 items-stretch rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                Commande :
              </span>
              <span className="font-thin m-1 p-1">
                {delivery?.amount?.length === undefined ||
                delivery?.amount?.length === 0
                  ? "0"
                  : delivery?.amount}
              </span>
            </div>
            {/* <div className="flex justify-evenly m-1 mb-5 items-stretch rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                Livraison :
              </span>
              <span className="font-thin m-1 p-1">?</span>
            </div> 
            <div className="flex justify-evenly m-1 mb-5 items-stretch rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                Total :
              </span>
              <span className="font-thin m-1 p-1">
                {parseInt(delivery.amount.replace(/[\D\s-]/g, ""), 10)}
              </span>
            </div>*/}
            <div className="flex justify-center m-1 items-start rounded-md bg-gray-200">
              <span className="font-bold capitalize m-1 p-1 min-w-max">
                Détails :
              </span>
              {!state?.lineClamp && delivery?.description?.length <= 25 ? (
                <p
                  className="border-none w-full text-left outline-none text-sm px-2 py-1 rounded-md ml-1"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    overflow: "hidden",
                  }}
                  onClick={toggleLineClamp}
                >
                  {delivery?.description}
                </p>
              ) : (
                <textarea
                  disabled={true}
                  id="description"
                  name="description"
                  rows={7}
                  value={delivery?.description}
                  className={`border-none text-start resize-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
                  onClick={toggleLineClamp}
                />
              )}

              {delivery?.description?.length > 25 ? (
                <span
                  className="text-sm font-thin text-gray-400 cursor-pointer"
                  onClick={toggleLineClamp}
                >
                  {state.showMoreOrLess}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full w-full md:w-6/12 border-0 md:border border-l-0 p-2 m-1 ml-0 rounded-r-md">
          <div className="flex flex-col h-full md:h-80 w-full overflow-y-scroll scrollbar-thin scrollbar-black-thumb">
            {!props?.isRadioHiden && (
              <div className="m-2 flex flex-row justify-evenly items-center border-2 border-gray-100 rounded-md p-2">
                <span className="font-bold">Point A chez moi ?</span>
                <div
                  className="flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md hover:bg-gray-200"
                  onClick={() => {
                    dispatch(setDeliveryStart(true));
                  }}
                >
                  <input
                    type="radio"
                    name="delivery"
                    onChange={() => dispatch(setDeliveryStart(true))}
                    checked={delivery?.isDeliveryStartAtA === true}
                  />{" "}
                  <span className="px-2">Oui</span>
                </div>
                <div
                  className="flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md hover:bg-gray-200"
                  onClick={() => {
                    dispatch(setDeliveryStart(false));
                  }}
                >
                  <input
                    type="radio"
                    name="delivery"
                    onChange={() => dispatch(setDeliveryStart(false))}
                    checked={delivery.isDeliveryStartAtA === false}
                  />
                  <span className="px-2">Non</span>
                </div>
              </div>
            )}
            {!delivery.isDeliveryStartAtA ? (
              <>
                <InputField
                  id="addressA"
                  type="text"
                  autoFocus={true}
                  labelName="addressA"
                  value={delivery.addressA}
                  isValid={true}
                  placeholder="Entrez le point de départ"
                  handleInputChange={handleInputChange}
                />
                <InputField
                  id="nameA"
                  type="text"
                  labelName="nameA"
                  value={delivery.nameA}
                  isValid={true}
                  placeholder="Entrez le nom"
                  handleInputChange={handleInputChange}
                />
                <InputField
                  id="phoneA"
                  type="text"
                  labelName="phoneA"
                  value={delivery.phoneA}
                  isValid={delivery.isPhoneAValid}
                  placeholder="Entrez le numero du point de depart"
                  handleInputChange={handleInputChange}
                />
              </>
            ) : null}

            <InputField
              id="addressB"
              type="text"
              labelName="addressB"
              value={delivery.addressB}
              isValid={true}
              placeholder="Entrez le point d'arrivé"
              handleInputChange={handleInputChange}
            />
            <InputField
              id="nameB"
              type="text"
              labelName="nameB"
              value={delivery.nameB}
              isValid={true}
              placeholder="Entrez le nom du point d'arrivé"
              handleInputChange={handleInputChange}
            />
            <InputField
              id="phoneB"
              type="tel"
              labelName="phoneB"
              value={delivery.phoneB}
              labelText="Numéro de téléphone"
              placeholder="Entrez le numero du point d'arrivé"
              handleInputChange={handleInputChange}
              isValid={delivery.isPhoneBValid}
            />
            <InputField
              id="amount"
              type="text"
              labelName="amount"
              value={delivery.amount}
              isValid={delivery.isAmountValid}
              placeholder="Entrez le montant de la commande"
              handleInputChange={handleInputChange}
            />
            <div
              style={{
                borderColor:
                  document.activeElement === textareRef.current
                    ? delivery.isDescriptionValid
                      ? "rgba(243, 244, 246, var(--tw-border-opacity))"
                      : "red"
                    : "rgba(243, 244, 246, var(--tw-border-opacity))",
              }}
              className="flex justify-between items-center w-full border-2 border-gray-100 rounded-md p-2 mb-2"
            >
              <textarea
                id="description"
                ref={textareRef}
                name="description"
                maxLength={500}
                value={delivery.description}
                onChange={handleInputChange}
                placeholder="Autres détails que vous jugez necessaire ..."
                className={`border-none w-full outline-none resize-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start w-full">
        <Button
          title="Valider"
          className="px-3 max-w-max"
          handleOnClick={handleSubmit}
          isWaiting={state.isWaiting}
          disabled={state.isWaiting}
        />
      </div>
    </div>
  );
};

export default NewDelivery;
