import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useAppDispatch } from "../../app/hooks";
import { CANCEL_ORDER } from "../../api/orders/mutations";
import { formatDate, formatMoney } from "../../utils/format";
import { InfoSharp, DeleteSharp } from "@mui/icons-material";
import { removeFromOrders } from "../../features/orders/ordersSlice";
import { ReactComponent as Spinner } from "./../../assets/spinner/spinner.svg";

interface Props {
  order: any;
}

const Order = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isWaiting: false,
  });
  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
      }
      dispatch(removeFromOrders(data?.cancelOrder?._id));
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });
  const handleSumbit = (e, id) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    cancelOrder({
      variables: {
        id,
      },
    });
  };
  return (
    <div className="group flex flex-col w-full md:w-3/5 mb-3 border hover:border-0 rounded-md hover:shadow-2xl transition-all ease-linear duration-300 p-1 md:p-2">
      <div className="flex w-full justify-between items-start p-2">
        <span className="font-bold">
          {formatDate(props.order?.node?.createdAt)} à{" "}
          {new Date(props.order?.node?.createdAt).getHours()} {" h "}{" "}
          {new Date(props.order?.node?.createdAt).getMinutes()} {" mn"}
        </span>
        <InfoSharp />
      </div>
      <div className="flex w-full justify-between items-start px-4 my-1">
        <span>
          <span className="font-bold">Montant:</span>{" "}
          {formatMoney.format(props.order?.node?.amount)}
        </span>
        <span>
          {
            {
              PENDING_ORDER: "En attente de validation",
              VALIDATED: "En attente de livraison",
              CANCELLED: "Commande annulé",
              DELIVERED: "Déja livré",
            }[props.order?.node?.state]
          }
        </span>
      </div>
      <div
        className={
          "hidden group-hover:flex group-hover:border mx-4 rounded-md flex-col p-2 max-h-80 overflow-y-scroll scrollbar-thin scrollbar-black-thumb"
        }
      >
        {props.order?.node?.products?.map((orderItem, i) => (
          <div
            className="flex w-full mb-3 h-32 border hover:border-0 rounded-md hover:shadow-2xl transition-all ease-linear duration-300 p-1 md:p-2"
            key={i}
          >
            <div className="flex w-1/3 md:w-1/4">
              <img
                src={orderItem?.product?.images[0]?.lg}
                alt=""
                className="object-cover w-full rounded-md"
              />
            </div>
            <div className="flex flex-col justify-between w-2/3 md:w-3/4 px-2">
              <div className="flex w-full justify-between items-start">
                <div className="flex flex-col w-2/3">
                  <div className="flex items-center w-full">
                    <span className="overflow-hidden text-ellipsis font-bold whitespace-nowrap">
                      {orderItem?.product?.name}
                    </span>
                  </div>
                  <div className="flex items-center w-full">
                    <span className="overflow-hidden text-ellipsis font-bold whitespace-nowrap text-yellow-500 text-sm">
                      {orderItem?.product?.shop?.name}
                    </span>
                  </div>
                </div>

                <div className="flex flex-col w-1/3 items-end justify-center">
                  <span className="font-thin p-1 my-1">
                    {orderItem?.product?.price} F CFA {"x"}{" "}
                    {orderItem?.quantity}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex w-full justify-between items-start px-4">
        {state.isWaiting ? (
          <div className="flex justify-center items-center">
            <Spinner
              style={{
                position: "absolute",
              }}
              className="inline-flex h-5 max-w-max"
            />
          </div>
        ) : (
          <div
            onClick={(e) => handleSumbit(e, props.order?.node?._id)}
            className="flex max-w-max md:bg-djeliba rounded-md md:px-2 my-1 hover:cursor-pointer"
          >
            <DeleteSharp />
            <span className="hidden md:flex">Annuler</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Order;
