import { gql } from "@apollo/client";

export const DELIVERIES_BY_OWNER = gql`
  query deliveriesByOwner($first: Float!, $after: String) {
    deliveriesByOwner(first: $first, after: $after) {
      pageData {
        limit
        count
        offset
      }
      page {
        edges {
          cursor
          node {
            _id
            addressA
            nameA
            phoneA
            addressB
            nameB
            phoneB
            deliveryAmount
            createdAt
            updatedAt
            status
            state
            description
          }
        }
      }
    }
  }
`;

export const DELIVERIES = gql`
  query deliveries($first: Float!, $after: String) {
    deliveries(first: $first, after: $after) {
      pageData {
        limit
        count
        offset
      }
      page {
        edges {
          cursor
          node {
            _id
            addressA
            nameA
            phoneA
            addressB
            nameB
            phoneB
            deliveryAmount
            createdAt
            updatedAt
            status
            state
            description
          }
        }
      }
    }
  }
`;
