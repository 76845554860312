import toast from "react-hot-toast";
import { RootState } from "../../app/store";
import { useMutation } from "@apollo/client";
import { formatPhone } from "../../utils/format";
import Button from "../../components/form/button";
import InputField from "../../components/form/input";
import {
  setFirstName,
  setLastName,
  setUser,
} from "../../features/users/usersSlice";
import { UPDATE_USER } from "../../api/users/mutations";
import React, { useCallback, useEffect, useState } from "react";
import { isEmailValid, phoneValidity } from "../../utils/validation";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

interface Props {
  handleDismiss: any;
}

const EditInfos = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.users.user);
  const location = useAppSelector((state: RootState) => state.utils.location);
  const [state, setState] = useState({
    firstname: user?.firstname === null ? "" : user?.firstname,
    lastname: user?.lastname === null ? "" : user?.lastname,
    email: user?.email === null ? "" : user?.email,
    phone:
      user?.phone === null
        ? ""
        : formatPhone(location?.country_code2, user?.phone),
    address: user?.address === null ? "" : user?.address,
    password: "",
    isWaiting: false,
    isEmailValid: true,
    isNumberValid: true,
    isAddressValid: true,
    isPhoneValidated: false,
  });

  let [submit] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        toast.success("Mise à jour réussie.");
        dispatch(setUser(data?.updateUser));
        props.handleDismiss();
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "phone":
        setState((prevState) => ({
          ...prevState,
          isNumberValid: phoneValidity(location?.country_code2, e.target.value),
          [e.target.name]: formatPhone(location?.country_code2, e.target.value),
        }));
        break;
      case "email":
        setState((prevState) => ({
          ...prevState,
          isEmailValid: isEmailValid(e.target.value),
          [e.target.name]: e.target.value,
        }));
        break;
      case "lastname":
        dispatch(setLastName(e.target.value));
        break;
      case "firstname":
        dispatch(setFirstName(e.target.value));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        break;
    }
  };

  const handleSubmit = useCallback(() => {
    let data = {};
    if (user.isEmailValid) {
      data = {
        ...data,
        email: state.email.replace(/\s+/g, ""),
      };
    }
    if (user?.isNumberValid) {
      data = {
        ...data,
        phone: state.phone.replace(/\s+/g, ""),
      };
    }
    if (user?.isLastNameValid) {
      data = {
        ...data,
        lastname: user?.lastname,
      };
    }
    if (user?.isFirstNameValid) {
      data = {
        ...data,
        firstname: user?.firstname,
      };
    }

    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    submit({
      variables: data,
    });
  }, [submit, state, user]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSubmit]);

  return (
    <div className="bg-gray-50 flex justify-center items-center flex-col py-7">
      <div className="flex justify-center items-center bg-white w-full h-12">
        <span className="font-bold text-2xl">Modifier mes informations</span>
      </div>
      <div className="p-3 pb-0 flex flex-col">
        <InputField
          type="text"
          id="lastname"
          autoFocus={true}
          isRequired={true}
          labelName="lastname"
          value={user.lastname}
          isValid={user.isLastNameValid}
          placeholder="Entrez votre nom ici"
          handleInputChange={handleInputChange}
        />
        <InputField
          type="text"
          id="firstname"
          isRequired={true}
          labelName="firstname"
          value={user.firstname}
          isValid={user.isFirstNameValid}
          placeholder="Entrez votre prénom ici"
          handleInputChange={handleInputChange}
        />
        <InputField
          id="phone"
          type="tel"
          icon={location?.country_flag}
          calling_code={location?.calling_code}
          labelName="phone"
          value={state?.phone}
          isValid={state?.isNumberValid ? true : false}
          info={location?.country_code2?.toLocaleUpperCase()}
          placeholder="Entrez votre numéro ici"
          handleInputChange={handleInputChange}
          autoFocus={true}
          autoComplete={"off"}
        />
        <InputField
          id="email"
          type="email"
          labelName="email"
          value={state?.email}
          placeholder="Entrez votre email ici"
          handleInputChange={handleInputChange}
          isValid={state?.isEmailValid ? true : false}
        />
        <Button
          title="Valider"
          className="px-3"
          hidden={user.isRegisteredOnU54}
          isWaiting={state.isWaiting}
          disabled={
            state?.isWaiting || !user.isFirstNameValid || !user.isLastNameValid
          }
          handleOnClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default EditInfos;
