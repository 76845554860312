// Detects if device is on iOS
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod|macintosh/.test(userAgent);
};

// Detects if device is on iOS
const isSafari = () => {
  return navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent
    ? navigator.userAgent.indexOf("CriOS") === -1 &&
        navigator.userAgent.indexOf("FxiOS") === -1
    : false;
};

const isMobile = () => {
  return window.innerWidth < 1025;
};

export const isIOS = (): boolean => {
  // @ts-ignore
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  return isIos() || (isSafari() && isMobile());
};
