import axios from "axios";
import toast from "react-hot-toast";
import { isEmailValid } from "../../utils/validation";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  access_token: string;
  user: {
    _id?: string;
    email: string;
    phone: string;
    step: number;
    avatar: string;
    count: number;
    address: string;
    lastname: string;
    isSeller: boolean;
    hasShop: boolean;
    contactBy: string;
    firstname: string;
    calling_code: string;
    isEmailValid: boolean;
    isLastNameValid: boolean;
    isFirstNameValid: boolean;
    sellerRequestState: string;
    isWaitingForAvatarUpload: boolean;
  };
}

const initialState: UserState = {
  access_token: "",
  user: {
    step: 0,
    count: 0,
    email: "",
    phone: "",
    avatar: "",
    address: "",
    lastname: "",
    contactBy: "",
    firstname: "",
    isSeller: false,
    hasShop: false,
    calling_code: "",
    isEmailValid: false,
    sellerRequestState: "",
    isLastNameValid: false,
    isFirstNameValid: false,
    isWaitingForAvatarUpload: false,
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setEmail: (state, action: PayloadAction<any>) => {
      state.user.email = action.payload;
      state.user.isEmailValid = isEmailValid(action.payload);
    },
    setToken: (state, action) => {
      state.access_token = action.payload;
    },
    setStep: (state, action) => {
      state.user.step = action.payload;
    },
    setHasShop: (state) => {
      state.user.hasShop = true;
      state.user.isSeller = true;
    },
    setFirstName: (state, action: PayloadAction<any>) => {
      state.user.firstname = action.payload;
      var regex = /^[a-zA-Z].{2,}$/g;
      state.user.isFirstNameValid = regex.test(action.payload);
    },
    setLastName: (state, action: PayloadAction<any>) => {
      state.user.lastname = action.payload;
      var regex = /^[a-zA-Z].{2,}$/g;
      state.user.isLastNameValid = regex.test(action.payload);
    },
    setContactBy: (state, action: PayloadAction<any>) => {
      state.user.contactBy = action.payload;
    },
    setIsWaitingForAvatarUpload: (state, action: PayloadAction<any>) => {
      state.user.isWaitingForAvatarUpload = action.payload;
    },
  },
});

export const {
  setUser,
  setStep,
  setEmail,
  setToken,
  setAvatar,
  setHasShop,
  setLastName,
  setContactBy,
  setFirstName,
  setIsWaitingForAvatarUpload,
} = usersSlice.actions;

export default usersSlice.reducer;

export const uploadAvatar = (avatar) => async (dispatch) => {
  dispatch(setIsWaitingForAvatarUpload(true));
  try {
    await axios
      .put(`https://lol.djeliba.com/users/avatar`, avatar, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        dispatch(setAvatar(res.data));
        dispatch(setIsWaitingForAvatarUpload(false));
        toast.success("Mise à jour réussie.");
      })
      .catch((err) => {
        console.error(err);
        dispatch(setIsWaitingForAvatarUpload(false));
        toast.error("Erreur de soumission.");
        toast("Veuillez réessayer", {
          icon: "ℹ️",
        });
      });
  } catch (error) {
    return console.error(error);
  }
};
