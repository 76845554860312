import { useQuery } from "@apollo/client";
import { MdAddBox } from "react-icons/md";
import icon from "../../assets/logo/icon.png";
import { IoMdAddCircle } from "react-icons/io";
import { useAppDispatch } from "../../app/hooks";
import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import Sidebar from "../../components/layout/sidebar";
import { CATEGORIES, SEGMENTS } from "../../api/utils/queries";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setCategories, setSegments } from "../../features/utils/utilsSlice";
import { toggleNewProductModal } from "../../features/products/productsSlice";
import { SHOP } from "../../api/shop/queries";
import { setShop } from "../../features/shops/shopsSlice";
import {
  setActiveDelivery,
  toggleNewDeliveryModal,
} from "../../features/deliveries/deliveriesSlice";

interface Props {}

function Desktop(props: Props) {
  const [state, setState] = useState({
    activeLink: "/",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: shop } = useQuery(SHOP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: localStorage.getItem("access_token") === null,
    errorPolicy: "all",
  });

  const { data: segments } = useQuery(SEGMENTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: localStorage.getItem("access_token") === null,
    errorPolicy: "all",
    variables: {
      first: 5,
    },
  });

  const { data: categories } = useQuery(CATEGORIES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: localStorage.getItem("access_token") === null,
    errorPolicy: "all",
    variables: {
      first: 5,
    },
  });

  useEffect(() => {
    if (shop) {
      dispatch(setShop(shop?.shopByUser));
    }
    if (segments) {
      dispatch(setSegments(segments?.segments?.page?.edges));
    }
    if (categories) {
      dispatch(setCategories(categories?.categories?.page?.edges));
    }
  }, [segments, categories, dispatch]);

  const handleCreateDelivery = (e) => {
    e.preventDefault();
    dispatch(setActiveDelivery({ action: "CREATE" }));
    dispatch(toggleNewDeliveryModal());
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      activeLink: location.pathname.replace(/^\/+|\/+$/g, ""),
    }));
  }, [location, state.activeLink]);

  return (
    <>
      {/* The Grid */}
      <div
        className="mx-auto min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "2em 5em auto",
        }}
      >
        {/* Banner hack */}
        <div
          className="banner"
          style={{
            gridColumn: "1 / -1",
            backgroundColor: "#a5a39f49",
          }}
        ></div>

        {/* Header */}
        <Header />
        <div
          style={{
            display: "grid",
            borderTopWidth: "0.5px",
          }}
          className="min-h-screen grid-cols-12 row-span-2 md:auto-rows-auto"
        >
          <div
            className="md:col-span-2 fixed md:relative bottom-0 w-full h-16 md:h-auto"
            style={{
              borderRightWidth: "0.5px",
            }}
          >
            <Sidebar />
          </div>
          <div className="col-span-full md:col-span-10">
            <Outlet />

            <div className="border group hover:cursor-pointer fixed flex justify-center items-center bottom-20 md:bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none">
              {/^(desktop)\/.{1,}\/(products)$/.test(state.activeLink) ? (
                <div
                  onClick={() => dispatch(toggleNewProductModal())}
                  className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border group-hover:border-0 group/icon"
                >
                  <IoMdAddCircle
                    size={35}
                    className="group-hover:text-slate-400"
                  />
                  <div className="min-w-max border border-djeliba absolute m-1 flex right-14 bottom-2 invisible group-hover/icon:visible rounded-md px-1 bg-white ">
                    <span className="text-xs font-thin">
                      Ajouter un produit
                    </span>
                  </div>
                </div>
              ) : /^(desktop)\/.{1,}\/(deliveries)$/.test(state.activeLink) ? (
                <div
                  onClick={handleCreateDelivery}
                  className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border group-hover:border-0 group/icon"
                >
                  <IoMdAddCircle
                    size={35}
                    className="group-hover:text-slate-400"
                  />
                  <div className="min-w-max border border-djeliba absolute m-1 flex right-14 bottom-2 invisible group-hover/icon:visible rounded-md px-1 bg-white ">
                    <span className="text-xs font-thin">
                      Nouvelle livraison
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="absolute w-12 h-12 rounded-full"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    className="object-scale-down rounded-full"
                    src={icon}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Desktop;
