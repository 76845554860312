import React, { useEffect } from "react";
import { RootState } from "../../app/store";
import { FaUserEdit } from "react-icons/fa";
import { classNames } from "../../utils/utils";
import { setStep } from "../../features/users/usersSlice";
import { MdConnectWithoutContact } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { BsCheckCircleFill, BsHourglassTop } from "react-icons/bs";

interface Props {}

const WizardHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.users.user);

  useEffect(() => {
    const { hasBeenAcceptedAsSeller, hasBeenPitched, hasBeenPlanForAPitch,  } =
      user;
    if (hasBeenAcceptedAsSeller) {
      dispatch(setStep(3));
    } else if (hasBeenPitched) {
      dispatch(setStep(2));
    } else if (hasBeenPlanForAPitch) {
      dispatch(setStep(1));
    } else {
      dispatch(setStep(0));
    }
  }, [dispatch, user]);

  return (
    <div className="w-full pt-7 pb-2 flex">
      <div className="w-1/4">
        <div className="relative mb-2">
          <div
            className={
              user.hasRequestedToBeSeller
                ? classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-green-400 rounded-full text-lg text-white flex items-center"
                  )
                : classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  )
            }
            onClick={() => dispatch(setStep(0))}
          >
            <FaUserEdit
              className={
                user.hasRequestedToBeSeller
                  ? classNames("text-center text-white w-full")
                  : classNames("text-center text-gray-600 w-full")
              }
            />
          </div>
        </div>

        <div
          onClick={() => dispatch(setStep(0))}
          className="text-xs text-center md:text-base hover:cursor-pointer"
        >
          Infos
        </div>
      </div>

      <div className="w-1/4">
        <div className="relative mb-2">
          <div
            className="absolute flex align-center items-center align-middle content-center"
            style={{
              width: " calc(100% - 2.5rem - 1rem)",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
              <div
                className="w-0 bg-green-300 py-1 rounded"
                style={{
                  width: user.hasRequestedToBeSeller ? "100%" : "0%",
                }}
              ></div>
            </div>
          </div>
          <div
            className={
              user.hasBeenPlanForAPitch
                ? classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-green-400 rounded-full text-lg text-white flex items-center"
                  )
                : classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  )
            }
            onClick={() => dispatch(setStep(1))}
          >
            <MdConnectWithoutContact
              className={
                user.hasBeenPlanForAPitch
                  ? classNames("text-center text-white w-full")
                  : classNames("text-center text-gray-600 w-full")
              }
            />
          </div>
        </div>

        <div
          onClick={() => dispatch(setStep(1))}
          className="text-xs text-center md:text-base hover:cursor-pointer"
        >
          Rendez-vous
        </div>
      </div>

      <div className="w-1/4">
        <div className="relative mb-2">
          <div
            className="absolute flex align-center items-center align-middle content-center"
            style={{
              width: " calc(100% - 2.5rem - 1rem)",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
              <div
                className="w-0 bg-green-300 py-1 rounded"
                style={{
                  width: user.hasBeenProcessed ? "100%" : "0%",
                }}
              ></div>
            </div>
          </div>

          <div
            className={
              user.hasBeenProcessed
                ? classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-green-400 rounded-full text-lg text-white flex items-center"
                  )
                : classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  )
            }
            onClick={() => dispatch(setStep(2))}
          >
            <BsHourglassTop
              className={
                user.hasBeenProcessed
                  ? classNames("text-center text-white w-full")
                  : classNames("text-center text-gray-600 w-full")
              }
            />
          </div>
        </div>

        <div
          onClick={() => dispatch(setStep(2))}
          className="text-xs text-center md:text-base hover:cursor-pointer"
        >
          Validation
        </div>
      </div>

      <div className="w-1/4">
        <div className="relative mb-2">
          <div
            className="absolute flex align-center items-center align-middle content-center"
            style={{
              width: " calc(100% - 2.5rem - 1rem)",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
              <div
                className="w-0 bg-green-300 py-1 rounded"
                style={{
                  width: user.step > 2 && user.hasBeenProcessed ? "100%" : "0%",
                }}
              ></div>
            </div>
          </div>
          <div
            className={
              user.step >= 3 && user.hasBeenAcceptedAsSeller
                ? classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-green-400 rounded-full text-lg text-white flex items-center"
                  )
                : classNames(
                    "hover:cursor-pointer w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  )
            }
            onClick={() => dispatch(setStep(3))}
          >
            <BsCheckCircleFill
              className={
                user.step >= 3 && user.hasBeenAcceptedAsSeller
                  ? classNames("text-center text-white w-full")
                  : classNames("text-center text-gray-600 w-full")
              }
            />
          </div>
        </div>

        <div
          onClick={() => dispatch(setStep(3))}
          className="text-xs text-center md:text-base hover:cursor-pointer"
        >
          Terminé
        </div>
      </div>
    </div>
  );
};

export default WizardHeader;
