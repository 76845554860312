import { gql } from "@apollo/client";

export const ORDERS_BY_PHONE = gql`
  query ordersByPhone($phone: String!, $calling_code: String!) {
    ordersByPhone(first: 20, phone: $phone, calling_code: $calling_code) {
      pageData {
        limit
        count
        offset
      }
      page {
        edges {
          cursor
          node {
            _id
            products {
              product {
                _id
                name
                price
                shop {
                  name
                }
                images {
                  lg
                }
              }
              quantity
            }
            createdAt
            amount
            state
            status
          }
        }
      }
    }
  }
`;

export const ORDERS_BY_SHOPS = gql`
  query ordersByShops($first: Float!, $after: String, $shopsIds: [ID!]!) {
    ordersByShops(first: $first, after: $after, shopsIds: $shopsIds) {
      pageData {
        limit
        count
        offset
      }
      page {
        edges {
          cursor
          node {
            product {
              _id
              name
              price
              shop {
                name
              }
              images {
                lg
              }
            }
            quantity
          }
        }
      }
    }
  }
`;

export const ORDERS_BY_SHOP = gql`
  query ordersByShop($first: Float!, $after: String, $shopId: ID!) {
    ordersByShop(first: $first, after: $after, shopId: $shopId) {
      pageData {
        limit
        count
        offset
      }
      page {
        edges {
          cursor
          node {
            product {
              _id
              name
              price
              shop {
                name
              }
              images {
                lg
              }
            }
            quantity
          }
        }
      }
    }
  }
`;

export const SHOP_ORDERS_METRICS = gql`
  query shopOrdersMetrics($shopId: ID!) {
    shopOrdersMetrics(shopId: $shopId)
  }
`;
