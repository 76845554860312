import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  clearFilterKeys,
  setFilterKeys,
} from "../../features/products/productsSlice";
import { DeleteOutline } from "@mui/icons-material";

interface Props {}

const SearchFilter = (props: Props) => {
  const data = [
    "Hommes",
    "Femmes",
    "Enfants",
    "Art",
    "Culture",
    "Epicérie",
    "Mode",
  ];
  const dispatch = useAppDispatch();
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );
  const onSetFilterKeys = (item: any) => {
    dispatch(setFilterKeys(item));
  };

  const onClearFilterKeys = () => {
    dispatch(clearFilterKeys());
  };

  return (
    <>
      <div className="bg-gray-50 flex justify-evenly items-center py-7 flex-wrap content-start mt-1">
        {filterKeys?.length > 0 && (
          <div
            onClick={() => onClearFilterKeys()}
            className={
              "m-2 border cursor-pointer bg-red-400 rounded-md hover:shadow-3xl hover:border-0 transition-all ease-linear duration-300 my-2"
            }
          >
            <DeleteOutline className="text-white" />
          </div>
        )}
        {data?.map((item, i) => (
          <div
            key={i}
            onClick={() => onSetFilterKeys(item)}
            className={
              filterKeys?.includes(item)
                ? "ring-4 hover:ring-0 ring-djeliba ring-opacity-70 m-2 border cursor-pointer bg-black text-white rounded-md hover:shadow-3xl hover:border-0 transition-all ease-linear duration-300 my-2"
                : "m-2 border cursor-pointer bg-white rounded-md hover:shadow-3xl hover:border-0 transition-all ease-linear duration-300 my-2"
            }
          >
            <span className="ml-2 my-1 p-3 font-bold">{item}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default SearchFilter;
