import React, { useState, useRef } from "react";
import firebase from "../../services/firebase";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import toast from "react-hot-toast";
import logo from "./../../assets/logo/logo.png";
import { WindowService } from "../../utils/window";
import Helper from "../../components/form/helper";
import Button from "../../components/form/button";
import InputField from "../../components/form/input";
import {
  dismissSignUpModal,
  login,
  setConfirmationResult,
  toggleSignInModal,
} from "../../features/utils/utilsSlice";
import { RootState } from "../../app/store";
import { useLazyQuery, useMutation } from "@apollo/client";
import { REGISTER } from "../../api/users/mutations";
import { setUser } from "../../features/users/usersSlice";
import { formatOTP, formatPhone, removeSpace } from "../../utils/format";
import { isOTPValid, phoneValidity } from "../../utils/validation";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FINDUSER_BYPHONE } from "../../api/users/queries";

declare let window: WindowService;

const auth = getAuth(firebase);
auth.useDeviceLanguage();

interface Props {}

const SignUp = (props: Props) => {
  const [state, setState] = useState({
    isPhoneSubmited: false,
    isPhoneValidated: false,
    country_code: "SN",
    calling_code: "+221",
    isPhoneValid: true,
    cguChecked: true,
    isCodeValid: true,
    isOTPSent: false,
    isWaiting: false,
    country_flag: "",
    password: "",
    phone: "",
    code: "",
  });

  const dispatch = useAppDispatch();

  const confirmationResult = useAppSelector(
    (state: RootState) => state.utils.confirmationResult
  );

  const location = useAppSelector((state: RootState) => state.utils.location);
  const recaptcha = useRef<any>(null);

  const [register] = useMutation(REGISTER, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        dispatch(login());
        localStorage.setItem("access_token", data?.register?.access_token);
        dispatch(setUser(data.register.user));
        toast.success("Votre inscription est déjà terminée.");
        toast.success("Bienvenue sur Djéliba.");
        dispatch(dismissSignUpModal());
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      toast.error(
        "Un compte est déja lié à ce numéro de téléphone, Veuillez vous connecter à votre compte."
      );
      console.error(error);
    },
  });

  const [findUser] = useLazyQuery(FINDUSER_BYPHONE, {
    onCompleted: (data) => {
      if (data) {
        dispatch(setUser(data?.findUserByPhoneNumber));
        toast.error(
          "Un compte est déja lié à ce numéro de téléphone, veuillez vous connecter à votre compte."
        );
        dispatch(dismissSignUpModal());
        dispatch(toggleSignInModal());
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "phone":
        setState((prevState) => ({
          ...prevState,
          isPhoneValid: phoneValidity(location?.country_code2, e.target.value),
          [e.target.name]: formatPhone(location?.country_code2, e.target.value),
        }));
        break;
      case "code":
        setState((prevState) => ({
          ...prevState,
          isCodeValid: isOTPValid(e.target.value),
          [e.target.name]: formatOTP(e.target.value),
        }));
        break;
      case "cgu":
        setState((prevState) => ({
          ...prevState,
          cguChecked: !prevState.cguChecked,
        }));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    onFindUser();
    setUpRecaptcha();
    let phoneNumber = location?.calling_code + removeSpace(state.phone);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        dispatch(setConfirmationResult(confirmationResult));
        setState((prevState) => ({
          ...prevState,
          isOTPSent: true,
          isWaiting: false,
        }));

        toast.success("Un code vous a été envoyé.");
      })
      .catch(function () {
        toast.error("Numéro incorrect ou invalid");
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
      });
  };

  // This function Set Up The reCAPTCHA Verifier
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, recaptcha.current, {
      size: "invisible",
      callback: function (response) {
        this.handleSubmit();
      },
    });
  };

  // Validation code Vérification method
  const onValidateCode = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    try {
      await confirmationResult
        .confirm(removeSpace(state.code))
        .then(function (result) {
          setState((prevState) => ({
            ...prevState,
            isWaiting: false,
            isPhoneValidated: true,
          }));

          toast.success("Votre numéro a été vérifié.");
        })
        .catch(function (error) {
          console.error(error);
          toast.error("Code incorrect ou invalid");
          setState((prevState) => ({
            ...prevState,
            isWaiting: false,
          }));
        });
    } catch (e) {
      console.error(e);
    }
  };

  const onRegister = async (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    register({
      variables: {
        password: state.password.trim(),
        phone: removeSpace(state.phone),
        calling_code: location?.calling_code,
      },
    });
  };

  const onFindUser = async () => {
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    findUser({
      variables: {
        phone: removeSpace(state.phone),
        calling_code: location?.calling_code,
      },
    });
  };

  return (
    <div className="flex justify-center items-center">
      <div className="relative px-11 my-7">
        <div className="flex justify-center items-center my-2">
          <img src={logo} alt="" className="h-20 object-scale-down" />
        </div>
        <InputField
          id="phone"
          type="text"
          icon={location?.country_flag}
          calling_code={location?.calling_code}
          labelName="phone"
          value={state.phone}
          isValid={state.isPhoneValid ? true : false}
          info={location?.country_code2.toLocaleUpperCase()}
          hidden={state.isPhoneValidated}
          placeholder="Entrez votre numéro ici"
          handleInputChange={handleInputChange}
          autoFocus={true}
          autoComplete="off"
        />
        <InputField
          id="code"
          type="text"
          hidden={!state.isOTPSent || state.isPhoneValidated}
          value={state.code}
          isValid={state.isCodeValid ? true : false}
          labelName="code"
          placeholder="Tapez le code reçu par sms"
          handleInputChange={handleInputChange}
        />
        <InputField
          id="password"
          type="password"
          labelName="password"
          value={state.password}
          isValid={
            state.password.length === 0
              ? true
              : state.password.length < 6
              ? false
              : true
          }
          hidden={!state.isPhoneValidated}
          placeholder="Tapez votre mot de passe"
          handleInputChange={handleInputChange}
        />
        <div className={!state.isPhoneValidated ? "hidden" : ""}>
          <input
            type="checkbox"
            id="rememberMe"
            className="checked:bg-blue-600 checked:border-transparent"
            name="cgu"
            checked={state.cguChecked}
            onChange={handleInputChange}
          />
          <label htmlFor="flexCheckDefault" className="text-xs pl-1">
            {" "}
            J'accepte les {/* It's very important to change this */}
            <a
              href="https://djeliba.blob.core.windows.net/files/TOLGO CGU.pdf"
              target="_blank"
              className="text-blue-400 font-weight-bolder"
              rel="noreferrer"
            >
              Termes et conditions d'utilisation
            </a>
          </label>
        </div>
        {!state.isOTPSent && (
          <Button
            title="Envoyer"
            isWaiting={state.isWaiting}
            handleOnClick={handleSubmit}
            disabled={
              state.phone.length === 0 || !state.isPhoneValid || state.isWaiting
            }
          />
        )}
        {state.isOTPSent && !state.isPhoneValidated && (
          <Button
            title="Valider"
            isWaiting={state.isWaiting}
            handleOnClick={onValidateCode}
            disabled={
              state.code.length === 0 || !state.isCodeValid || state.isWaiting
            }
          />
        )}
        {state.isPhoneValidated && (
          <Button
            title="Enregister"
            isWaiting={state.isWaiting}
            handleOnClick={onRegister}
            disabled={
              state.password.length === 0 ||
              state.password.length < 6 ||
              !state.cguChecked ||
              state.isWaiting
            }
          />
        )}
        <Helper view="sign-up" />
      </div>
      <button ref={recaptcha}></button>
    </div>
  );
};

export default SignUp;
