import React from "react";
import { NavLink } from "react-router-dom";
import { classNames } from "../../utils/utils";

interface Props {
  active: boolean;
  title: string;
  link: string;
  icon: any;
}

const SidebarItem = (props: Props) => (
  <NavLink to={props.link}>
    <li
      className={
        props.active
          ? classNames(
              "sidebar-item group bg-black shadow-lg border-transparent flex-shrink relative"
            )
          : classNames("sidebar-item group relative")
      }
    >
      <div
        className={
          props.active
            ? classNames(
                "p-1 group-hover:bg-white rounded-lg md:shadow-3xl bg-white"
              )
            : classNames("p-1 group-hover:bg-white rounded-lg md:shadow-3xl")
        }
      >
        <props.icon
          size={20}
          className={
            props.active
              ? classNames("group-hover:text-black text-black")
              : classNames("group-hover:text-black")
          }
        />
      </div>
      <div
        className={
          props.active
            ? classNames(
                "hidden md:flex md:p-2 group-hover:font-bold group-hover:text-white text-white"
              )
            : classNames(
                "hidden md:flex md:p-2 group-hover:font-bold group-hover:text-white"
              )
        }
      >
        <span>{props.title}</span>
      </div>
    </li>
  </NavLink>
);

export default SidebarItem;
