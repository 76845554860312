import { gql } from "@apollo/client";

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $shop: String!
    $name: String!
    $price: Float!
    $quantity: Float!
    $description: String!
    $isDeliveryFree: Boolean!
    $categories: [String!]!
  ) {
    createProduct(
      createProductInput: {
        shop: $shop
        name: $name
        price: $price
        quantity: $quantity
        categories: $categories
        isDeliveryFree: $isDeliveryFree
        description: $description
      }
    ) {
      _id
      name
      price
      quantity
      description
      isDeliveryFree
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation removeProduct($id: ID!) {
    removeProduct(id: $id) {
      _id
    }
  }
`;

export const HIDE_PRODUCT = gql`
  mutation updateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
      _id
      status
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($updateProductInput: UpdateProductInput!) {
    updateProduct(updateProductInput: $updateProductInput) {
      _id
      price
      name
      quantity
      description
      isDeliveryFree
      categories {
        _id
        name
      }
    }
  }
`;
