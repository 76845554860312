import React from "react";
import Button from "../form/button";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setCart } from "../../features/cart/cartSlice";
import { RootState } from "../../app/store";
import {
  dismissValidationModal,
  resetWishlist,
} from "../../features/wishlist/wishlistSlice";
import { useNavigate } from "react-router-dom";

interface Props {}

const Validation = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const wishlistItems = useAppSelector(
    (state: RootState) => state.wishlist.wishlistItems
  );

  const handleSubmit = (e: any) => {
    dispatch(setCart(wishlistItems));
    dispatch(dismissValidationModal());
    dispatch(resetWishlist());
    toast.success("Votre wishlist à été transféré dans le panier");
    navigate("/products/cart");
  };

  return (
    <div className="bg-gray-50 flex justify-center items-center flex-col py-7">
      <div className="flex justify-center items-center bg-white w-full h-12 ">
        <span className="font-thin text-center px-3">
          Tous les éléments de la wishlist seront transférés dans le panier.
        </span>
      </div>

      <div className="w-1/2">
        <Button title="Valider" handleOnClick={handleSubmit} />
      </div>
    </div>
  );
};

export default Validation;
