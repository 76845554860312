import { RootState } from "../../app/store";
import { FcEditImage } from "react-icons/fc";
import React, { useRef, useState } from "react";
import Button from "../../components/form/button";
import { uploadAvatar } from "../../features/users/usersSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

interface Props {
  handleDismiss: any;
}

const UpdateLogo = (props: Props) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const user = useAppSelector((state: RootState) => state.users.user);
  const [state, setState] = useState({
    file: "",
    renderImage: "",
  });

  const handleInputChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      file: e.target.files[0],
      renderImage: URL.createObjectURL(e.target.files[0]),
    }));
  };
  const handleSubmit = () => {
    let data = new FormData();
    data.append("avatar", state?.file);
    dispatch(uploadAvatar(data));
    props.handleDismiss();
  };

  return (
    <div className="bg-gray-50 items-center justify-center flex flex-col py-7">
      <div className="items-center justify-center flex bg-white w-full h-12 px-2">
        <span className="font-bold text-2xl">Modifier le logo</span>
      </div>
      <div className="items-center justify-center flex flex-col p-7 pb-0">
        <div className="relative rounded-full w-full items-center justify-center flex border border-gray-200">
          <FcEditImage
            className="absolute top-0 right-2 cursor-pointer"
            onClick={() => inputRef.current?.click()}
            size={20}
          />
          {user.avatar ? (
            <img
              className="object-cover rounded-full h-40 w-40 shadow-2xl"
              src={state.renderImage ? state.renderImage : user.avatar}
              alt=""
            />
          ) : state.renderImage ? (
            <img
              className="object-cover rounded-full h-40 w-40 shadow-2xl"
              src={state.renderImage}
              alt=""
            />
          ) : (
            <div className="rounded-full h-40 w-40 bg-gray-200"></div>
          )}
        </div>
        <input
          multiple
          type="file"
          name="file"
          ref={inputRef}
          accept="image/*"
          className="hidden"
          onChange={handleInputChange}
        />
        <div className="w-1/2 mt-2">
          <Button
            title="Valider"
            // isWaiting={loading}
            handleOnClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateLogo;
