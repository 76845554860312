import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation register(
    $phone: String!
    $password: String!
    $calling_code: String!
  ) {
    register(
      registerUserInput: {
        phone: $phone
        password: $password
        calling_code: $calling_code
      }
    ) {
      user {
        _id
        phone
        updatedAt
        createdAt
      }
      access_token
    }
  }
`;

export const UPDATE_USER = gql`
  mutation update(
    $email: String
    $phone: String
    $firstname: String
    $lastname: String
  ) {
    updateUser(
      updateUserInput: {
        email: $email
        phone: $phone
        firstname: $firstname
        lastname: $lastname
      }
    ) {
      _id
      phone
      firstname
      lastname
      email
    }
  }
`;

export const SAVE_TOKEN = gql`
  mutation saveToken($fcmToken: String!) {
    saveToken(fcmToken: $fcmToken)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $phone: String!
    $password: String!
    $calling_code: String!
  ) {
    resetPassword(
      resetPasswordInput: {
        phone: $phone
        password: $password
        calling_code: $calling_code
      }
    ) {
      user {
        _id
        phone
        updatedAt
        createdAt
      }
    }
  }
`;

export const REGISTER_USER_REQUEST = gql`
  mutation update(
    $email: String
    $phone: String!
    $firstname: String!
    $lastname: String!
  ) {
    updateUser(
      updateUserInput: {
        email: $email
        phone: $phone
        firstname: $firstname
        lastname: $lastname
        hasRequestedToBeSeller: true
      }
    ) {
      _id
      email
      phone
      firstname
      lastname
      hasRequestedToBeSeller
    }
  }
`;
