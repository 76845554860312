import { gql } from "@apollo/client";

export const CREATE_SHOP = gql`
  mutation createShop($name: String!, $segments: [String!]!) {
    createShop(createShopInput: { name: $name, segments: $segments }) {
      _id
      name
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation update(
    $id: String!
    $name: String
  ) {
    updateShop(
      updateShopInput: {
        id: $id
        name: $name
      }
    ) {
      _id
      name
    }
  }
`;
