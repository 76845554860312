import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface WishlistItem {
  _id: string;
  uri: string;
  shop: object;
  name: string;
  price: number;
  quantity: number;
  description: string;
  isDeliveryFree: boolean;
}

interface WishlistState {
  isWaiting: boolean;
  wishlistItems: WishlistItem[];
  error: any;
  isValidationModalOpen: boolean;
}

const initialState: WishlistState = {
  isWaiting: false,
  wishlistItems: [],
  error: null,
  isValidationModalOpen: false,
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    setWishlist: (state, action: PayloadAction<WishlistItem[]>) => {
      action.payload.forEach((item) => {
        if (
          !state.wishlistItems?.some(function (el) {
            return el?._id === item?._id;
          })
        ) {
          state.wishlistItems = [item, ...state.wishlistItems];
        }
      });
    },
    resetWishlist: (state) => {
      state.wishlistItems = [];
    },
    addToWishlist: (state, action: PayloadAction<WishlistItem>) => {
      if (!state.wishlistItems?.includes(action.payload)) {
        if (state.wishlistItems?.length > 0) {
          state.wishlistItems = [action.payload, ...state.wishlistItems];
        } else {
          state.wishlistItems = [action.payload];
        }
      }
    },
    removeFromWishlist: (state, action: PayloadAction<string>) => {
      state.wishlistItems = state.wishlistItems.filter(
        (item) => item._id !== action.payload
      );
    },
    toggleValidationModal: (state) => {
      state.isValidationModalOpen = !state.isValidationModalOpen;
    },
    dismissValidationModal: (state) => {
      state.isValidationModalOpen = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  hasError,
  setWishlist,
  startWaiting,
  resetWishlist,
  addToWishlist,
  removeFromWishlist,
  toggleValidationModal,
  dismissValidationModal,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
