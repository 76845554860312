import { gql } from "@apollo/client";

export const SEGMENTS = gql`
  query segments($first: Float!, $after: String) {
    segments(first: $first, after: $after) {
      page {
        edges {
          node {
            _id
            name
          }
        }
      }
    }
  }
`;

export const CATEGORIES = gql`
  query categories($first: Float!, $after: String) {
    categories(first: $first, after: $after) {
      page {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            _id
            name
          }
        }
      }
    }
  }
`;
