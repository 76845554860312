import { countryPhoneNumbers } from "./validation";

// Format OTP code
export const formatOTP = (OTP) => {
  let code = OTP.replace(/[\D\s-]/g, "");
  if (code.length === 6) {
    code = code.slice(0, 2) + " " + code.slice(2, 4) + " " + code.slice(4, 6);
  } else {
    code = OTP;
  }
  return code;
};

/**
 * Format Money To a currency format
 */
export const formatMoney = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "XOF",
  useGrouping: true,
  notation: "standard",
  currencyDisplay: "symbol",
});

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const formatDate = (date) => {
  let opt: any = { year: "numeric", month: "long", day: "numeric" };
  let weekday_opt: any = { weekday: "long" };
  let dateToFormat = new Date(date);
  let weekday = toTitleCase(
    dateToFormat.toLocaleDateString("fr-FR", weekday_opt)
  );
  return weekday + ", " + dateToFormat.toLocaleDateString("fr-FR", opt);
};

/**
 *
 * @param isoCountryCode
 * @param phone
 * @returns phone : 77 556 58 80
 */
export const formatPhone = (isoCountryCode = "SN", phone) => {
  const size = countryPhoneNumbers[isoCountryCode]?.phoneNumberSize;
  let number = phone.replace(/[\D\s-]/g, "");
  switch (isoCountryCode) {
    case "SN":
      if (number.length === size) {
        number =
          number.slice(0, 2) +
          " " +
          number.slice(2, 5) +
          " " +
          number.slice(5, 7) +
          " " +
          number.slice(7, size);
      } else {
        number = phone.replace(/[\D\s-]/g, "");
      }
      break;
    case "CI":
      if (number.length === size) {
        number =
          number.slice(0, 2) +
          " " +
          number.slice(2, 5) +
          " " +
          number.slice(5, 8) +
          " " +
          number.slice(8, size);
      } else {
        number = phone;
      }
      break;
    default:
      if (number.length === size) {
        number =
          number.slice(0, 2) +
          " " +
          number.slice(2, 4) +
          " " +
          number.slice(4, 6) +
          " " +
          number.slice(6, size);
      } else {
        number = phone;
      }
      break;
  }
  return number;
};

/**
 * @param text
 * @returns textwithoutspace
 */
export const removeSpace = (text: string) => text.replace(/[\D\s-]/g, "");
