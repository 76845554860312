// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDm5ItwfQ2eiuLV3u_KsWpcPxL3-aoYqRg",
  authDomain: "djeliba.firebaseapp.com",
  projectId: "djeliba",
  storageBucket: "djeliba.appspot.com",
  messagingSenderId: "1031547804581",
  appId: "1:1031547804581:web:6019f594d1c5586dcfa64c",
  measurementId: "G-9SM5V3WRMM",
};
