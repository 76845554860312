import { combineReducers } from "redux";
import utils from "../features/utils/utilsSlice";
import cart from "../features/cart/cartSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import users from "../features/users/usersSlice";
import shops from "../features/shops/shopsSlice";
import orders from "../features/orders/ordersSlice";
import wishlist from "../features/wishlist/wishlistSlice";
import deliveries from "../features/deliveries/deliveriesSlice";
// import Symbol_observable from 'symbol-observable';
import products from "../features/products/productsSlice";
import payments from "../features/payments/paymentsSlice";
import {
  configureStore,
  ThunkAction,
  AnyAction,
  Reducer,
  Action,
} from "@reduxjs/toolkit";

const appReducer = combineReducers({
  payments,
  products,
  deliveries,
  wishlist,
  orders,
  shops,
  users,
  cart,
  utils,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "utils/clearStorage") {
    storage.removeItem("persist:me");
    const { utils } = state;
    state = { utils } as RootState;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "djeli",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
