import React from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  dismissResetPasswordModal,
  dismissSignUpModal,
  toggleResetPasswordModal,
  toggleSignInModal,
  toggleSignUpModal,
} from "../../features/utils/utilsSlice";

interface Props {
  view: string;
}

const Helper = (props: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className="py-3">
      {props.view === "sign-up" && (
        <>
          <p className="my-2 text-xs">
            Numéro incorrect ?
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => {
                window.location.reload();
                dispatch(dismissSignUpModal());
                dispatch(toggleSignUpModal());
              }}
            >
              {" "}
              Recommencer
            </span>
          </p>
          <p className="my-2 text-xs">
            Tu as déja un compte ?
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => dispatch(toggleSignInModal())}
            >
              {" "}
              Se connecter
            </span>
          </p>
        </>
      )}
      {props.view === "sign-in" && (
        <p className="text-xs">
          Tu n'as pas de compte ?
          <span
            className="text-blue-400 cursor-pointer"
            onClick={() => dispatch(toggleSignUpModal())}
          >
            {" "}
            Inscris-toi
          </span>
        </p>
      )}
      {props.view === "sign-in" && (
        <span 
        className="inline-flex text-xs text-center text-blue-400 hover:cursor-pointer"
        onClick={() => dispatch(toggleResetPasswordModal())}
        >
          Mot de passe oublié
        </span>
      )}
      {props.view === "reset-password" && (
        <>
          <p className="my-2 text-xs">
            Numéro incorrect ?
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => {
                window.location.reload();
                dispatch(dismissResetPasswordModal());
                dispatch(toggleResetPasswordModal());
              }}
            >
              {" "}
              Recommencer
            </span>
          </p>
          <p className="my-2 text-xs">
            Vous vous souvenez de votre mot de passe ?
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => {
                dispatch(dismissResetPasswordModal());
                dispatch(toggleSignInModal())
              }}
            >
              {" "}
              Se connecter
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export default Helper;
