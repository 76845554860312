import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface paymentState {
  payment: {
    _id?: string;
    email: string;
    phone: string;
    step: number;
    lastname: string;
    firstname: string;
    paymentMode: string;
  };
  isValidationModalOpen: boolean;
  isTransactionModalOpen: boolean;
  deliveryPrice: number;
}

const initialState: paymentState = {
  payment: {
    step: 0,
    email: "",
    phone: "",
    lastname: "",
    firstname: "",
    paymentMode: "CASH",
  },
  isValidationModalOpen: false,
  isTransactionModalOpen: false,
  deliveryPrice: 1500,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.payment.step = action.payload;
    },
    setPaymentMode: (state, action: PayloadAction<any>) => {
      state.payment.paymentMode = action.payload;
    },
    toggleValidationModal: (state) => {
      state.isValidationModalOpen = !state.isValidationModalOpen;
    },
    dismissValidationModal: (state) => {
      state.isValidationModalOpen = false;
    },
    toggleTransactionModal: (state) => {
      state.isTransactionModalOpen = !state.isTransactionModalOpen;
    },
    dismissTransactionModal: (state) => {
      state.isTransactionModalOpen = false;
    },
  },
});

export const {
  setPaymentMode,
  toggleValidationModal,
  dismissValidationModal,
  toggleTransactionModal,
  dismissTransactionModal,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
