import { gql } from "@apollo/client";

export const SHOP = gql`
  query shopByUser {
    shopByUser {
      _id
      logo
      name
    }
  }
`;
