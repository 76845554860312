import React, { useRef, useState } from "react";
import { RootState } from "../../app/store";
import icon from "../../assets/logo/icon.png";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils/format";
import Modal from "../../components/common/modal";
import Banner from "../../components/layout/banner";
import Header from "../../components/layout/header";
import logo from "./../../assets/logo/logo.png";
import Transaction from "../../components/ux/transaction";
import EmptyCart from "./../../assets/img/empty-cart.jpg";
import Validation from "../../components/ux/order-validation";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CategorySharp,
  DeleteSharp,
  DeliveryDiningSharp,
} from "@mui/icons-material";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../../features/cart/cartSlice";
import {
  dismissValidationModal,
  toggleTransactionModal,
  dismissTransactionModal,
} from "../../features/payments/paymentsSlice";
import { IoMdAddCircle } from "react-icons/io";
import { searchFor } from "../../features/deliveries/deliveriesSlice";

interface Props {}

function Cart(props: Props) {
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const isValidationModalOpen = useAppSelector(
    (state: RootState) => state.payments.isValidationModalOpen
  );
  const isTransactionModalOpen = useAppSelector(
    (state: RootState) => state.payments.isTransactionModalOpen
  );
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );
  const cartItems = useAppSelector((state: RootState) => state.cart.cartItems);
  const total = useAppSelector((state: RootState) => state.cart.total);
  const isDeliveryFree = useAppSelector(
    (state: RootState) => state.cart.isDeliveryFree
  );
  const deliveryPrice = 1500;

  const handleRemoveFromCart = (e, productId) => {
    e.preventDefault();
    dispatch(removeFromCart(productId));
  };

  const handleIncreaseQuantity = (e, productId) => {
    e.preventDefault();
    dispatch(increaseQuantity(productId));
  };

  const handleDecreaseQuantity = (e, productId) => {
    e.preventDefault();
    dispatch(decreaseQuantity(productId));
  };

  const handleGoToShop = (e, product) => {
    e.preventDefault();
    navigate("/shops/" + product?.shop?._id, {
      state: {
        ...product?.shop,
      },
    });
  };

  const handleGoToDetail = (e, product) => {
    navigate("/shops/products/" + product?._id, {
      state: {
        ...product,
      },
    });
  };

  const handleJumpToTop = (e) => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Madimi One";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };
  const dismissSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: false,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));

    dispatch(searchFor(e.target.value));
  };

  return (
    <>
      <Modal
        modalIsOpen={isTransactionModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => dispatch(toggleTransactionModal())}
        dismissModal={() => dispatch(dismissTransactionModal())}
        content={<Transaction handleDismiss={dismissTransactionModal} />}
      />
      <Modal
        modalIsOpen={isValidationModalOpen}
        className="w-4/5 md:w-3/12"
        dismissModal={() => dispatch(dismissValidationModal())}
        content={
          <Validation
            handleDismiss={() => dispatch(dismissValidationModal())}
          />
        }
      />
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "5em auto",
        }}
      >
        {/* Header */}
        {/* Header Hack */}
        <div className="header"></div>
        <div className="fixed top-0 left-0 right-0 z-50 bg-white w-full">
          <Banner className="bg-djeliba" />
          <div
            className="justify-between items-center max-w-full p-2"
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateRows: "auto",
            }}
          >
            <img
              onClick={(e) => handleJumpToTop(e)}
              src={logo}
              alt=""
              className="h-14 object-scale-down hover:cursor-pointer"
            />
            <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
              <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
                <CategorySharp
                  className={
                    filterKeys?.length > 0
                      ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                      : "hover:cursor-pointer bg-transparent border-0"
                  }
                  onClick={() => toggleSearchFilterModal()}
                />
              </div>
              <input
                className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba caret-black"
                placeholder={"Que cherchez vous ?"}
                onBlur={() => handleOnBlur()}
                onFocus={() => handleOnFocus()}
                onChange={(e) => handleSearchBar(e)}
                type="text"
                value={state.searchingFor}
                name="searchingFor"
                ref={inputRef}
                style={{
                  fontFamily: "Leckerli One",
                }}
              />
            </div>
          </div>
        </div>
        {/* Content */}
        {cartItems?.length > 0 ? (
          <div
            className=""
            style={{
              gridColumn: "1 / -1",
            }}
          >
            <div className="flex flex-col md:flex-row justify-between md:justify-evenly w-full h-full px-2 md:p-7">
              <div className="flex flex-col w-full md:w-1/2 md:mx-7">
                {cartItems.map((product, i) => (
                  <div
                    className="flex w-full mb-3 h-32 border hover:border-0 rounded-md hover:shadow-2xl transition-all ease-linear duration-300 p-1 md:p-2"
                    key={i}
                  >
                    <div className="flex w-1/3 md:w-1/4">
                      <img
                        onClick={(e) => handleGoToDetail(e, product)}
                        src={product?.uri}
                        alt=""
                        className="object-cover w-full rounded-md"
                      />
                    </div>
                    <div className="flex flex-col justify-between w-2/3 md:w-3/4 px-2">
                      <div className="flex w-full justify-between items-start">
                        <div className="flex flex-col w-2/3">
                          <div className="flex items-center w-full">
                            <span
                              onClick={(e) => handleGoToDetail(e, product)}
                              className="overflow-hidden text-ellipsis font-bold whitespace-nowrap cursor-pointer"
                            >
                              {product?.name}
                            </span>
                          </div>
                          <div className="flex items-center w-full">
                            <span
                              onClick={(e) => handleGoToShop(e, product)}
                              className="overflow-hidden text-ellipsis font-bold whitespace-nowrap text-yellow-500 text-sm hover:cursor-pointer"
                            >
                              {product?.shop?.name}
                            </span>
                          </div>
                        </div>

                        <div className="flex flex-col w-1/3 items-end justify-center">
                          <span className="font-thin my-1">
                            {product?.price} F
                          </span>
                          <div className="flex justify-end">
                            <span
                              onClick={(e) =>
                                handleDecreaseQuantity(e, product?._id)
                              }
                              className="font-bold rounded-md px-2 mx-1 hover:cursor-pointer border bg-djeliba"
                            >
                              -
                            </span>
                            <span className="font-bold rounded-sm px-1 mx-1 bg-black text-white">
                              {product?.quantity}
                            </span>
                            <span
                              onClick={(e) =>
                                handleIncreaseQuantity(e, product?._id)
                              }
                              className="font-bold rounded-md px-2 mx-1 hover:cursor-pointer border bg-djeliba"
                            >
                              +
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="flex max-w-max md:bg-djeliba rounded-md md:px-2 my-1 hover:cursor-pointer"
                        onClick={(e) => handleRemoveFromCart(e, product?._id)}
                      >
                        <DeleteSharp />
                        <span className="hidden md:flex">Retirer</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col items-center w-full md:w-1/4 h-80 pb-5 md:pb-0">
                <div className="flex flex-col items-center justify-around w-full h-full bg-black rounded-md p-2 mb-4">
                  <div className="flex max-w-max bg-djeliba p-1 my-3 px-3 rounded-md">
                    <span className="font-bold text-white text-lg">
                      Total panier
                    </span>
                  </div>

                  <div className="flex flex-row my-4">
                    <span className="font-bold text-white text">
                      Frais d'achat :
                    </span>
                    <span className="font-thin text-yellow-500 ml-1">
                      {formatMoney.format(total)}
                    </span>
                  </div>

                  <div className="flex flex-row my-4">
                    <span className="font-bold text-white">
                      Frais de livraison :
                    </span>
                    <span className="font-thin ml-1 text-yellow-500">
                      {formatMoney.format(isDeliveryFree ? 0 : deliveryPrice)}
                    </span>
                  </div>

                  <hr className="flex w-1/2 border border-white h-1" />

                  <div className="flex flex-row my-2">
                    <span className="font-bold text-yellow-500 ml-1">
                      {formatMoney.format(
                        isDeliveryFree ? total : total + deliveryPrice
                      )}
                    </span>
                  </div>
                </div>

                <div className="flex max-w-max px-7 py-1 bg-green-400 rounded-md hover:cursor-pointer">
                  <span
                    onClick={() => dispatch(toggleTransactionModal())}
                    className="font-bold text-white text-lg"
                  >
                    Finalisez
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center relative">
            <img
              src={EmptyCart}
              alt=""
              className="object-scale-down md:h-1/3"
            />
          </div>
        )}
        <div className="group hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none">
          <div className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border group-hover:border-0">
            <IoMdAddCircle size={35} className="group-hover:text-slate-400" />
          </div>
          <div
            className={
              "relative justify-center items-center bottom-14 hidden md:bottom-11 w-12 h-12 group-hover:flex rounded-full"
            }
          >
            <div
              onClick={() => {
                navigate("/products/orders");
              }}
              className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border"
            >
              <DeliveryDiningSharp fontSize="large" />
            </div>

            <div
              onClick={() => {
                navigate("/");
              }}
              className="flex justify-center items-center bg-white absolute w-12 h-12 rounded-full border bottom-12"
            >
              <img
                src={icon}
                alt=""
                className="object-scale-down rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
