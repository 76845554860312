import React, { useRef } from "react";
import { MdPassword } from "react-icons/md";
import { LockSharp, EditLocationSharp } from "@mui/icons-material";

interface Props {
  handleInputChange?: any;
  autoComplete?: string;
  calling_code?: string;
  isRequired?: boolean;
  autoFocus?: boolean;
  isDisabled?: boolean;
  placeholder: string;
  labelText?: string;
  labelName: string;
  hidden?: boolean;
  isValid?: boolean;
  styles?: string;
  icon?: string;
  info?: string;
  value: string;
  type: string;
  id: string;
}

const InputField = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={props.styles + " "} hidden={props.hidden}>
      <div
        className="flex justify-between items-center w-full border-2 border-gray-100 rounded-md p-2 mb-2"
        style={{
          borderColor:
            document.activeElement === inputRef.current
              ? props.isValid
                ? "rgba(243, 244, 246, var(--tw-border-opacity))"
                : "red"
              : "rgba(243, 244, 246, var(--tw-border-opacity))",
        }}
      >
        {props.labelName === "phone" && (
          <div className="inline-flex">
            <img
              src={props.icon || "https://assets.ipstack.com/flags/sn.svg"}
              className="h-5 object-cover border-2 rounded-sm"
              alt="Country flag"
            />
            <span className="w-14 px-1 text-sm">{`${props.calling_code}`}</span>
          </div>
        )}
        {props.labelName === "code" && (
          <span className="inline-flex">
            <MdPassword size={28} />
          </span>
        )}
        {props.labelName === "address" && (
          <span className="inline-flex">
            <EditLocationSharp />
          </span>
        )}
        {props.labelName === "password" && (
          <span className="inline-flex">
            <LockSharp />
          </span>
        )}
        <input
          id={props.id}
          ref={inputRef}
          type={props.type}
          value={props.value}
          name={props.labelName}
          disabled={props.isDisabled}
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          onChange={(e) => props.handleInputChange(e)}
          className={`border-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1 caret-black`}
          autoComplete={props.autoComplete}
        />
      </div>
    </div>
  );
};

export default InputField;
