import React from "react";
import { ReactComponent as Spinner } from "./../../assets/spinner/spinner.svg";

interface Props {
  handleOnClick?: any;
  isWaiting?: boolean;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  title: string;
  ref?: any;
}

const Button = (props: Props) => {
  let btnStyle =
    "flex justify-center relative w-full p-2 rounded-md outline-none text-white text-center";

  if (props.hidden) {
    btnStyle = btnStyle + " hidden";
  }

  if (props.disabled) {
    btnStyle = btnStyle + " bg-gray-100 mt-3 cursor-not-allowed";
  } else {
    btnStyle = btnStyle + " bg-black mt-3 cursor-pointer";
  }

  if (props.className) {
    btnStyle = btnStyle + " " + props.className;
  }

  return (
    <button
      onClick={(e) => props.handleOnClick(e)}
      disabled={props.disabled}
      className={btnStyle}
      ref={props?.ref}
    >
      <span className="inline-flex">{props.title}</span>
      {props.isWaiting && (
        <Spinner
          style={{
            position: "absolute",
          }}
          className="inline-flex h-7 max-w-max"
        />
      )}
    </button>
  );
};

export default Button;
