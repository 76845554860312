import axios from "axios";

const getLocationAsync = async () =>
  await axios
    .get(
      "https://api.ipgeolocation.io/ipgeo?apiKey=6032512ed21b4cd88676a8ae5a93a4f8"
    )
    .then((response) => response.data)
    .catch((error) => console.log(error));

export default getLocationAsync;
