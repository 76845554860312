import { gql } from "@apollo/client";

export const LOGIN = gql`
  query login($phone: String!, $password: String!, $calling_code: String!) {
    login(
      loginInput: {
        phone: $phone
        password: $password
        calling_code: $calling_code
      }
    ) {
      user {
        _id
        phone
        avatar
        isSeller
        hasShop
        lastname
        firstname
        hasBeenPitched
        hasBeenPlanForAPitch
        hasRequestedToBeSeller
        hasBeenAcceptedAsSeller
      }
      access_token
    }
  }
`;

export const ME = gql`
  query me {
    me {
      _id
      phone
      email
      avatar
      isSeller
      hasShop
      lastname
      firstname
      hasBeenPitched
      hasBeenProcessed
      hasBeenPlanForAPitch
      hasRequestedToBeSeller
      hasBeenAcceptedAsSeller
    }
  }
`;

export const FINDUSER_BYPHONE = gql`
  query findUserByPhoneNumber($phone: String!, $calling_code: String!) {
    userByPhone(
      findUserByPhoneInput: { phone: $phone, calling_code: $calling_code }
    ) {
      _id
      phone
    }
  }
`;
