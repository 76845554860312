import toast from "react-hot-toast";
import React, { useState } from "react";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import { phoneValidity } from "../../utils/validation";
import Button from "../../components/form/button";
import InputField from "../../components/form/input";
import CheckBox from "../../components/form/checkbox";
import { formatPhone, removeSpace } from "../../utils/format";
import {
  dismissSignInModal,
  login as loggedIn,
} from "../../features/utils/utilsSlice";
import { useLazyQuery } from "@apollo/client";
import { SHOP } from "../../api/shop/queries";
import { LOGIN } from "../../api/users/queries";
import Helper from "../../components/form/helper";
import { setToken, setUser } from "../../features/users/usersSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setShop } from "../../features/shops/shopsSlice";

interface Props {}

function SignIn(props: Props) {
  const routes = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isPhoneValid: false,
    password: "",
    phone: "",
  });

  const location = useAppSelector((state: RootState) => state.utils.location);

  const [getShop] = useLazyQuery(SHOP, {
    onCompleted: (data) => {
      if (data) {
        dispatch(setShop(data?.shopByUser));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [login, { loading }] = useLazyQuery(LOGIN, {
    onCompleted: (data) => {
      if (data) {
        localStorage.setItem("access_token", data?.login?.access_token);
        dispatch(loggedIn());
        dispatch(setUser(data?.login.user));
        dispatch(setToken(data?.login?.access_token));
        const { from } = (routes.state as any) || { from: { pathname: "/" } };
        if (from !== "/") {
          navigate(from);
        }

        toast.success("Bienvenue sur Djéliba.");
        dispatch(dismissSignInModal());
        const { hasShop } = data?.login?.user;
        if (hasShop) {
          getShop({
            context: {
              headers: { authorization: `Bearer ${data?.login?.access_token}` },
            },
          });
        }
      } else {
        toast.error("Numero de téléphone ou mot de passe incorrect.");
      }
    },
    onError: (error) => {
      console.error(error);
    },
    variables: {
      password: state.password.trim(),
      phone: removeSpace(state.phone),
      calling_code: location?.calling_code,
    },
  });

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "phone":
        setState((prevState) => ({
          ...prevState,
          isPhoneValid: phoneValidity(location?.country_code2, e.target.value),
          [e.target.name]: formatPhone(location?.country_code2, e.target.value),
        }));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
        break;
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-md px-11 my-7">
        <div className="flex justify-center items-center my-2">
          <img src={logo} alt="" className="h-20 object-scale-down" />
        </div>
        <div>
          <InputField
            id="phone"
            type="tel"
            autoFocus={true}
            labelName="phone"
            value={state.phone}
            icon={location?.country_flag}
            calling_code={location?.calling_code}
            placeholder="Entrez votre numéro ici"
            handleInputChange={handleInputChange}
            isValid={
              state.phone.length === 0
                ? true
                : state.isPhoneValid
                ? true
                : false
            }
            info={location?.country_code2.toLocaleUpperCase()}
          />
          <InputField
            styles="pt-2"
            id="password"
            type="password"
            labelName="password"
            value={state.password}
            isValid={
              state.password.length === 0
                ? true
                : state.password.length < 6
                ? false
                : true
            }
            placeholder="Tapez votre mot de passe"
            handleInputChange={handleInputChange}
          />
          <CheckBox labelText="Se souvenir de moi" hidden={false} />
          <Button
            title="Connexion"
            isWaiting={loading}
            handleOnClick={login}
            disabled={
              state.phone.length === 0 ||
              !state.isPhoneValid ||
              state.password === "" ||
              state.password.length < 6
            }
          />
          <Helper view="sign-in" />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
