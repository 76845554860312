import React, { useRef, useState } from "react";
import Modal from "../common/modal";
import logo from "./../../assets/logo/logo.png";
import SearchFilter from "../ux/search-filter";
import { CategorySharp } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { searchFor } from "../../features/products/productsSlice";
import { RootState } from "../../app/store";

interface Props {
  logo?: any;
}

function Header(props: Props) {
  const [state, setState] = useState({
    isSearchFilterModalOpen: false,
    searchingFor: "",
  });

  const dispatch = useAppDispatch();
  const filterKeys = useAppSelector(
    (state: RootState) => state.products.filterKeys
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Leckerli One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Lato";
      }
    }
  };

  const toggleSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: !prevState.isSearchFilterModalOpen,
    }));
  };
  const dismissSearchFilterModal = () => {
    setState((prevState) => ({
      ...prevState,
      isSearchFilterModalOpen: false,
    }));
  };

  const handleSearchBar = (e) => {
    setState((prevState) => ({
      ...prevState,
      searchingFor: e.target.value,
    }));

    dispatch(searchFor(e.target.value));
  };

  return (
    <div>
      <Modal
        modalIsOpen={state.isSearchFilterModalOpen}
        className="w-4/5 md:w-3/12"
        handleModal={() => toggleSearchFilterModal()}
        dismissModal={() => dismissSearchFilterModal()}
        content={<SearchFilter />}
        hideDismissBtn={true}
      />
      <div
        className="header px-2 justify-between items-center my-5 max-w-full"
        style={{
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateRows: "auto",
        }}
      >
        <img
          alt=""
          src={props.logo ? props.logo : logo}
          className="h-10 object-scale-down"
        />
        <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
          <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
            <CategorySharp
              className={
                filterKeys?.length > 0
                  ? "text-green-400 hover:cursor-pointer bg-transparent border-0"
                  : "hover:cursor-pointer bg-transparent border-0"
              }
              onClick={() => toggleSearchFilterModal()}
            />
          </div>
          <input
            className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba"
            placeholder={"Que cherchez vous ?"}
            onBlur={() => handleOnBlur()}
            onFocus={() => handleOnFocus()}
            onChange={(e) => handleSearchBar(e)}
            type="text"
            value={state.searchingFor}
            name="searchingFor"
            ref={inputRef}
            style={{
              fontFamily: "Leckerli One",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
