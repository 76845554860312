import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";

interface Props {}

const Shops = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className="grid">
      {/* Banner hack */}
      <div
        className="banner"
        style={{
          gridColumn: "1 / -1",
          backgroundColor: "#a5a39f49",
        }}
      ></div>

      {/* Header */}
      <Header />
      <div className="flex justify-center w-full m-auto">
        <div className="flex flex-wrap py-5 justify-evenly w-full">
          <div
            onClick={() => navigate("/desktop/shops/1")}
            className="w-2/5 mb-7 rounded-md border-2 hover:border-0 hover:cursor-pointer flex flex-col justify-between hover:shadow-2xl transition-all ease-linear duration-300"
          >
            <div className="flex justify-center items-center border-0">
              <img
                src="https://otemcosmetics.com/img/my-shop-logo-1591169774.jpg"
                className="rounded-md w-full object-scale-down"
                alt="meta's logo"
              />
            </div>
            <div className="flex justify-center items-center border border-b-0 w-full">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                Otem cosmetics
              </span>
            </div>
          </div>

          <div
            onClick={() => navigate("/desktop/shops/1")}
            className="w-2/5 mb-7 rounded-md border-2 hover:border-0 hover:cursor-pointer flex flex-col justify-between hover:shadow-2xl transition-all ease-linear duration-300"
          >
            <div className="flex justify-center items-center border-0">
              <img
                src="https://otemcosmetics.com/img/my-shop-logo-1591169774.jpg"
                className="rounded-md w-full object-scale-down"
                alt="meta's logo"
              />
            </div>
            <div className="flex justify-center items-center border border-b-0 w-full">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                Otem cosmetics
              </span>
            </div>
          </div>

          <div
            onClick={() => navigate("/desktop/shops/1")}
            className="w-2/5 mb-7 rounded-md border-2 hover:border-0 hover:cursor-pointer flex flex-col justify-between hover:shadow-2xl transition-all ease-linear duration-300"
          >
            <div className="flex justify-center items-center border-0">
              <img
                src="https://otemcosmetics.com/img/my-shop-logo-1591169774.jpg"
                className="rounded-md w-full object-scale-down"
                alt="meta's logo"
              />
            </div>
            <div className="flex justify-center items-center border border-b-0 w-full">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                Otem cosmetics
              </span>
            </div>
          </div>

          <div
            onClick={() => navigate("/desktop/shops/1")}
            className="w-2/5 mb-7 rounded-md border-2 hover:border-0 hover:cursor-pointer flex flex-col justify-between hover:shadow-2xl transition-all ease-linear duration-300"
          >
            <div className="flex justify-center items-center border-0">
              <img
                src="https://otemcosmetics.com/img/my-shop-logo-1591169774.jpg"
                className="rounded-md w-full object-scale-down"
                alt="meta's logo"
              />
            </div>
            <div className="mt-6 text-xs text-grey-dark">
              Already have an account? Log in
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shops;
