import React from "react";
import CardList from "../card/cardlist";

interface Props {
  products?: object[];
}

function Content(props: Props) {
  return (
    <div className="content">
      <CardList products={props.products} />
    </div>
  );
}

export default Content;
