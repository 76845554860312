import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SHOP_ORDERS_METRICS } from "../../api/orders/queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const optionsGraphOne = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Rapport d'activités",
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 1,
        beginAtZero: true,
      },
    },
  },
};
export const optionsGraphTwo = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chiffre d'affaires",
    },
  },
};

const DATA_COUNT = 7;
const labels: string[] = [];
for (let i = 1; i <= DATA_COUNT; ++i) {
  labels.push(i.toString());
}

interface dataItem {
  sales: string;
  orders: string;
}

interface Props {}

const Dashbord = (props: Props) => {
  const shopId = useAppSelector((state: RootState) => state.shops.shop._id);

  const { data: graphData } = useQuery(SHOP_ORDERS_METRICS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !shopId,
    variables: {
      shopId,
    },
  });

  const dataGraphOne = {
    labels: graphData?.shopOrdersMetrics?.map(
      (item: dataItem) =>
        new Date(Object.keys(item).join(","))
          .toLocaleString("fr-FR", {
            weekday: "long",
          })
          .charAt(0)
          .toUpperCase() +
        new Date(Object.keys(item).join(","))
          .toLocaleString("fr-FR", {
            weekday: "long",
          })
          .slice(1)
    ),
    datasets: [
      {
        fill: false,
        tension: 0.4,
        pointRadius: 5,
        label: "Ventes",
        pointHoverRadius: 10,
        borderColor: "#00af54",
        pointStyle: "rectRounded",
        backgroundColor: "#7FDCAC",
        data: graphData?.shopOrdersMetrics.map(
          (item: dataItem) => Object.values(item)[0].sales
        ),
      },
      {
        fill: false,
        tension: 0.4,
        pointRadius: 5,
        label: "Commandes",
        pointStyle: "triangle",
        pointHoverRadius: 10,
        borderColor: "#fbaf00",
        backgroundColor: "#F8D175",
        data: graphData?.shopOrdersMetrics.map(
          (item: dataItem) => Object.values(item)[0].orders
        ),
      },
    ],
  };
  const dataGraphTwo = {
    labels: graphData?.shopOrdersMetrics?.map(
      (item: dataItem) =>
        new Date(Object.keys(item).join(","))
          .toLocaleString("fr-FR", {
            weekday: "long",
          })
          .charAt(0)
          .toUpperCase() +
        new Date(Object.keys(item).join(","))
          .toLocaleString("fr-FR", {
            weekday: "long",
          })
          .slice(1)
    ),
    datasets: [
      {
        fill: false,
        tension: 0.4,
        pointRadius: 5,
        label: "CA",
        pointHoverRadius: 10,
        borderColor: "#ff5252",
        pointStyle: "rectRounded",
        backgroundColor: "#ffbaba",
        data: graphData?.shopOrdersMetrics.map(
          (item: dataItem) => Object.values(item)[0].turnover
        ),
      },
    ],
  };

  return (
    <div className="m-auto flex flex-col w-full h-full">
      <div className="flex flex-col w-full items-center justify-evenly">
        <div className="flex min-w-max justify-between items-center rounded-md border p-1 my-3">
          <span>Chiffre d'affaires : </span>
          <div className="bg-black rounded-md ml-1">
            <span className="font-extrabold m-1 text-white">
              {graphData?.shopOrdersMetrics
                .map((item: dataItem) => Object.values(item)[0].turnover)
                .reduce(function (accumulator, currentValue) {
                  return accumulator + currentValue;
                }, 0)}{" "}
              FCFA
            </span>
          </div>
        </div>
      </div>
      <div className="flex md:w-10/12 m-2 md:m-5 mt-7 border rounded-sm">
        <Line options={optionsGraphOne} data={dataGraphOne} />
      </div>
      <div className="flex md:w-10/12 m-2 md:m-5 mt-7 border rounded-sm">
        <Line options={optionsGraphTwo} data={dataGraphTwo} />
      </div>
    </div>
  );
};

export default Dashbord;
