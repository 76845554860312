import Button from "../form/button";
import InputField from "../form/input";
import { BsImages } from "react-icons/bs";
import {
  MdOutlinePhotoSizeSelectActual,
  MdPhotoLibrary,
  MdPhotoSizeSelectLarge,
} from "react-icons/md";
import { RootState } from "../../app/store";
import logo from "./../../assets/logo/logo.png";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  dismissNewProductModal,
  setIsDeliveryFree,
  setProductCategories,
  setProductDescription,
  setProductName,
  setProductPrice,
  setProductQuantity,
  startWaiting,
  stopWaiting,
  uploadProductPhotos,
} from "../../features/products/productsSlice";
import {
  AddSharp,
  ChevronLeft,
  ChevronRight,
  ExpandMoreSharp,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import { IoTrashBin } from "react-icons/io5";
import { removeSpace } from "../../utils/format";
import { useLazyQuery, useMutation } from "@apollo/client";
import { setProducts } from "../../features/shops/shopsSlice";
import { CREATE_PRODUCT } from "../../api/product/mutations";
import { PRODUCTS_BY_SHOP } from "../../api/product/queries";
import Label from "./label";

interface Props {}

interface State {
  files: string[];
  renderImages: string[];
  photoModalVisible: boolean;
  resizeModalVisible: boolean;
}

let count = 0;

const NewProduct = (props: Props) => {
  const [state, setState] = useState<State | any>({
    files: [],
    currentIndex: 0,
    photoModalVisible: false,
    resizeModalVisible: false,
  });
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const photoModalRef = useRef<HTMLDivElement>(null);
  const resizeModalRef = useRef<HTMLDivElement>(null);
  const textareRef = useRef<HTMLTextAreaElement>(null);

  const shopId = useAppSelector((state: RootState) => state.shops.shop?._id);
  const product = useAppSelector((state: RootState) => state.products.product);
  const categories = useAppSelector(
    (state: RootState) => state.utils.categories
  );
  const isWaiting = useAppSelector(
    (state: RootState) => state.products.isWaiting
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        resizeModalRef.current &&
        !resizeModalRef.current.contains(event.target)
      ) {
        dismissResizeModal();
      }
      if (
        photoModalRef.current &&
        !photoModalRef.current.contains(event.target)
      ) {
        dismissPhotoModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resizeModalRef, photoModalRef]);

  const [getProducts] = useLazyQuery(PRODUCTS_BY_SHOP, {
    onCompleted: (data) => {
      if (data) {
        dispatch(setProducts(data?.productsByShop?.page?.edges));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onCompleted: async (res) => {
      if (res) {
        if (state?.files?.length !== 0) {
          let data = new FormData();

          state?.files.forEach((item) => {
            data.append("images", item?.file);
            data.append("ratios", item?.ratio);
          });

          await dispatch(uploadProductPhotos(res.createProduct._id, data));
          dispatch(dismissNewProductModal());
          toast.success("Votre produit a été ajouté avec success.");
          setTimeout(
            () =>
              getProducts({
                variables: {
                  shopId,
                },
              }),
            10000
          );
        } else {
          dispatch(stopWaiting());
          dispatch(dismissNewProductModal());
          toast.success("Votre produit a été ajouté avec success.");
        }
      } else {
        dispatch(stopWaiting());
      }
    },
    onError: (error) => {
      console.error(error);
      dispatch(stopWaiting());
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startWaiting());
    createProduct({
      variables: {
        shop: shopId,
        name: product.name,
        price: parseInt(removeSpace(product.price)),
        quantity: parseInt(removeSpace(product.quantity)),
        categories: product.categories,
        description: product.description,
        isDeliveryFree: product.isDeliveryFree,
      },
    });
  };

  const handleChevronLeft = () => {
    const size = state.files.length;
    count = (state.currentIndex + size - 1) % size;
    setState((prevState) => ({
      ...prevState,
      currentIndex: count,
    }));
  };

  const handleChevronRight = () => {
    count = (count + 1) % state.files.length;
    setState((prevState) => ({
      ...prevState,
      currentIndex: count,
    }));
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "name":
        dispatch(setProductName(e.target.value));
        break;
      case "price":
        dispatch(setProductPrice(e.target.value));
        break;
      case "description":
        dispatch(setProductDescription(e.target.value));
        break;
      case "quantity":
        dispatch(setProductQuantity(e.target.value));
        break;
      case "file":
        if (Array.from(e.target.files).length > 5) {
          e.preventDefault();
          toast(`Vous pouvez ajouter seulement 5 photos par produits`);
          return;
        }
        setState((prevState) => ({
          ...prevState,
          files: [
            ...state.files,
            ...[...e.target.files].map((file) => ({
              ratio: "1/1",
              file: file,
              id: file.lastModified,
              uri: URL.createObjectURL(file),
            })),
          ],
          currentIndex: prevState.files.length,
        }));
        break;
      default:
        break;
    }
  };

  const handleCheckboxInput = (e) => {
    dispatch(setProductCategories(e.target.value));
    console.log(e.target.value);
  };

  const showResizeModal = (e) => {
    setState((prevState) => ({
      ...prevState,
      resizeModalVisible: true,
    }));
  };

  const dismissResizeModal = () => {
    setState((prevState) => ({
      ...prevState,
      resizeModalVisible: false,
    }));
  };

  const showPhotoModal = () => {
    setState((prevState) => ({
      ...prevState,
      photoModalVisible: true,
    }));
  };

  const dismissPhotoModal = () => {
    setState((prevState) => ({
      ...prevState,
      photoModalVisible: false,
    }));
  };

  const resizePhoto = (ratio) => {
    const array = [...state.files];
    array.splice(state.currentIndex, 1, {
      ...state.files[state.currentIndex],
      ratio,
    });
    setState((prevState) => ({
      ...prevState,
      files: array,
    }));
  };

  const handleDeleteItem = (e, id) => {
    e.preventDefault();
    const array = [...state.files];
    array.splice(
      state.files.findIndex((element) => element.id === id),
      1
    );
    const size = array.length;
    count = (state.currentIndex + size - 1) % size;
    setState((prevState) => ({
      ...prevState,
      currentIndex: count,
      files: array,
    }));
  };

  return (
    <div className="flex flex-col justify-center items-center p-2 overflow-y-scroll scrollbar-thin scrollbar-black-thumb">
      <div className="flex justify-center items-center">
        <img src={logo} alt="" className="h-20 object-scale-down max-w-max" />
      </div>
      <div className="flex flex-col md:flex-row justify-evenly items-center w-full h-full border">
        <div className="relative flex flex-col justify-center items-center h-full w-full border-0 md:border p-2 m-1 md:mr-0 md:rounded-l-md">
          <div className="flex flex-col justify-center items-center h-80 w-full">
            {state?.files?.length === 0 ? (
              <>
                <BsImages
                  size="50"
                  className="hover:cursor-pointer"
                  onClick={() => inputRef.current?.click()}
                />
                <span
                  className="flex justify-center items-center rounded-md outline-none text-white text-center bg-black cursor-pointer px-2 max-w-max font-thin mt-3"
                  onClick={() => inputRef.current?.click()}
                >
                  Ajoutez les photos ici
                </span>
              </>
            ) : (
              <img
                className={
                  {
                    initial: "object-scale-down w-full h-full",
                    "1/1": "object-cover w-72 h-72",
                    "4/5": "object-cover w-56 h-full",
                    "16/9": "object-cover w-72 h-40",
                  }[state?.files[state.currentIndex]?.ratio]
                }
                src={state?.files[state.currentIndex]?.uri}
                alt=""
              />
            )}
            <input
              multiple
              max={5}
              type="file"
              name="file"
              ref={inputRef}
              className="hidden"
              accept=".jpg, .jpeg, .png"
              onChange={handleInputChange}
            />
          </div>
          <div
            className={`absolute w-full top-1/2 transform -translate-y-1/2 px-3 justify-between items-center ${
              state.files.length >= 2 ? "flex" : "hidden"
            }`}
          >
            <ChevronLeft
              onClick={() => handleChevronLeft()}
              className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
            />
            <ChevronRight
              onClick={() => handleChevronRight()}
              className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
            />
          </div>
          <div
            className={
              state.files.length !== 0
                ? "flex justify-center items-center absolute bottom-1 left-1 hover:cursor-pointer"
                : "hidden"
            }
            onClick={showResizeModal}
          >
            <MdPhotoSizeSelectLarge className="bg-white rounded hover:cursor-pointer" />
          </div>
          <div
            className={
              state.resizeModalVisible
                ? "flex flex-col justify-center absolute bottom-1 left-1 bg-black p-2 rounded-md opacity-80"
                : "hidden"
            }
            ref={resizeModalRef}
          >
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state?.files[state.currentIndex]?.ratio === "initial"
                  ? "bg-djeliba  rounded-md px-1"
                  : ""
              }`}
              onClick={() => resizePhoto("initial")}
            >
              <span className="text-white px-1">Initial</span>
              <MdOutlinePhotoSizeSelectActual className="text-white" />
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state?.files[state.currentIndex]?.ratio === "1/1"
                  ? "bg-djeliba  rounded-md px-1"
                  : ""
              }`}
              onClick={() => resizePhoto("1/1")}
            >
              <span className="text-white px-1">1/1</span>
              <div className="flex justify-between items-center rounded-sm w-4 h-4 border"></div>
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state?.files[state.currentIndex]?.ratio === "4/5"
                  ? "bg-djeliba  rounded-md px-1"
                  : ""
              }`}
              onClick={() => resizePhoto("4/5")}
            >
              <span className="text-white px-1">4/5</span>
              <div className="flex justify-between items-center rounded-sm w-4 h-5 border"></div>
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state?.files[state.currentIndex]?.ratio === "16/9"
                  ? "bg-djeliba  rounded-md px-1"
                  : ""
              }`}
              onClick={() => resizePhoto("16/9")}
            >
              <span className="text-white px-1">16/9</span>
              <div className="flex justify-between items-center rounded-sm w-6 h-4 border"></div>
            </div>
          </div>
          <div
            className={
              state.photoModalVisible
                ? "flex justify-center items-center absolute bottom-1 right-1 bg-black p-2 rounded-md opacity-90 z-50"
                : "hidden"
            }
            ref={photoModalRef}
          >
            {state?.files?.map((item, i) => (
              <div
                className={`flex justify-between items-center p-1 relative`}
                key={i}
              >
                <img
                  src={item?.uri}
                  className="object-cover rounded-md w-11 h-11"
                  alt=""
                />
                <span
                  onClick={(e) => handleDeleteItem(e, item?.id)}
                  className="flex justify-center items-center absolute top-1 right-2 text-sm text-red-500 rounded h-3 hover:cursor-pointer"
                >
                  x
                </span>
              </div>
            ))}
            <div
              className={`flex justify-between items-center p-1 ${
                state.files.length >= 5 ? "hidden" : ""
              } `}
              onClick={() => inputRef.current?.click()}
            >
              <AddSharp
                className="bg-white rounded hover:cursor-pointer"
                fontSize="small"
              />
            </div>
          </div>
          <div
            className={
              state.files.length !== 0
                ? "flex justify-center items-center absolute bottom-1 right-7 hover:cursor-pointer"
                : "hidden"
            }
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                files: [],
              }));
            }}
          >
            <IoTrashBin className="bg-white rounded hover:cursor-pointer" />
          </div>
          <div
            className={
              state.files.length !== 0
                ? "flex justify-center items-center absolute bottom-1 right-1 hover:cursor-pointer"
                : "hidden"
            }
            onClick={showPhotoModal}
          >
            <MdPhotoLibrary className="bg-white rounded hover:cursor-pointer" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full w-full border-0 md:border border-l-0 p-2 m-1 ml-0 rounded-r-md">
          <div className="flex flex-col h-full md:h-80 w-full overflow-y-scroll scrollbar-thin scrollbar-black-thumb">
            <InputField
              id="name"
              type="text"
              autoFocus={true}
              labelName="name"
              value={product.name}
              isValid={product.isNameValid}
              placeholder="Entrez le nom du produit"
              handleInputChange={handleInputChange}
            />
            <InputField
              id="price"
              type="text"
              labelName="price"
              value={product.price}
              isValid={product.isPriceValid}
              placeholder="Entrez le prix du produit"
              handleInputChange={handleInputChange}
            />
            <div
              style={{
                borderColor:
                  document.activeElement === textareRef.current
                    ? product.isDescriptionValid
                      ? "rgba(243, 244, 246, var(--tw-border-opacity))"
                      : "red"
                    : "rgba(243, 244, 246, var(--tw-border-opacity))",
              }}
              className="flex justify-between items-center w-full border-2 border-gray-100 rounded-md p-2 mb-2"
            >
              <textarea
                id="description"
                ref={textareRef}
                name="description"
                maxLength={500}
                value={product.description}
                onChange={handleInputChange}
                placeholder="Entrez la description du produit"
                className={`border-none w-full outline-none resize-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
              />
            </div>
            <InputField
              type="text"
              id="quantity"
              labelName="quantity"
              value={product.quantity}
              isValid={product.isQuantityValid}
              handleInputChange={handleInputChange}
              placeholder="Entrez la quantité disponible en stock"
            />
            <div className="flex items-start h-28">
              <div className="flex relative justify-start w-full items-center border-2 border-gray-100 rounded-md p-2 mb-2 group">
                <div
                  className={
                    product.categories.length === 0
                      ? "flex justify-between items-center w-full border-none outline-none text-sm p-1 rounded-md"
                      : "inline-block max-w-fit border-none outline-none text-sm p-1 rounded-md"
                  }
                >
                  {product.categories.length === 0 && (
                    <div className="w-full flex justify-between items-center">
                      <span className="text-gray-400">Catégories </span>
                      <ExpandMoreSharp className="text-gray-400" />
                    </div>
                  )}
                  {product?.categories?.map((item, i) => (
                    <div
                      key={i}
                      className="max-w-min inline-block border justify-between items-center m-1 rounded-md p-1"
                    >
                      <span className="capitalize text-xs">
                        {
                          categories?.find((el) => el?.node._id === item)?.node
                            ?.name
                        }
                      </span>
                    </div>
                  ))}
                </div>
                <div className="absolute z-50 right-0 top-11 max-w-max hidden group-hover:flex rounded-md p-2 bg-black flex-col hover:h-32 overflow-y-scroll scrollbar-thin scrollbar-white-thump">
                  {categories?.map((item, i) => (
                    <label
                      className="text-black my-0.5 mx-2 bg-white rounded-md p-2 text-xs flex justify-start items-center hover:cursor-pointer"
                      htmlFor={item?.node?.name}
                      key={i}
                    >
                      <input
                        id={item?.node?.name}
                        type="checkbox"
                        className="hover:cursor-pointer"
                        value={item?.node?._id}
                        onChange={(e) => handleCheckboxInput(e)}
                        checked={product.categories.includes(item?.node?._id)}
                      />
                      <span className="ml-1 capitalize">
                        {item?.node?.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="m-2">
              <Label
                isRequired={true}
                style="text-black font-thin text-sm font-bold"
                labelName="delivery"
                labelText="Offrir la livraison... ?"
              />
              <div
                className={
                  product.isDeliveryFree === true
                    ? "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md bg-gray-00"
                    : "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md"
                }
                onClick={() => {
                  dispatch(setIsDeliveryFree(true));
                }}
              >
                <input
                  type="radio"
                  name="delivery"
                  onChange={() => dispatch(setIsDeliveryFree(true))}
                  checked={product.isDeliveryFree === true}
                />{" "}
                <span className="px-2">Oui</span>
              </div>
              <div
                className={
                  product.isDeliveryFree === false
                    ? "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md bg-gray-200"
                    : "flex items-center justify-center cursor-pointer max-w-max p-1 rounded-md"
                }
                onClick={() => {
                  dispatch(setIsDeliveryFree(false));
                }}
              >
                <input
                  type="radio"
                  name="delivery"
                  onChange={() => dispatch(setIsDeliveryFree(false))}
                  checked={product.isDeliveryFree === false}
                />
                <span className="px-2">Non</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start w-full">
        <Button
          title="Ajouter"
          handleOnClick={handleSubmit}
          className="px-3 max-w-max"
          disabled={
            !product.isPriceValid ||
            !product.isNameValid ||
            !product.isQuantityValid ||
            !product.isDescriptionValid ||
            product.categories.length === 0 ||
            isWaiting
          }
          isWaiting={isWaiting}
        />
      </div>
    </div>
  );
};

export default NewProduct;
