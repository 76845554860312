import { classNames } from "../../utils/utils";
import icon from "../../assets/logo/icon.png";
import { useWindowDimensions } from "./../../utils/utils";
import Footer from "../../components/layout/footer";
import Banner from "../../components/layout/banner";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Favorite,
  GirlSharp,
  StarSharp,
  DoneSharp,
  StoreSharp,
  CategorySharp,
  EmojiPeopleSharp,
  ChildFriendlySharp,
  ShoppingCartSharp,
  DevicesOtherSharp,
  FamilyRestroomSharp,
  DeliveryDiningSharp,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { RootState } from "../../app/store";
import { formatMoney } from "../../utils/format";
import { addToCart, removeFromCart } from "../../features/cart/cartSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../features/wishlist/wishlistSlice";
import Button from "../../components/form/button";
import Modal from "../../components/common/modal";
import Transaction from "../../components/ux/transaction";
import Validation from "../../components/ux/order-validation";
import {
  dismissValidationModal,
  toggleTransactionModal,
  dismissTransactionModal,
} from "../../features/payments/paymentsSlice";

interface Props {}

let count = 0;
let slideInterval;

function Product(props: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const product = location.state as any;
  const { width } = useWindowDimensions();
  const [state, setState] = useState({
    liked: false,
    added: false,
    loaded: false,
    quantity: 1,
    lineClamp: false,
    showMoreOrLess: "Voir Plus",
  });

  // useEffect(() => {
  //   const url = location.pathname.replace(/^\/+|\/+$/g, "");
  // }, [location.pathname]);

  const inputRef = useRef<HTMLInputElement>(null);
  const cartItems = useAppSelector((state: RootState) => state.cart.cartItems);
  const wishlistItems = useAppSelector(
    (state: RootState) => state.wishlist?.wishlistItems
  );

  const isValidationModalOpen = useAppSelector(
    (state: RootState) => state.payments.isValidationModalOpen
  );

  const isTransactionModalOpen = useAppSelector(
    (state: RootState) => state.payments.isTransactionModalOpen
  );

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });
    if (
      wishlistItems?.some(function (el) {
        return el?._id === product?._id;
      })
    ) {
      setState((prevState) => ({
        ...prevState,
        liked: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        liked: false,
      }));
    }
    if (
      cartItems?.some(function (el) {
        return el?._id === product?._id;
      })
    ) {
      setState((prevState) => ({
        ...prevState,
        added: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        added: false,
      }));
    }
  }, [wishlistItems, cartItems, product?._id]);

  const handleOnBlur = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Leckerli One";
      }
    }
  };

  const handleOnFocus = () => {
    if (inputRef.current) {
      if (inputRef.current.value === "") {
        inputRef.current.style.fontFamily = "Lato";
      }
    }
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    const { _id, shop, price, name, images, description, isDeliveryFree } = product;
    const cartItem = {
      _id,
      uri: images[0]?.lg,
      name,
      shop: {
        _id: shop?._id,
        name: shop?.name,
        logo: shop?.logo,
      },
      price,
      images,
      description,
      isDeliveryFree,
      quantity: 1,
    };
    dispatch(addToCart(cartItem));
    setState((prevState) => ({
      ...prevState,
      added: !prevState.added,
    }));
  };

  const handleRemoveFromCart = (e) => {
    e.preventDefault();
    dispatch(removeFromCart(product?._id));
    setState((prevState) => ({
      ...prevState,
      added: !prevState.added,
    }));
  };

  const handleToggleWish = (e) => {
    e.preventDefault();
    if (state.liked) {
      dispatch(removeFromWishlist(product?._id));
    } else {
      const { _id, shop, price, name, images, description, isDeliveryFree } = product;
      const wishlistItem = {
        _id,
        uri: images[0]?.lg,
        name,
        shop: {
          _id: shop?._id,
          name: shop?.name,
          logo: shop?.logo,
        },
        price,
        images,
        description,
        isDeliveryFree,
        quantity: 1,
      };
      dispatch(addToWishlist(wishlistItem));
    }
  };

  const handleGoToShop = (e) => {
    e.preventDefault();
    navigate("/shops/" + product?.shop?._id, {
      state: {
        ...product?.shop,
      },
    });
  };

  const handleDecreaseQuantity = (e) => {
    setState((prevState) => ({
      ...prevState,
      quantity: prevState.quantity > 1 ? prevState.quantity - 1 : 1,
    }));
  };

  const handleIncreaseQuantity = (e) => {
    setState((prevState) => ({
      ...prevState,
      quantity: prevState.quantity + 1,
    }));
  };

  const handleSubmit = (e: any) => {
    dispatch(toggleTransactionModal());
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRef = useRef<HTMLDivElement>(null);

  const handleChevronLeft = () => {
    const size = product?.images.length;
    count = (currentIndex + size - 1) % size;
    setCurrentIndex(count);
  };

  const handleChevronRight = useCallback(() => {
    count = (count + 1) % product?.images.length;
    setCurrentIndex(count);
  }, [product?.images.length]);

  const startSlider = useCallback(() => {
    slideInterval = setInterval(() => {
      handleChevronRight();
    }, 100000);
  }, [handleChevronRight]);

  useEffect(() => {
    startSlider();
    if (slideRef.current) {
      slideRef.current.addEventListener("mouseenter", pauseSlider);
      slideRef.current.addEventListener("mouseleave", startSlider);
    }

    return () => {
      clearInterval(slideInterval);
    };
  }, [startSlider]);

  const pauseSlider = () => {
    clearInterval(slideInterval);
  };

  const toggleLineClamp = () => {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  };

  return (
    <>
      <Modal
        modalIsOpen={isTransactionModalOpen}
        className="w-4/5 md:w-3/12"
        dismissModal={() => dispatch(dismissTransactionModal())}
        handleModal={() => dispatch(toggleTransactionModal())}
        content={
          <Transaction
            handleDismiss={dismissTransactionModal}
            item={{ ...product, quantity: state.quantity }}
          />
        }
      />
      <Modal
        modalIsOpen={isValidationModalOpen}
        className="w-4/5 md:w-3/12"
        dismissModal={() => dispatch(dismissValidationModal())}
        content={
          <Validation
            handleDismiss={() => dispatch(dismissValidationModal())}
            item={{ ...product, quantity: state.quantity }}
          />
        }
      />
      <Banner />
      {/* The Grid */}
      <div
        className="mx-auto grid min-h-screen"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "2em 5em auto auto auto",
        }}
      >
        {/* Banner hack */}
        <div
          className="banner"
          style={{
            gridColumn: "1 / -1",
            backgroundColor: "#a5a39f49",
          }}
        ></div>

        {/* Header */}
        <div
          className="header px-2 justify-between items-center my-5 max-w-full"
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gridTemplateRows: "auto",
          }}
        >
          <div className="flex">
            <img
              src={product?.shop?.logo}
              alt=""
              className="h-10 object-scale-down rounded-md"
              onClick={handleGoToShop}
            />
            <div className="max-w-max justify-evenly items-end px-2 hidden md:flex">
              <StarSharp fontSize="small" className="text-yellow-500" />
              <StarSharp fontSize="small" className="text-yellow-500" />
              <StarSharp fontSize="small" className="text-yellow-500" />
              <StarSharp fontSize="small" className="text-slate-200" />
              <StarSharp fontSize="small" className="text-slate-200" />
            </div>
          </div>
          <div className="relative border rounded-md flex flex-row justify-between items-center ml-2">
            <div className="relative transition-all ease-linear duration-300 group min-h-max border border-white">
              <CategorySharp className="hover:cursor-pointer bg-transparent border-0" />
              <div className="absolute left-1 invisible group-hover:visible rounded-md px-1 bg-white text-black z-10 border border-djeliba">
                <ul className="flex flex-col justify-between min-w-max my-2 px-2 rounded-md ">
                  <li className="m-0.5">
                    <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                      <FamilyRestroomSharp fontSize="large" />
                      <span className="mx-1">Toutes catégories</span>
                    </div>
                  </li>
                  <li className="m-0.5 group">
                    <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                      <GirlSharp fontSize="large" />
                      <span className="mx-1">Femmes</span>
                    </div>
                  </li>
                  <li className="m-0.5">
                    <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                      <ChildFriendlySharp fontSize="large" />
                      <span className="mx-1">Enfants</span>
                    </div>
                  </li>
                  <li className="m-0.5">
                    <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                      <EmojiPeopleSharp fontSize="large" />
                      <span className="mx-1">Hommes</span>
                    </div>
                  </li>
                  <li className="m-0.5">
                    <div className="hover:cursor-pointer flex items-center hover:bg-djeliba p-1 rounded-md">
                      <DevicesOtherSharp fontSize="large" />
                      <span className="mx-1">Divers</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <input
              className="h-8 max-h-8 md:w-80 outline-none text-center border-0 bg-djeliba"
              placeholder={"Que cherchez vous ?"}
              onBlur={() => handleOnBlur()}
              onFocus={() => handleOnFocus()}
              ref={inputRef}
              style={{
                fontFamily: "Leckerli One",
              }}
            />
          </div>
        </div>

        {/* Content */}
        <div
          className=""
          style={{
            gridColumn: "1 / -1",
          }}
        >
          <div className="flex flex-col md:flex-row justify-around items-start">
            <div className="w-full md:w-1/3 border relative">
              <div ref={slideRef} className="w-full select-none relative">
                <div className="w-full flex-row flex justify-evenly items-center">
                  <figure className="md:h-full grow md:m-2.5">
                    <img
                      onLoad={() => {
                        setState((prevState) => ({
                          ...prevState,
                          loaded: true,
                        }));
                      }}
                      src={product?.images[currentIndex]?.lg}
                      alt={product?.name}
                      loading="lazy"
                      style={{
                        width: "100%",
                        maxHeight: "80vh",
                        minWidth: "0",
                        objectFit: "cover",
                      }}
                      className={
                        state.loaded
                          ? "rounded-none"
                          : "rounded-none bg-gray-100 "
                      }
                    />
                  </figure>
                </div>
                {product.images.length > 1 && (
                  <div className="absolute w-full top-1/2 transform -translate-y-1/2 px-3 flex justify-between items-center">
                    <ChevronLeft
                      onClick={() => handleChevronLeft()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
                    />
                    <ChevronRight
                      onClick={() => handleChevronRight()}
                      className="border border-black opacity-50 bg-white rounded hover:cursor-pointer"
                    />
                  </div>
                )}
              </div>
              <div className="w-full px-1 flex justify-between items-center">
                <div>
                  {product?.isDeliveryFree && (
                    <DeliveryDiningSharp
                      onClick={handleAddToCart}
                      fontSize={width > 768 ? "small" : "medium"}
                      className="text-green-500 top-0 right-1 m-1 p-0.5 border bg-white rounded absolute"
                    />
                  )}
                  {state.loaded && (
                    <Favorite
                      onClick={handleToggleWish}
                      className={
                        state.liked
                          ? classNames(
                              "m-1 border-2 border-black bg-white rounded hover:cursor-pointer text-red-500"
                            )
                          : classNames(
                              "m-1 border-2 border-black bg-white rounded hover:cursor-pointer"
                            )
                      }
                    />
                  )}
                  {state.loaded &&
                    (!state.added ? (
                      <ShoppingCartSharp
                        onClick={handleAddToCart}
                        className="m-1 border-2 border-black bg-white rounded hover:cursor-pointer"
                      />
                    ) : (
                      <DoneSharp
                        onClick={handleRemoveFromCart}
                        className="m-1 border-2 border-black bg-white rounded hover:cursor-pointer text-green-400"
                      />
                    ))}
                </div>
                {product.images.length > 1 && (
                  <div className="flex justify-center items-center">
                    {product?.images.map((item, i) => (
                      <div
                        key={i}
                        className={
                          currentIndex === i
                            ? "bg-black h-1.5 w-1.5 rounded-full ml-1"
                            : "bg-slate-400 h-1 w-1.5 rounded-sm ml-1"
                        }
                      ></div>
                    ))}
                  </div>
                )}
                <div>
                  <StoreSharp
                    onClick={handleGoToShop}
                    className="m-1 border-2 border-black bg-white rounded hover:cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="w-full mt-2 md:mt-0 md:w-1/2 h-full md:border hover:border-0">
              <div className="flex justify-around md:justify-between items-center">
                <div>
                  <div className="m-2">
                    <span className="font-bold text-lg">{product?.name}</span>
                    <span className="mx-2 font-thin text-xs">
                      {product?.isDeliveryFree ? "livraision gratuite" : ""}
                    </span>
                  </div>
                  <div className="m-2">
                    <span className="font-bold cursor-pointer text-yellow-500">
                      {product?.shop?.name}
                    </span>
                  </div>
                  <div className="m-2 my-3">
                    <span className="font-thin">
                      {formatMoney.format(product?.price)}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="">
                    <span
                      onClick={handleDecreaseQuantity}
                      className="font-bold rounded-md px-2 mx-1 hover:cursor-pointer border bg-djeliba text-xl"
                    >
                      -
                    </span>
                    <span className="font-bold rounded-sm px-1 mx-1 bg-black text-white text-lg">
                      {state.quantity}
                    </span>
                    <span
                      onClick={handleIncreaseQuantity}
                      className="font-bold rounded-md px-2 mx-1 hover:cursor-pointer border bg-djeliba text-xl"
                    >
                      +
                    </span>
                  </div>
                  <div className="px-2">
                    <Button
                      title="Commander"
                      isWaiting={false}
                      handleOnClick={(e) => handleSubmit(e)}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
              <div className="mx-2">
                {!state.lineClamp ? (
                  <p
                    className="border-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      overflow: "hidden",
                    }}
                    onClick={toggleLineClamp}
                  >
                    {product?.description}
                  </p>
                ) : (
                  <textarea
                    disabled={true}
                    id="description"
                    name="description"
                    rows={7}
                    value={product.description}
                    className={`border-none resize-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
                    onClick={toggleLineClamp}
                  />
                )}

                <span
                  className="text-sm font-thin text-gray-400 cursor-pointer"
                  onClick={toggleLineClamp}
                >
                  {state.showMoreOrLess}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Recommendation */}
        <div
          className="p-0 my-2"
          style={{
            gridColumn: "1 / -1",
          }}
        >
          {/* <>
            <div className="flex justify-start md:justify-center items-center m-2 p-1">
              <span className="font-bold text-xl">
                D'autres produits de{" "}
                <span className="text-yellow-500">{product?.shop?.name}</span>
              </span>
            </div>
            <Slider
              images={data?.productsByShop.page.edges.map(
                (item) => item?.node?.images[0]?.lg
              )}
            />
          </> */}
        </div>

        {/* Footer */}
        <Footer />
        <div
          onClick={() => {
            navigate("/");
          }}
          className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none"
        >
          <img src={icon} alt="" className="object-scale-down rounded-full" />
        </div>
      </div>
    </>
  );
}

export default Product;
