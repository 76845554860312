import SidebarItem from "./sidebarItem";
import { RootState } from "../../app/store";
import { GoChecklist } from "react-icons/go";
import { AiFillSetting } from "react-icons/ai";
import { MdDeliveryDining, MdInventory2 } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import React, { useEffect, useState } from "react";
import { DashboardSharp } from "@mui/icons-material";
// import { MdCampaign, MdInventory2 } from "react-icons/md";

const Sidebar = (props: any) => {
  const [state, setState] = useState({
    activeLink: "/",
  });
  const location = useLocation();
  const shopId = useAppSelector((state: RootState) => state.shops.shop._id);

  useEffect(() => {
    setState((prevState) => ({
      activeLink: location.pathname.replace(/^\/+|\/+$/g, ""),
    }));
  }, [location, state.activeLink]);

  return (
    <div className="md:h-screen sticky">
      <div>
        <ul className="bg-white md:bg-transparent flex md:flex-col fixed md:relative bottom-0 justify-around md:justify-between items-center md:items-stretch w-full h-16 border-t md:border-none">
          <>
            <SidebarItem
              active={state.activeLink === `desktop/${shopId}`}
              link={`/desktop/${shopId}`}
              title="Dashbord"
              icon={DashboardSharp}
            />
            <SidebarItem
              active={state.activeLink === `desktop/${shopId}/products`}
              link={`/desktop/${shopId}/products`}
              title="Mes Produits"
              icon={MdInventory2}
            />
            <SidebarItem
              active={state.activeLink === `desktop/${shopId}/deliveries`}
              link={`/desktop/${shopId}/deliveries`}
              title="Mes livraisons"
              icon={MdDeliveryDining}
            />
            <SidebarItem
              active={state.activeLink === `desktop/${shopId}/transactions`}
              link={`/desktop/${shopId}/transactions`}
              title="Mes transactions"
              icon={GoChecklist}
            />
            {/* <SidebarItem
              active={state.activeLink === `desktop/${shopId}/campaigns`}
              link={`/desktop/${shopId}/campaigns`}
              title="Mes campagnes"
              icon={MdCampaign}
            /> */}
            <SidebarItem
              active={state.activeLink === `desktop/${shopId}/settings`}
              link={`/desktop/${shopId}/settings`}
              title="Mes Paramètres"
              icon={AiFillSetting}
            />
          </>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
