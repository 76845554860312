import React from "react";
import ShareIcon from "../icon/ShareIcon";
import logo from "./../../assets/logo/logo.png";
import HomeScreenIcon from "../icon/HomeScreenIcon";

interface Props {}

const Install = (props: Props) => {
  const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-md px-3 my-7">
        <div className="flex justify-center items-center my-2">
          <img src={logo} alt="" className="h-20 object-scale-down" />
        </div>
        <div className="flex-col justify-center items-center">
          <div className="flex justify-start items-center">
            <span className="inline-flex my-1 items-center">
              1°)&nbsp;Appuyez sur &nbsp;
              <ShareIcon className="h-7 text-blue-400" modern={isiOS13AndUp} />
            </span>
          </div>
          <div className="flex justify-start items-center">
            <span className="inline-flex my-1 items-center">
              2°)&nbsp;Ensuite sur &nbsp;
              <HomeScreenIcon className="h-7" modern={isiOS13AndUp} />
            </span>
          </div>
          <div className="flex justify-center items-center">
            <span className="inline-flex mt-2 text-xs">
              Étapes à suivre pour ajouter l'application.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Install;
