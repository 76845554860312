import Button from "../form/button";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { RootState } from "../../app/store";
import { classNames } from "../../utils/utils";
import { RiHandCoinFill } from "react-icons/ri";
import wave from "./../../assets/icon/wave.png";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "../../utils/format";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  dismissTransactionModal,
  setPaymentMode,
  toggleValidationModal,
} from "../../features/payments/paymentsSlice";
import { ReactComponent as OrangeMoney } from "./../../assets/icon/orange_money.svg";
import { useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../../api/orders/mutations";
import MobileDetect from "mobile-detect";
import { resetCart } from "../../features/cart/cartSlice";

interface Props {
  handleDismiss: any;
  item?: any;
}

const Transaction = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const total = useAppSelector((state: RootState) => state.cart.total);
  const isDeliveryFree = useAppSelector(
    (state: RootState) => state.cart.isDeliveryFree
  );
  const deliveryPrice = props?.item?.isDeliveryFree
    ? 0
    : isDeliveryFree
    ? 0
    : 1500;
  const [state, setState] = useState({
    amount: props.item
      ? props?.item?.price * props?.item?.quantity + deliveryPrice
      : total + deliveryPrice,
    isAmountValid: false,
  });
  const paymentMode = useAppSelector(
    (state: RootState) => state.payments.payment.paymentMode
  );
  let cartItems = useAppSelector((state: RootState) => state.cart.cartItems);
  if (props.item) {
    cartItems = [props.item];
  }
  const logged = useAppSelector((state: RootState) => state.utils.logged);
  const phone = useAppSelector((state: RootState) => state.users.user.phone);
  const calling_code = useAppSelector(
    (state: RootState) => state.utils?.location?.calling_code
  );

  const [submitOrder] = useMutation(CREATE_ORDER, {
    onCompleted: (data) => {
      if (data) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        dispatch(props.handleDismiss());
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleSubmit = (e: any) => {
    switch (paymentMode) {
      case "CASH":
        if (logged) {
          submitOrder({
            variables: {
              phone,
              calling_code,
              products: cartItems?.map((item, i) => ({
                product: item._id,
                quantity: item.quantity || 1,
                shop: item.shop._id,
              })),
            },
          });
          navigate("/products/orders");
          dispatch(resetCart());
          dispatch(props.handleDismiss());
          toast.success("Votre commande à été prise en compte");
          toast.success("Veuillez attendre l'appel du livreur");
        } else {
          dispatch(dismissTransactionModal());
          dispatch(toggleValidationModal());
        }
        break;
      case "OM":
        dispatch(dismissTransactionModal());
        dispatch(toggleValidationModal());
        break;
      case "WAVE":
        dispatch(dismissTransactionModal());
        dispatch(toggleValidationModal());
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="bg-gray-50 flex justify-center items-center flex-col py-7 ">
        <div className="flex justify-center items-center bg-white w-full">
          <span className="font-bold text-pretty">
            {paymentMode === "CASH" ? "Paiement à la livraison." : ""}
          </span>
        </div>
        <div className="flex justify-between md:m-3 m-1">
          <span
            onClick={() => {
              dispatch(setPaymentMode("OM"));
            }}
            className={
              paymentMode === "OM"
                ? classNames(
                    "bg-gray-100 pointer-events-none m-1 p-1 rounded-md border-2 cursor-pointer ring-4 ring-djeliba  hover:shadow-3xl transition-all ease-linear duration-300"
                  )
                : classNames(
                    "bg-gray-100 pointer-events-none m-1 p-1 rounded-md border cursor-pointer"
                  )
            }
          >
            <OrangeMoney className="max-w-min h-12" />
          </span>
          <span
            onClick={() => {
              dispatch(setPaymentMode("WAVE"));
            }}
            className={
              paymentMode === "WAVE"
                ? classNames(
                    "bg-gray-100 pointer-events-none m-1 p-1 rounded-md border-2 cursor-pointer ring-4 ring-djeliba  hover:shadow-3xl transition-all ease-linear duration-300"
                  )
                : classNames(
                    "bg-gray-100 pointer-events-none m-1 p-1 rounded-md border cursor-pointer"
                  )
            }
          >
            <img src={wave} alt="" className="h-12 object-scale-down" />
          </span>
          <span
            onClick={() => {
              dispatch(setPaymentMode("CASH"));
            }}
            className={
              paymentMode === "CASH"
                ? classNames(
                    "border-2 cursor-pointer m-1 p-1 rounded-md ring-4 ring-djeliba  hover:shadow-3xl transition-all ease-linear duration-300"
                  )
                : classNames("border cursor-pointer m-1 p-1 rounded-md")
            }
          >
            <RiHandCoinFill className="rounded-md h-12 w-20" />
          </span>
        </div>
        <div className="flex justify-center items-center flex-col">
          <input
            type="text"
            readOnly={true}
            value={formatMoney.format(state.amount)}
            className="border w-9/12 outline-none text-sm px-2 py-2 my-5 rounded-md text-center"
          />
        </div>
        <div className="w-1/2">
          <Button title="Valider" handleOnClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default Transaction;
