import axios from "axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductState {
  isWaiting: boolean;
  error: any;
  products: any[];
  filteredProducts: object[];
  filterKeys: string[];
  pageInfo: {
    startCursor: string;
    endCursor: string;
  };
  product: {
    shop: string;
    price: string;
    name: string;
    quantity: string;
    description: string;
    categories: any[];
    isPriceValid: boolean;
    isDeliveryFree: boolean;
    isNameValid: boolean;
    currentIndex: number;
    isQuantityValid: boolean;
    isCategoriesValid: boolean;
    isDescriptionValid: boolean;
    isEditProductModalOpen: boolean;
    isNewProductModalOpen: boolean;
    isHideProductModalOpen: boolean;
    isDeleteProductModalOpen: boolean;
  };
  activeProduct: {
    _id: string;
    shop: string;
    price: string;
    name: string;
    quantity: string;
    description: string;
    isDeliveryFree: boolean;
    categories: any[];
  };
}

const initialState: ProductState = {
  isWaiting: false,
  error: null,
  products: [],
  filteredProducts: [],
  filterKeys: [],
  pageInfo: {
    startCursor: "",
    endCursor: "",
  },
  product: {
    name: "",
    price: "",
    shop: "",
    quantity: "",
    categories: [],
    description: "",
    currentIndex: 0,
    isPriceValid: false,
    isNameValid: false,
    isDeliveryFree: false,
    isQuantityValid: false,
    isCategoriesValid: false,
    isDescriptionValid: false,
    isNewProductModalOpen: false,
    isEditProductModalOpen: false,
    isHideProductModalOpen: false,
    isDeleteProductModalOpen: false,
  },
  activeProduct: {
    _id: "",
    name: "",
    price: "",
    shop: "",
    quantity: "",
    isDeliveryFree: false,
    categories: [],
    description: "",
  },
};

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    stopWaiting: (state) => {
      state.isWaiting = false;
    },
    setCurrentIndex: (state, action: PayloadAction<number>) => {
      state.product.currentIndex = action.payload;
    },
    setFilterKeys: (state, action: PayloadAction<string>) => {
      if (
        state.filterKeys?.some(function (el) {
          return el === action.payload;
        })
      ) {
        state.filterKeys = state?.filterKeys?.filter(
          (el) => el !== action.payload
        );
      } else {
        if (typeof state.filterKeys === "undefined") {
          state.filterKeys = [action.payload];
        } else {
          state.filterKeys = [...state?.filterKeys, action.payload];
        }
      }
    },
    clearFilterKeys: (state) => {
      state.filterKeys = [];
    },
    setProducts: (state, action: PayloadAction<object[]>) => {
      state.products = action.payload;
      state.filteredProducts = action.payload;
    },
    setProductName: (state, action: PayloadAction<any>) => {
      state.product.name = action.payload;
      state.product.isNameValid = state.product.isNameValid =
        !/[!@#$%^&*()+=[\]{};:"\\|,.<>/?]+/.test(action.payload);
    },
    setProductPrice: (state, action: PayloadAction<any>) => {
      const price = parseInt(action.payload.replace(/[\D\s-]/g, ""), 10);
      state.product.price = new Intl.NumberFormat("fr-FR").format(price || 0);
      state.product.isPriceValid = price > 500 && price < 10000000;
    },
    setProductDescription: (state, action: PayloadAction<any>) => {
      state.product.description = action.payload;
      state.product.isDescriptionValid = action.payload?.trim().length <= 500;
    },
    setProductCategories: (state, action: PayloadAction<any>) => {
      state.product.categories = !state.product.categories.includes(
        action.payload
      )
        ? [...state.product.categories, action.payload]
        : state.product.categories.filter((item) => item !== action.payload);
    },
    setProductQuantity: (state, action: PayloadAction<any>) => {
      const quantity = parseInt(action.payload.replace(/[\D\s-]/g, ""), 10);
      state.product.quantity = new Intl.NumberFormat("fr-FR").format(
        quantity || 0
      );
      state.product.isQuantityValid = action.payload > 0;
    },
    setIsDeliveryFree: (state, action: PayloadAction<boolean>) => {
      state.product.isDeliveryFree = action.payload;
    },
    toggleNewProductModal: (state) => {
      state.product.isNewProductModalOpen =
        !state.product.isNewProductModalOpen;
      state.product.name = "";
      state.product.price = "";
      state.product.quantity = "";
      state.product.categories = [];
      state.product.description = "";
      state.product.currentIndex = 0;
      state.product.isPriceValid = false;
      state.product.isNameValid = false;
      state.product.isDeliveryFree = false;
      state.product.isQuantityValid = false;
      state.product.isCategoriesValid = false;
      state.product.isDescriptionValid = false;
    },
    dismissNewProductModal: (state) => {
      state.product.isNewProductModalOpen = false;
    },
    toggleEditProductModal: (state) => {
      state.product.isEditProductModalOpen =
        !state.product.isEditProductModalOpen;

      state.product.name = state.activeProduct?.name;
      state.product.price = state.activeProduct?.price;
      state.product.quantity = state.activeProduct?.quantity;
      state.product.isDeliveryFree = state.activeProduct?.isDeliveryFree;
      state.product.categories = state.activeProduct?.categories?.map(
        (item) => item?._id
      );
      state.product.description = state.activeProduct?.description;
      state.product.isPriceValid = true;
      state.product.isNameValid = true;
      state.product.isQuantityValid = true;
      state.product.isCategoriesValid = true;
      state.product.isDescriptionValid = true;
      state.isWaiting = false;
    },
    dismissEditProductModal: (state) => {
      state.product.isEditProductModalOpen = false;
    },
    toggleDeleteProductModal: (state) => {
      state.product.isDeleteProductModalOpen =
        !state.product.isDeleteProductModalOpen;
    },
    dismissDeleteProductModal: (state) => {
      state.product.isDeleteProductModalOpen = false;
    },
    toggleHideProductModal: (state) => {
      state.product.isHideProductModalOpen =
        !state.product.isHideProductModalOpen;
    },
    dismissHideProductModal: (state) => {
      state.product.isHideProductModalOpen = false;
    },
    setActiveProduct: (state, action: PayloadAction<any>) => {
      state.activeProduct = action.payload;
    },
    searchFor: (state, action: PayloadAction<any>) => {
      if (!(action?.payload?.length === 0)) {
        state.filteredProducts = state.products.filter((item) => {
          let title = item?.node?.name.toLowerCase().replace(/ /g, "");
          let text = action.payload.toLowerCase().replace(/ /g, "");
          return title.normalize().includes(text.normalize()) ? item : null;
        });
      } else {
        state.filteredProducts = state.products;
      }
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
    clearStorage() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
  extraReducers: (builder) => {},
});

export const {
  hasError,
  setProducts,
  stopWaiting,
  startWaiting,
  clearStorage,
  setProductPrice,
  setProductName,
  setCurrentIndex,
  setActiveProduct,
  setProductQuantity,
  setProductCategories,
  setFilterKeys,
  searchFor,
  clearFilterKeys,
  setIsDeliveryFree,
  setProductDescription,
  toggleNewProductModal,
  toggleEditProductModal,
  toggleHideProductModal,
  dismissEditProductModal,
  dismissNewProductModal,
  dismissHideProductModal,
  toggleDeleteProductModal,
  dismissDeleteProductModal,
} = productSlice.actions;

export default productSlice.reducer;

export const uploadProductPhotos = (id, data) => async (dispatch) => {
  try {
    await axios
      .put(`https://lol.djeliba.com/products/${id}/images`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((res) => {
        dispatch(stopWaiting());
      })
      .catch((err) => {
        console.error(err);
        dispatch(stopWaiting());
      });
  } catch (error) {
    return console.error(error);
  }
};
