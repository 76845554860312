import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CartItem {
  _id: string;
  uri: string;
  shop: object;
  name: string;
  price: number;
  quantity: number;
  description: string;
  isDeliveryFree: boolean;
}
interface CartState {
  isWaiting: boolean;
  isDeliveryFree: boolean;
  total: number;
  cartItems: CartItem[];
  error: any;
}

const initialState: CartState = {
  isWaiting: false,
  isDeliveryFree: false,
  cartItems: [],
  total: 0,
  error: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    startWaiting: (state) => {
      state.isWaiting = true;
    },
    setCart: (state, action: PayloadAction<CartItem[]>) => {
      action.payload.forEach((item) => {
        if (
          !state.cartItems?.some(function (el) {
            return el?._id === item?._id;
          })
        ) {
          state.cartItems = [item, ...state.cartItems];
          state.isDeliveryFree = state?.cartItems?.reduce(function (
            accumulator,
            currentValue
          ) {
            return accumulator || currentValue.isDeliveryFree;
          },
          false);
          state.total = state.cartItems.reduce(function (
            accumulator,
            currentValue
          ) {
            return accumulator + currentValue.price * currentValue.quantity;
          },
          0);
        }
      });
    },
    resetCart: (state) => {
      state.cartItems = [];
      state.total = 0;
    },
    addToCart: (state, action: PayloadAction<CartItem>) => {
      if (
        !state?.cartItems?.some(function (el) {
          return el?._id === action.payload?._id;
        })
      ) {
        if (state.cartItems?.length > 0) {
          state.cartItems = [action.payload, ...state.cartItems];
        } else {
          state.cartItems = [action.payload];
        }
        state.total = state?.cartItems?.reduce(function (
          accumulator,
          currentValue
        ) {
          return accumulator + currentValue.price * currentValue.quantity;
        },
        0);
        state.isDeliveryFree = state?.cartItems?.reduce(function (
          accumulator,
          currentValue
        ) {
          return accumulator || currentValue.isDeliveryFree;
        },
        false);
      }
    },
    removeFromCart: (state, action: PayloadAction<string>) => {
      state.cartItems = state.cartItems.filter(
        (item) => item._id !== action.payload
      );
      state.total = state.cartItems.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.price * currentValue.quantity;
      },
      0);
      state.isDeliveryFree = state?.cartItems?.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator || currentValue.isDeliveryFree;
      },
      false);
    },
    increaseQuantity: (state, action: PayloadAction<string>) => {
      state.cartItems = state.cartItems.map((item) =>
        item?._id === action.payload
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      state.total = state.cartItems.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.price * currentValue.quantity;
      },
      0);
    },
    decreaseQuantity: (state, action: PayloadAction<string>) => {
      state.cartItems = state.cartItems.map((item) =>
        item?._id === action.payload
          ? { ...item, quantity: item.quantity > 1 ? item.quantity - 1 : 1 }
          : item
      );
      state.total = state.cartItems.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.price * currentValue.quantity;
      },
      0);
    },
    setQuantity: (state, action: PayloadAction<string>) => {},
    hasError: (state, action) => {
      state.error = action.payload;
      state.isWaiting = false;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setCart,
  hasError,
  resetCart,
  addToCart,
  startWaiting,
  removeFromCart,
  increaseQuantity,
  decreaseQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
