import { useState } from "react";
import { RootState } from "../../app/store";
import { useAppSelector } from "../../app/hooks";
import Button from "../form/button";

interface Props {}

interface State {
  files: string[];
  renderImages: string[];
  photoModalVisible: boolean;
  resizeModalVisible: boolean;
}

const DeliveryWrapUp = (props: Props) => {
  const [state, setState] = useState<State | any>({});
  const toggleLineClamp = () => {
    setState((prevState) => ({
      ...prevState,
      lineClamp: !prevState.lineClamp,
      showMoreOrLess: prevState.lineClamp ? "Voir Plus" : "Voir Moins",
    }));
  };
  const delivery = useAppSelector(
    (state: RootState) => state.deliveries.delivery
  );

  return (
    <div className="flex flex-col justify-center items-center h-full w-full md:w-6/12 border-0 md:border p-2 m-1 md:mr-0 md:rounded-l-md">
      <div className="flex flex-col justify-start items-start h-80 w-full overflow-y-scroll scrollbar-thin">
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">A :</span>
          <span className="font-thin m-1 p-1">
            {delivery?.neighborhoodA?.length === undefined ||
            delivery?.neighborhoodA?.length === 0
              ? "xxxxxxxx"
              : delivery?.neighborhoodA}
          </span>
          <span className="font-thin m-1 p-1">/</span>
          <span className="font-thin m-1 p-1">
            {delivery?.nameA?.length === undefined ||
            delivery?.nameA?.length === 0
              ? "xxx"
              : delivery?.nameA}
          </span>
          <span className="font-thin m-1 p-1">-</span>
          <span className="font-thin m-1 p-1">
            {delivery?.phoneA?.length === undefined ||
            delivery?.phoneA?.length === 0
              ? "xxxxxxxx"
              : delivery?.phoneA}
          </span>
        </div>
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">B :</span>
          <span className="font-thin m-1 p-1">
            {delivery?.neighborhoodB?.length === undefined ||
            delivery?.neighborhoodB?.length === 0
              ? "xxxxxxxx"
              : delivery?.neighborhoodB}
          </span>
          <span className="font-thin m-1 p-1">/</span>
          <span className="font-thin m-1 p-1">
            {delivery?.nameB?.length === undefined ||
            delivery?.nameB?.length === 0
              ? "xxx"
              : delivery?.nameB}
          </span>
          <span className="font-thin m-1 p-1">-</span>
          <span className="font-thin m-1 p-1">
            {delivery?.phoneB?.length === undefined ||
            delivery?.phoneB?.length === 0
              ? "xxxxxxxx"
              : delivery?.phoneB}
          </span>
        </div>
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">Commande :</span>
          <span className="font-thin m-1 p-1"></span>
        </div>
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">Livraison :</span>
          <span className="font-thin m-1 p-1"></span>
        </div>
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">Total :</span>
          <span className="font-thin m-1 p-1"></span>
        </div>
        <div className="flex justify-evenly m-1 items-stretch rounded-md bg-gray-200">
          <span className="font-bold capitalize m-1 p-1">Détails</span>
          {!state.lineClamp ? (
            <p
              className="border-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
              }}
              onClick={toggleLineClamp}
            >
              {delivery?.description}
            </p>
          ) : (
            <textarea
              disabled={true}
              id="description"
              name="description"
              rows={7}
              value={delivery?.description}
              className={`border-none resize-none w-full outline-none text-sm px-2 py-1 rounded-md ml-1 overflow-y-scroll scrollbar-thin scrollbar-black-thumb`}
              onClick={toggleLineClamp}
            />
          )}

          <span
            className="text-sm font-thin text-gray-400 cursor-pointer"
            onClick={toggleLineClamp}
          >
            {state.showMoreOrLess}
          </span>
        </div>
        <div className="flex justify-center items-start w-full">
          <Button title="Ajouter" className="px-3 max-w-max" />
        </div>
      </div>
    </div>
  );
};

export default DeliveryWrapUp;
