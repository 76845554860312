import React, { useState } from "react";
import Button from "../form/button";
import toast from "react-hot-toast";
import { RootState } from "../../app/store";
import { useMutation } from "@apollo/client";
import { DELETE_PRODUCT } from "../../api/product/mutations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { removeFromProducts } from "../../features/shops/shopsSlice";

interface Props {
  handleDismiss: any;
}

const DeleteProduct = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isWaiting: false,
  });
  const product = useAppSelector(
    (state: RootState) => state.products.activeProduct
  );

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: async (res) => {
      if (res) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        console.log(res)
        dispatch(removeFromProducts(res.removeProduct._id));
        props.handleDismiss();
        toast.success("Le produit à été supprimé");
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    deleteProduct({
      variables: {
        id: product?._id,
      },
    });
  };

  return (
    <div className="bg-gray-50 flex justify-center items-center flex-col py-7">
      <div className="flex justify-center items-center bg-white w-full h-12 ">
        <span className="font-thin text-center px-3">
          Vous allez supprimer le produit.
        </span>
      </div>

      <div className="w-1/2">
        <Button
          title="Continuer"
          handleOnClick={handleSubmit}
          isWaiting={state.isWaiting}
          disabled={state.isWaiting}
        />
      </div>
    </div>
  );
};

export default DeleteProduct;
