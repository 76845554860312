import NewShop from "./new-shop";
import { FcInfo } from "react-icons/fc";
import React, { useState } from "react";
import { RootState } from "../../app/store";
import { FaUserEdit } from "react-icons/fa";
import { formatPhone } from "../../utils/format";
import Modal from "./../../components/common/modal";
import EditInfos from "../../components/ux/edit-infos";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  dismissNewShopModal,
  toggleNewShopModal,
} from "../../features/shops/shopsSlice";
import Button from "../form/button";
import { setStep } from "../../features/users/usersSlice";

interface Props {}

const WizardHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isInfosModalOpen: false,
  });

  const user = useAppSelector((state: RootState) => state.users.user);
  const shop = useAppSelector((state: RootState) => state.shops.shop);
  const location = useAppSelector((state: RootState) => state.utils.location);

  const toggleProfileModal = () => {
    setState((prevState) => ({
      ...prevState,
      isInfosModalOpen: !prevState.isInfosModalOpen,
    }));
  };

  const dismissProfileModal = () => {
    setState((prevState) => ({
      ...prevState,
      isInfosModalOpen: false,
    }));
  };

  const content = () => {
    switch (user.step) {
      case 0:
        return (
          <div className="bg-gray-100 rounded-xl mx-auto p-4">
            <div className="flex justify-between items-center">
              <span>Mes Informations</span>
              <FaUserEdit
                size={25}
                className="ml-2 cursor-pointer hover:text-tolgo"
                onClick={() => toggleProfileModal()}
              />
            </div>
            <hr
              className="my-3 h-0.5 w-full opacity-5"
              style={{
                background: "linear-gradient(to right, gray, black, gray)",
              }}
            />
            <div>
              <div className="py-2">
                Nom :
                <span className="text-gray-500 px-2 font-thin">
                  {" "}
                  {user.lastname}
                </span>
              </div>
              <div className="py-2">
                Prénom :
                <span className="text-gray-500 px-2 font-thin">
                  {" "}
                  {user.firstname}
                </span>
              </div>
              <div className="py-2">
                Téléphone:
                <span className="text-gray-500 px-2 font-thin">
                  ({location?.calling_code}) {formatPhone("SN", user.phone)}
                </span>
              </div>
              <div className="py-2">
                Email:
                <span className="text-gray-500 px-2 font-thin">
                  {user.email}
                </span>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="bg-gray-100 rounded-xl mx-auto p-4 text-center">
            <div className="flex justify-between items-center">
              <span>Mon rendez-vous</span>
              <FcInfo
                size={25}
                className="ml-2 cursor-pointer hover:text-tolgo"
              />
            </div>
            <hr
              className="my-3 h-0.5 w-full opacity-5"
              style={{
                background: "linear-gradient(to right, gray, black, gray)",
              }}
            />
            <>
              {user.hasBeenPlanForAPitch ? (
                <div className="flex flex-col justify-center items-start">
                  <div className="flex justify-center items-center my-3">
                    <p>
                      Vous avez rendez-vous le{" "}
                      <span className="font-thin text-blue-400 text-sm">
                        03/07/2022
                      </span>{" "}
                      à
                      <span className="font-thin text-blue-400 text-sm">
                        {" "}
                        18h 30
                      </span>{" "}
                      avec Mr DIABATE Tahirou.
                    </p>
                  </div>
                  {user?.email.length > 0 && (
                    <div className="flex justify-center items-center my-3">
                      <p>
                        Cliquez ici :{" "}
                        <a
                          href="https://meet.google.com/gzp-iayj-sga"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <span className="font-mono text-blue-400 text-sm cursor-pointer">
                            meet.google.com/gzp-iayj-sga
                          </span>
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-start">
                  <p>
                    Votre demande est en cours de traitement, vous serez
                    contacté au plus tard dans 48 h
                  </p>
                </div>
              )}
            </>
          </div>
        );
      case 2:
        return (
          <div className="bg-gray-100 rounded-xl mx-auto p-4 text-center">
            <div className="flex justify-between items-center">
              <span>La validation de ma demande</span>
              <FcInfo
                size={25}
                className="ml-2 cursor-pointer hover:text-tolgo"
              />
            </div>
            <hr
              className="my-3 h-0.5 w-full opacity-5"
              style={{
                background: "linear-gradient(to right, gray, black, gray)",
              }}
            />
            <>
              {user.hasBeenProcessed ? (
                <div className="flex flex-col justify-center items-center">
                  {user.hasBeenAcceptedAsSeller ? (
                    <>
                      <div className="flex justify-center items-center my-3">
                        <p>
                          Votre demande à été accepté. Nous, vous souhaitons la
                          bienvenue
                        </p>
                      </div>
                      <Button
                        title="Suivant"
                        className="max-w-min"
                        handleOnClick={() => dispatch(setStep(3))}
                      />
                    </>
                  ) : (
                    <div className="flex justify-center items-center my-3">
                      <p>
                        Votre demande à été refusé. Car votre activité ne
                        correspond pas.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="flex justify-center items-center my-3">
                    <p>Votre demande est en cours de validation.</p>
                  </div>
                </div>
              )}
            </>
          </div>
        );
      case 3:
        return (
          user.hasBeenAcceptedAsSeller && (
            <div className="bg-gray-100 rounded-xl mx-auto p-4 text-center">
              <div className="flex justify-between items-center">
                <span>Créer ma premiere boutique</span>
                <FcInfo
                  size={25}
                  className="ml-2 cursor-pointer hover:text-tolgo"
                />
              </div>
              <hr
                className="my-3 h-0.5 w-full opacity-5"
                style={{
                  background: "linear-gradient(to right, gray, black, gray)",
                }}
              />
              <div className="flex justify-center items-center">
                <Button
                  title="Commencer"
                  className="max-w-min"
                  handleOnClick={() => dispatch(toggleNewShopModal())}
                />
              </div>
            </div>
          )
        );

      default:
        break;
    }
  };

  return (
    <div className="flex justify-center items-center w-full my-7">
      <Modal
        modalIsOpen={state.isInfosModalOpen}
        handleModal={() => toggleProfileModal()}
        dismissModal={() => dismissProfileModal()}
        content={<EditInfos handleDismiss={() => dismissProfileModal()} />}
      />
      <Modal
        content={<NewShop />}
        className="w-11/12 md:w-2/5"
        modalIsOpen={shop.isNewShopModalOpen}
        handleModal={() => dispatch(toggleNewShopModal())}
        dismissModal={() => dispatch(dismissNewShopModal())}
      />
      <div className="flex md:flex-row items-center md:items-stretch px-3">
        {content()}
      </div>
    </div>
  );
};

export default WizardHeader;
