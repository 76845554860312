import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RootState } from "../../app/store";
import { setOrders } from "../../features/shops/shopsSlice";
import { ORDERS_BY_SHOP } from "../../api/orders/queries";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

interface Props {}

const Transactions = (props: Props) => {
  const dispatch = useAppDispatch();

  const orders = useAppSelector((state: RootState) => state.shops.orders);

  const shopId = useAppSelector((state: RootState) => state.shops.shop._id);

  const first = 50;

  const { data } = useQuery(ORDERS_BY_SHOP, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
    skip: !shopId,
    variables: {
      first,
      shopId,
    },
  });

  useEffect(() => {
    dispatch(setOrders(data?.ordersByShop?.page?.edges));
  }, [data, dispatch]);

  return (
    <>
      {orders?.length === 0 ? (
        <div className="flex flex-col justify-between items-center text-center w-full p-2">
          <span className="text-lg text-slate-400 mt-32 font-bold">
            Il n'y a eu aucune transactions dans la boutique.
          </span>
        </div>
      ) : (
        <div className="flex justify-center items-start w-full h-full p-2 pb-16">
          <table className="w-full">
            <thead className="w-full">
              <tr className="table-row text-black">
                <th className="table-cell text-center w-20 pr-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Image</span>
                  </div>
                </th>
                <th className="table-cell text-center w-20 px-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Nom</span>
                  </div>
                </th>
                <th className="table-cell text-center w-20 px-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Prix</span>
                  </div>
                </th>
                <th className="table-cell text-center w-20 pl-1">
                  <div className="bg-djeliba w-full rounded-md px-2 py-1">
                    <span className="">Commande</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className="w-full">
              {orders?.map((item, i) => (
                <tr className="table-row" key={i}>
                  <td className="text-center border-b">
                    <img
                      src={item?.node?.product?.images[0]?.lg}
                      className="object-cover w-16 h-16 rounded-md"
                      alt=""
                    />
                  </td>
                  <td className="text-center border-b">
                    <span className="text-center">
                      {item?.node?.product?.name}
                    </span>
                  </td>
                  <td className="text-center border-b">
                    <span className="">
                      {new Intl.NumberFormat("fr-FR").format(
                        item?.node?.product?.price
                      )}
                    </span>
                  </td>
                  <td className="text-center border-b overflow-ellipsis">
                    <span className="">{item?.node?.quantity}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Transactions;
