import React, { useState } from "react";
import Button from "../form/button";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useMutation } from "@apollo/client";
import { HIDE_PRODUCT } from "../../api/product/mutations";
import { updateProduct } from "../../features/shops/shopsSlice";

interface Props {
  handleDismiss: any;
}

const HideProduct = (props: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    isWaiting: false,
  });
  const product = useAppSelector(
    (state: RootState) => state.products.activeProduct
  );

  const [hideProduct] = useMutation(HIDE_PRODUCT, {
    onCompleted: async (res) => {
      if (res) {
        setState((prevState) => ({
          ...prevState,
          isWaiting: false,
        }));
        dispatch(updateProduct(res.updateProduct));
        props.handleDismiss();
        toast.success(
          `Le produit est désormais ${
            product?.status === "VISIBLE" ? "invisible" : "visible"
          }.`
        );
      }
    },
    onError: (error) => {
      setState((prevState) => ({
        ...prevState,
        isWaiting: false,
      }));
      console.error(error);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      isWaiting: true,
    }));
    hideProduct({
      variables: {
        updateProductInput: {
          id: product._id,
          status: product?.status === "VISIBLE" ? "HIDDEN" : "VISIBLE",
        },
      },
    });
  };

  return (
    <div className="bg-gray-50 flex justify-center items-center flex-col py-7">
      <div className="flex justify-center items-center bg-white w-full h-12 ">
        <span className="font-thin text-center px-3">
          {product?.status === "VISIBLE"
            ? "Le produit ne sera plus visible par vos clients."
            : "Le produit sera visible par vos clients."}
        </span>
      </div>

      <div className="w-1/2">
        <Button
          title="Continuer"
          handleOnClick={handleSubmit}
          isWaiting={state.isWaiting}
          disabled={state.isWaiting}
        />
      </div>
    </div>
  );
};

export default HideProduct;
