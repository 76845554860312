import Button from "../form/button";
import InputField from "../form/input";
import { BsImages } from "react-icons/bs";
import { RootState } from "../../app/store";
import { useMutation, useQuery } from "@apollo/client";
import { IoTrashBin } from "react-icons/io5";
import logo from "./../../assets/logo/logo.png";
import { ExpandMoreSharp } from "@mui/icons-material";
import { CREATE_SHOP } from "../../api/shop/mutations";
import React, { useRef, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  stopWaiting,
  setSegments,
  startWaiting,
  setShopName,
  uploadShopLogo,
  dismissNewShopModal,
  setShop,
} from "../../features/shops/shopsSlice";
import { setSegments as setSegmentsList } from "../../features/utils/utilsSlice";
import {
  MdPhotoLibrary,
  MdPhotoSizeSelectLarge,
  MdOutlinePhotoSizeSelectActual,
} from "react-icons/md";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SEGMENTS } from "../../api/utils/queries";
import { setHasShop } from "../../features/users/usersSlice";

interface Props {}

const NewShop = (props: Props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    file: "",
    ratio: "initial",
    renderImage: "",
    resizeModalVisible: false,
  });
  const dispatch = useAppDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const shop = useAppSelector((state: RootState) => state.shops.shop);
  const segments = useAppSelector((state: RootState) => state.utils.segments);
  const isWaiting = useAppSelector((state: RootState) => state.shops.isWaiting);

  const { data } = useQuery(SEGMENTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: localStorage.getItem("access_token") === null,
    errorPolicy: "all",
    variables: {
      first: 5,
    },
  });

  const [createShop] = useMutation(CREATE_SHOP, {
    onCompleted: async (res) => {
      if (res) {
        if (state?.file?.length !== 0) {
          let data = new FormData();
          data.append("logo", state?.file);
          data.append("ration", state?.ratio);
          await dispatch(uploadShopLogo(res.createShop._id, data));
          toast.success("Votre boutique a été crée avec success.");
          dispatch(setShop(res.createShop));
          dispatch(setHasShop());
          dispatch(dismissNewShopModal());
          navigate("/products/desktop/" + res.createShop._id);
        } else {
          dispatch(stopWaiting());
          dispatch(dismissNewShopModal());
          toast.success("Votre boutique a été crée avec success.");
        }
      } else {
        dispatch(stopWaiting());
      }
    },
    onError: (error) => {
      console.error(error);
      dispatch(stopWaiting());
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(startWaiting());
    createShop({
      variables: {
        name: shop.name,
        segments: shop.segments,
      },
    });
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case "shopName":
        dispatch(setShopName(e.target.value));
        break;
      case "segments":
        dispatch(setSegments(e.target.value));
        break;
      case "file":
        setState((prevState) => ({
          ...prevState,
          file: e.target.files[0],
          renderImage: URL.createObjectURL(e.target.files[0]),
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setSegmentsList(data?.segments?.page?.edges));
    }
  }, [data, dispatch]);

  const handleCheckboxInput = (e) => {
    dispatch(setSegments(e.target.value));
  };

  const handleSetSegments = (item) => {
    dispatch(setSegments(item));
  };

  const showResizeModal = (e) => {
    setState((prevState) => ({
      ...prevState,
      resizeModalVisible: true,
    }));
  };

  const dismissResizeModal = () => {
    setState((prevState) => ({
      ...prevState,
      resizeModalVisible: false,
    }));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        dismissResizeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  const resizeLogo = (ratio) => {
    switch (ratio) {
      case "initial":
        setState((prevState) => ({
          ...prevState,
          ratio: "initial",
        }));
        dismissResizeModal();
        break;
      case "1/1":
        setState((prevState) => ({
          ...prevState,
          ratio: "1/1",
        }));
        dismissResizeModal();
        break;
      case "4/5":
        setState((prevState) => ({
          ...prevState,
          ratio: "4/5",
        }));
        dismissResizeModal();
        break;
      case "16/9":
        setState((prevState) => ({
          ...prevState,
          ratio: "16/9",
        }));
        dismissResizeModal();
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col justify-center items-center p-2 overflow-hidden">
      <div className="flex justify-center items-center">
        <img src={logo} alt="" className="h-20 object-scale-down max-w-max" />
      </div>
      <div className="flex flex-col md:flex-row justify-evenly items-center w-full h-full border">
        <div className="relative flex flex-col justify-center items-center h-full w-full border p-2 m-1 md:mr-0 md:rounded-l-md">
          <div className="flex flex-col justify-center items-center h-48 w-full">
            {!state.renderImage ? (
              <>
                <BsImages size="50" onClick={() => inputRef.current?.click()} />
                <span
                  className="flex justify-center items-center rounded-md outline-none text-white text-center bg-black cursor-pointer px-2 max-w-max font-thin mt-3"
                  onClick={() => inputRef.current?.click()}
                >
                  Ajoutez votre logo ici
                </span>
              </>
            ) : (
              <img
                className={
                  {
                    initial: "object-contain w-full h-full",
                    "1/1": "object-cover w-48 h-48",
                    "4/5": "object-cover w-36 h-full",
                    "16/9": "object-cover w-48 h-28",
                  }[state.ratio]
                }
                src={state.renderImage}
                alt=""
              />
            )}
            <input
              type="file"
              name="file"
              ref={inputRef}
              accept="image/*"
              className="hidden"
              onChange={handleInputChange}
            />
          </div>
          <div
            className={
              state.file.length !== 0
                ? "flex justify-center items-center absolute bottom-1 left-1 hover:cursor-pointer"
                : "hidden"
            }
            onClick={showResizeModal}
          >
            <MdPhotoSizeSelectLarge className="bg-white rounded hover:cursor-pointer" />
          </div>
          <div
            className={
              state.resizeModalVisible
                ? "flex flex-col justify-center absolute bottom-1 left-1 bg-black p-2 rounded-md opacity-80"
                : "hidden"
            }
            ref={divRef}
          >
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state.ratio === "initial" ? "bg-djeliba  rounded-md px-1" : ""
              }`}
              onClick={() => resizeLogo("initial")}
            >
              <span className="text-white px-1">Initial</span>
              <MdOutlinePhotoSizeSelectActual className="text-white" />
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state.ratio === "1/1" ? "bg-djeliba  rounded-md px-1" : ""
              }`}
              onClick={() => resizeLogo("1/1")}
            >
              <span className="text-white px-1">1/1</span>
              <div className="flex justify-between items-center rounded-sm w-4 h-4 border"></div>
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state.ratio === "4/5" ? "bg-djeliba  rounded-md px-1" : ""
              }`}
              onClick={() => resizeLogo("4/5")}
            >
              <span className="text-white px-1">4/5</span>
              <div className="flex justify-between items-center rounded-sm w-4 h-5 border"></div>
            </div>
            <div
              className={`flex justify-between items-center hover:cursor-pointer ${
                state.ratio === "16/9" ? "bg-djeliba  rounded-md px-1" : ""
              }`}
              onClick={() => resizeLogo("16/9")}
            >
              <span className="text-white px-1">16/9</span>
              <div className="flex justify-between items-center rounded-sm w-6 h-4 border"></div>
            </div>
          </div>
          <div
            className={
              state.file.length !== 0
                ? "flex justify-center items-center absolute bottom-1 right-7 hover:cursor-pointer"
                : "hidden"
            }
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                renderImage: "",
                file: "",
              }));
            }}
          >
            <IoTrashBin className="bg-white rounded hover:cursor-pointer" />
          </div>
          <div
            className={
              state.file.length !== 0
                ? "flex justify-center items-center absolute bottom-1 right-1 hover:cursor-pointer"
                : "hidden"
            }
            onClick={() => inputRef.current?.click()}
          >
            <MdPhotoLibrary className="bg-white rounded hover:cursor-pointer" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-full w-full border border-l-0 p-2 m-1 ml-0 rounded-r-md">
          <div className="flex flex-col h-full md:h-48 w-full">
            <InputField
              type="text"
              id="shopName"
              autoFocus={true}
              isRequired={true}
              labelName="shopName"
              value={shop.name}
              isValid={shop.isNameValid}
              handleInputChange={handleInputChange}
              placeholder="Entrez le nom de la boutique"
            />
            <div className="flex items-start h-full overflow-y-scroll scrollbar-thin">
              <div className="flex relative justify-start w-full items-center border-2 border-gray-100 rounded-md p-2 mb-2 group">
                <div
                  className={
                    shop.segments.length === 0
                      ? "flex justify-between items-center w-full border-none outline-none text-sm p-1 rounded-md"
                      : "inline-block max-w-fit border-none outline-none text-sm p-1 rounded-md"
                  }
                >
                  {shop.segments.length === 0 && (
                    <div className="w-full flex justify-between items-center">
                      <span className="text-gray-400">Secteurs </span>
                      <ExpandMoreSharp className="text-gray-400" />
                    </div>
                  )}
                  {shop?.segments?.map((item, i) => (
                    <div
                      key={i}
                      className="max-w-min inline-block border justify-between items-center m-1 rounded-md p-1"
                    >
                      <span className="capitalize text-xs">
                        {
                          segments?.find((el) => el?.node?._id === item)?.node
                            ?.name
                        }
                      </span>
                    </div>
                  ))}
                </div>
                <div className="absolute z-50 right-0 top-11 max-w-max hidden group-hover:flex rounded-md p-2 bg-black flex-col hover:h-32 overflow-y-scroll scrollbar-thin">
                  {segments?.map((item, i) => (
                    <label
                      className="text-black my-0.5 mx-2 bg-white rounded-md p-2 text-xs flex justify-start items-center hover:cursor-pointer"
                      htmlFor={item?.node?.name}
                      key={i}
                      onClick={(e) => handleSetSegments(item?.node?._id)}
                    >
                      <input
                        id={item?.node?._id}
                        type="checkbox"
                        value={item?.node?._id}
                        className="hover:cursor-pointer"
                        onChange={(e) => handleCheckboxInput(e)}
                        checked={shop.segments.includes(item?.node?._id)}
                      />
                      <span className="ml-1 capitalize">
                        {item?.node?.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start w-full">
        <Button
          title="Créer la boutique"
          className="px-3 max-w-max"
          handleOnClick={handleSubmit}
          disabled={
            shop.name.length === 0 ||
            !shop.isNameValid ||
            shop.segments.length === 0
          }
          isWaiting={isWaiting}
        />
      </div>
    </div>
  );
};

export default NewShop;
