import React, { useEffect } from "react";
import icon from "../../assets/logo/icon.png";
import { useNavigate } from "react-router-dom";
import Header from "../../components/layout/header";
import WizardHeader from "../../components/ux/wizard-header";
import WizardContent from "../../components/ux/wizard-content";
import { useQuery } from "@apollo/client";
import { ME } from "../../api/users/queries";
import { setUser } from "../../features/users/usersSlice";
import { useAppDispatch } from "../../app/hooks";

interface Props {}

const BecomeSeller = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("access_token");

  const { data } = useQuery(ME, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: token === null ? true : false,
    errorPolicy: "all",
    context: {
      headers: { authorization: `Bearer ${token}` },
    },
  });

  useEffect(() => {
    if (data) {
      dispatch(setUser(data?.me));
    }
  }, [data, dispatch]);

  return (
    <>
      <div
        className="mx-auto"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "2em 5em 8em auto",
        }}
      >
        {/* Banner hack */}
        <div
          className="banner"
          style={{
            gridColumn: "1 / -1",
            backgroundColor: "#a5a39f49",
          }}
        ></div>

        {/* Header */}
        <Header />

        <WizardHeader />
        <WizardContent />

        <div
          onClick={() => {
            navigate("/");
          }}
          className="hover:cursor-pointer fixed flex justify-center items-center bottom-5 right-5 p-2 w-14 h-14 bg-white rounded-full active:shadow-xl shadow transition ease-in duration-200 focus:outline-none"
        >
          <img src={icon} alt="" className="object-scale-down rounded-full" />
        </div>
      </div>
    </>
  );
};

export default BecomeSeller;
